(function () {
function SvgChartGlobal()
{
};
SvgChartGlobal.language = 'zh-cn';
SvgChartGlobal.lang = {};
SvgChartGlobal.timeformat = {};

Date.prototype.toISOString = function() {
	function pad(n) {
		return n < 10 ? '0' + n : n
	}
	return this.getUTCFullYear() + '/' + pad(this.getUTCMonth() + 1)
			+ '/' + pad(this.getUTCDate()) + ' '
			+ pad(this.getUTCHours()) + ':' + pad(this.getUTCMinutes())
			+ ':' + pad(this.getUTCSeconds())
			+ ' +00';
}

window.MST = SvgChartGlobal;
})();