// import {ConLog} from './con_log';
// const conlog = new ConLog({debug: false});

export let com = {};
// Exp: 
//  com.getCookie(cookieKey);
com.getCookie = function(name) { 
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
        }
    }
    return null;
}

// Exp: 
//  com.setCookie(cookieKey, cookieValue, expireMinutes);
com.setCookie = function(name, value, seconds) {
    let expires = '';
    if (seconds) {
      let date = new Date();
      date.setTime(date.getTime() + (seconds * 1000));
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
}

// Exp: 
//  com.removeCookie(cookieKey);
com.removeCookie = function(name) {
    document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
}