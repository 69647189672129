import AES from 'crypto-js/aes'
import CryptoJS from "crypto-js";
import { com } from './common/com.cookie.js';

type WordArray = CryptoJS.lib.WordArray
type CipherParams = CryptoJS.lib.CipherParams

export class JsonClass {
    private readonly aes_key: string = ''
    private readonly aes_iv: string = ''

    constructor() {
        const iv = document.querySelector('meta[name=j-i]')["content"];

        if (iv != undefined) {
            this.aes_iv = iv
        }

        this.aes_key = com.getCookie("x-j");
    }

    setJson(data: string, encrypt?: boolean): string {
        if (encrypt) {
            data = this.encrypt(data);
        }

        return data;
    }

    getJson(data: string, decrypt?: boolean): string {
        if (decrypt) {
            data = this.decrypt(data);
        }

        return data;
    }

    async loadRemoteJson(url: string) {
        try {
            const res: Response = await fetch(url);

            if (!res.ok) {
                throw new Error('Network response was not ok');
            }

            return res.text();
        } catch (e) {
            console.log("fetch error: " + e);
            throw e;
        }
    }

    private encrypt(data: string): string {
        const result: CipherParams = AES.encrypt(data, this.getKey(), {iv: this.getIv()});

        return result.toString(CryptoJS.format.OpenSSL);
    }

    private decrypt(data: string): string {
        const result: WordArray = AES.decrypt(data, this.getKey(), {iv: this.getIv()})

        return result.toString(CryptoJS.enc.Utf8);
    }

    private getKey(): WordArray {
        return CryptoJS.enc.Utf8.parse(this.aes_key);
    }

    private getIv(): WordArray {
        return CryptoJS.enc.Utf8.parse(this.aes_iv);
    }
}
