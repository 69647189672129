(function() {

var SvgChartGlobal = MST;

var SvgChartUtil = function () {
};

SvgChartUtil.prototype.attachTouchEvent = function(eventTarget, actionTarget)
{
	actionTarget.eventTarget = eventTarget;

	if("ontouchstart" in window) {
		actionTarget.ontouchstart = this.onTouchEventStart;
		actionTarget.ontouchmove = this.onTouchEventMove;
		actionTarget.ontouchend = this.onTouchEventOut;
	}
	else {
		actionTarget.onmousedown = this.onTouchEventStart;
		actionTarget.onmousemove = this.onTouchEventMove;
		actionTarget.onmouseover = this.onTouchEventMove;
		// actionTarget.onmouseout = this.onTouchEventOut;
		actionTarget.onmouseleave = this.onTouchEventOut;
		actionTarget.onmouseup = this.onTouchEventEnd;
	}
};

SvgChartUtil.closePopDiv = function() {
	var divs = document.getElementsByClassName("SvgChartColorSelect");
	for (var i = 0; i < divs.length; i++) {
		if (divs[i].parentNode.style.display != "none") {
			divs[i].parentNode.style.display = "none";
		}
	}
	  
	var divs = document.getElementsByClassName("SvgChartTechnicalSetting");
	for (var i = 0; i < divs.length; i++) {
		if (divs[i].style.visibility != "hidden") {
			divs[i].style.visibility = "hidden";
		}
	}

	var divs = document.getElementsByClassName("SvgChartTechnicalSettingMultiParams");
	for (var i = 0; i < divs.length; i++) {
		if (divs[i].style.visibility != "hidden") {
			divs[i].style.visibility = "hidden";
		}
	}
	
	if("ontouchstart" in window) {
		var objs = document.getElementsByTagName("input");
		for (var i = 0; i < objs.length; i++) {
			objs[i].blur();
		}
	}
	
};

SvgChartUtil.prototype.onTouchEventStart = function(event)
{
	SvgChartUtil.closePopDiv();
	
	if(this.eventTarget != null && this.eventTarget.onTouchEvent != null) {
		var util = new SvgChartUtil();
		var cursorCoords = util.getCursorCoords(event, this);
		if (cursorCoords == null) return true;

		return this.eventTarget.onTouchEvent(this, "touchstart", cursorCoords);
	}

	return true;
};

SvgChartUtil.prototype.onTouchEventMove = function(event)
{
	SvgChartUtil.closePopDiv();
	
	if(this.eventTarget != null && this.eventTarget.onTouchEvent != null) {
		var util = new SvgChartUtil();
		var cursorCoords = util.getCursorCoords(event, this);
		if (cursorCoords == null) return true;

		return this.eventTarget.onTouchEvent(this, "touchmove", cursorCoords);
	}

	return false;
};

SvgChartUtil.prototype.onTouchEventOut = function(event)
{
	if(this.eventTarget != null && this.eventTarget.onTouchEvent != null) {
		return this.eventTarget.onTouchEvent(this, "touchout", null);
	}

	return true;
};

SvgChartUtil.prototype.onTouchEventEnd = function(event)
{
	if(this.eventTarget != null && this.eventTarget.onTouchEvent != null) {
		return this.eventTarget.onTouchEvent(this, "touchend", null);
	}

	return true;
};

SvgChartUtil.prototype.attachClickEvent = function(eventTarget, eventParam, actionTarget)
{
	actionTarget.eventTarget = eventTarget;
	actionTarget.eventParam = eventParam;

	actionTarget.onclick = this.onClickEvent;
};

SvgChartUtil.prototype.onClickEvent = function(event)
{
	if(this.eventTarget != null) {
		return this.eventTarget.onClickEvent(this, this.eventParam, event);
	}

	return true;
};

SvgChartUtil.prototype.attachChangeEvent = function(eventTarget, eventParam, actionTarget)
{
	actionTarget.eventTarget = eventTarget;
	actionTarget.eventParam = actionTarget.value;

	if (eventParam == "charttype") {
		actionTarget.onchange = this.onChangeEvent;
	} else {
		actionTarget.onchange = this.onClickEvent;
	}
};

SvgChartUtil.prototype.onChangeEvent = function(event)
{
	if(this.eventTarget != null) {
		return this.eventTarget.onChangeEvent(this, this.eventParam);
	}

	return true;
};

SvgChartUtil.prototype.attachKeydownEvent = function(eventTarget, actionTarget)
{
	actionTarget.eventTarget = eventTarget;
	actionTarget.onkeydown = this.onKeydownEvent;
};

SvgChartUtil.prototype.onKeydownEvent = function(event)
{
	if(this.eventTarget != null) {
		var e = event || window.event;
		return this.eventTarget.onKeydownEvent(e.keyCode);
	}

	return true;
};

SvgChartUtil.prototype.getCursorCoords = function(event, target)
{
	if ("ontouchstart" in window) {
		if (event.touches) {
			if (event.touches.length > 1) {
				return null;
			}
			event = event.touches[0];
		}
	}
	event = event || window.event;
	var element = target;
	var doc = document.documentElement;
	var body = document.body;
	var x = (window.Event && event.pageX != undefined) ? event.pageX : event.clientX + (doc && doc.scrollLeft || body && body.scrollLeft || 0) - (doc && doc.clientLeft  || body && body.clientLeft || 0);
	var y = (window.Event && event.pageY != undefined) ? event.pageY : event.clientY + (doc && doc.scrollTop  ||  body && body.scrollTop  || 0) - (doc && doc.clientTop  || body && body.clientTop  || 0);

    var xPosition = 0;
    var yPosition = 0;

    while(element) {
        xPosition += (element.offsetLeft + element.clientLeft);
        yPosition += (element.offsetTop + element.clientTop);
        element = element.offsetParent;
    }
    x -= xPosition;
    y -= yPosition;

    element = target;
    xPosition = 0;
    yPosition = 0;
    while(element != body) {
        xPosition += element.scrollLeft;
        yPosition += element.scrollTop;
        element = element.parentNode;
    }
    x += xPosition;
    y += yPosition;
	return {x : x, y : y, ax : x, ay : y};
};

SvgChartUtil.prototype.getOptimizedValue = function(value, decimals, gt) {
	var result = 0;
	var values = new Array(1, 2, 4, 5, 8, 10, 12, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 80, 90, 100);

	for(var i = 0; i < decimals; i++) {
		value *= 10;
	}

	var scale = 1;

	while(value>=100) {
		value /= 10;
		scale *= 10;
	}

	for(var i = 0; i < values.length; i++) {
		if (gt) {
			if (values[i] > value) {
				result = values[i];
				break;
			}
		} else {
			if (values[i] >= value) {
				result = values[i];
				break;
			}
			
		}
			
	}

	result *= scale;

	for(var i = 0; i < decimals; i++) {
		result /= 10;
	}

	return result;
};

SvgChartUtil.prototype.getOptimizedValueFactor = function(value, decimals) {
	var result = 0;

	for(var i = 0; i < decimals; i++) {
		value *= 10;
	}

	var scale = 1;

	while(value>=100) {
		value /= 10;
		scale *= 10;
	}

	if (value % 10 == 0) {
		result = 10;
	} else if (value % 5 == 0) {
		result = 5;
	} else if (value % 2 == 0) {
		result = 2;
	} else {
		result = value;
	}

	result *= scale;

	for(var i = 0; i < decimals; i++) {
		result /= 10;
	}

	return result;
};

SvgChartUtil.prototype.getOptimizedPoints = function(min, max, part, decimals) {
	part = part + 1;
	var result = new Array(part);
	if (isNaN(min)) {
		return result;
	}
	var scale = 1;

	for(var i = 0; i < decimals; i++) {
		scale *= 10;
	}

	// console.log("max: " + max + " ,min: " + min);
	var span = this.getOptimizedValue((max-min) / (part - 1), decimals, false);

	while(true) {
		var factor = this.getOptimizedValueFactor(span, decimals);
		result[0] = Math.floor(min / factor) * factor;
		result[0] = Math.round(result[0] * scale) / scale;
		// console.log("result[" + 0 + "]" + result[0]);

		for(var i = 1; i < part; i++) {
			result[i] = result[i - 1] + span;
			result[i] = Math.round(result[i] * scale) / scale;
			// console.log("result[" + i + "]" + result[i]);
		}

		if(result[part - 1] >= max) {
			break;
		}
		else {
			span = this.getOptimizedValue(span, decimals, true);
		}
	}

	if(result[0] > 0 && part > 2 && result[part - 3] > max) {
		for(var i = 0; i < part; i++) {
			result[i] -= span;
			result[i] = Math.round(result[i] * scale) / scale;
		}
	}

	this.formatPrecision(result);

	return result;
};


SvgChartUtil.prototype.formatPrecision = function(value) {
	var maxPrecision = 0;
	for (var i = 0; i < value.length; i++) {
		var str = value[i].toString();
		value[i] = str;
		var pos = str.indexOf(".");
		var precision = 0;
		if (pos >= 0) {
			precision = str.length - 1 - pos;
		}
		maxPrecision = Math.max(maxPrecision, precision);
	}

	if (maxPrecision == 0) {
		return value;
	}

	for (var i = 0; i < value.length; i++) {
		var str = value[i];
		var pos = str.indexOf(".");

		if (pos < 0) {
			pos = str.length;
			str += ".";
		}
		while (str.length <= pos + maxPrecision) {
			str += "0";
		}
		value[i] = str;
	}
	return value;

};

// SvgChartUtil.prototype.getFormatValue = function(value) {
	// // if (value > 1000000) {
		// // return Math.round(value / 10000) / 100 + "M";
	// // // } else if (value > 1000) {
		// // // return Math.round(value / 10) / 100 + "K";
	// // } else {
		// return value;
	// // }
//
// };

SvgChartUtil.getTimeFormat = function(period, type)
{
	var i = 1;
	if (type == "all") {
		i = 2;
	} else if (type == "detail") {
		i = 0;
	}
	var f = SvgChartGlobal.periodTimeformat[period][i];
	var f = SvgChartGlobal.timeformat["select"][f];

	return function (data) {
		return (new Date(data)).format(f);
		// return MST.SvgChart.timeFormatFunc(data, f);
	};

};

SvgChartUtil.measureText = function(chart, text)
{
	var div = chart.measureTextDiv;
	div.innerHTML = text;
	var w = div.clientWidth;
	div.innerHTML = '';
	return w;
};

SvgChartUtil.fillZero = function(n){
	return n > 9 ? n.toString() : "0" + n;
};

SvgChartUtil.exchangeHAxis = function(exchangeTimes, timeFormat, date, lastCloseStartIndex)
{
	var hAxisValues = new Array();
	var index = 0;
	var m0 = 0;
	var span = 2;
	date = date.substring(0, 11);

	var gmtHours = (new Date()).getTimezoneOffset() / 60;

	for (var i = 0; i < exchangeTimes.length; i++) {

		var label = exchangeTimes[i];
		var times = label.split(/:|\//);

		if (times.length != 2 && times.length != 4) {
			continue;
		}
		for (var t = 0; t < times.length; t++) {
			times[t] = parseInt(times[t]);
			if (isNaN(times[t])) {
				return [];
			}
		}

		var m = times[0] * 60 + times[1];

		if (i == 0) {
			m0 = m;
		}

		var time0 = times[0] - gmtHours;
		 if (time0 > 24) {
			 time0 -= 24;
		 }
		label = SvgChartUtil.fillZero(time0) + ":" + SvgChartUtil.fillZero(times[1]);
		// label = timeFormat(date + label + ":00");
		if (times.length == 4) {
			var time2 = times[2] - gmtHours;
			 if (time2 > 24) {
				 time2 -= 24;
			 }
			var label1 = SvgChartUtil.fillZero(time2) + ":" + SvgChartUtil.fillZero(times[3]);
			// label += "/" + timeFormat(date + label1 + ":00");
			label += "/" + label1;
		}
		hAxisValues.push({value : (m - m0) / span + lastCloseStartIndex + 1, label : label});

		if (times.length == 4) {
			m0 += (times[2] - times[0]) * 60 + (times[3] - times[1]) - span * 2;
		}
	};
	return hAxisValues;
};

SvgChartUtil.jsonpOptions = {};
SvgChartUtil.jsonpCount = 0;
SvgChartUtil.jsonp = function(options)
{

    var timeout = 3000;
    if (options.timeout != undefined) {
    	timeout = options.timeout * 1000;
    }
    var head = document.getElementsByTagName('head')[0];
	var jsonp = document.createElement("script");
	jsonp.type = "text/javascript";
    var callback = options.jsonpCallback;
    if (options.url.indexOf("jsonp") < 0) {
    	SvgChartUtil.jsonpCount += 1;
    	callback = callback + SvgChartUtil.jsonpCount;
    	jsonp.src = options.url + "&callback=" + callback;
    } else {
    	jsonp.src = options.url;
    }
	if (SvgChartUtil.jsonpOptions[jsonp.src] != null) {
		SvgChartUtil.jsonpOptions[jsonp.src].push(options);
	} else {
		head.appendChild(jsonp);
		SvgChartUtil.jsonpOptions[jsonp.src] = [options];

	    window[callback] = function (json) {
    		head.removeChild(jsonp);
	        clearTimeout(jsonp.timer);
	        window[callback] = null;
	        var arr = SvgChartUtil.jsonpOptions[jsonp.src];
	        for (var i = 0; i < arr.length; i++) {
	        	arr[i].success && arr[i].success(json);
	        }
	        SvgChartUtil.jsonpOptions[jsonp.src] = null;
	    };
	
	    jsonp.timer = setTimeout(function () {
	    	window[callback] = null;
	        head.removeChild(jsonp);
	        var arr = SvgChartUtil.jsonpOptions[jsonp.src];
	        for (var i = 0; i < arr.length; i++) {
	        	arr[i].fail && arr[i].fail();
	        }
	        SvgChartUtil.jsonpOptions[jsonp.src] = null;
	    }, timeout);
	}

};

SvgChartUtil.getPeriod = function(param, data, rangeStart, rangeEnd) {
	var dateNow = new Date();
	if(data && data.length > 0){
		dateNow = new Date(data[data.length - 1].date);
	}
	// var rangeStart;
	// var rangeEnd;
	var format = "YYYY/MM/DD ";

	var period;
	switch(param) {
		case "1D":
			period = "5m";
			break;
		case "2D":
		case "3D":
		case "1W":
			period = "5m";
			break;
		case "1M":
//			period = "1h";
//			break;
		case "3M":
		case "6M":
		case "YTD":
			period = "daily";
			break;
		case "6M_weekly":
		case "1Y":
		case "2Y":
		case "3Y":
			period = "weekly";
			// period = "daily";
			break;
		case "5Y":
		case "10Y":
		case "ALL":
			period = "monthly";
			break;
		default :
			period = "daily";
	};
	
	if (data == null || data.length == 0) {
		return {period : period};
	}

	// if (param != null && param != "YTD" && this.chart.period != period) {
		// return {period : period};
	// }

	if (param == null || param == "YTD") {

		var dateStart;
		var dateEnd;

		if (param == "YTD") {
			var ytd = new Date(dateNow.getFullYear(), 0, 1);
			rangeStart = ytd.format(format);
			rangeEnd = dateNow.format(format);
			dateStart = ytd.getTime();
			dateEnd = dateNow.getTime();

		} else if (rangeStart != null && rangeEnd != null) {
			dateStart = Date.parse(rangeStart);
			dateEnd = Date.parse(rangeEnd);

			if (isNaN(dateStart))
				dateStart = dateNow.getTime();
			if (isNaN(dateEnd))
				dateEnd = dateNow.getTime();

			if (dateStart > dateEnd) {
				var t = dateStart;
				dateStart = dateEnd;
				dateEnd = t;
			}

			rangeStart = (new Date(dateStart)).format(format);
			rangeEnd = (new Date(dateEnd)).format(format);
		}

		var range = (dateEnd - dateStart) / 3600000;
		if (range > 365 * 24) {
			period = "monthly";
//		} else if (range > 185 * 24) {
//			period = "weekly";
		} else if (range > 12 * 24) {
			period = "daily";
		} else if (range > 12) {
			period = "1h";
		} else {
			//period = "5m";
			period = "1m";
		}

		// if (this.chart.period != period) {
			// return {period : period};
		// }
	} else if (param == "1D") {
		rangeStart = dateNow.format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "2D") {
		var offset = 2;
		// switch (dateNow.getDay()) {
		// case 0:
		// case 6:
		// 	offset = 3;
		// 	break;
		// case 1:
		// case 2:
		// 	offset = 4;
		// 	break;
		// }
		// rangeStart = (new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() - offset)).format(format);
		var startIndex = SvgChartUtil.getStartIndex(data, data.length - 1, offset);
		rangeStart = new Date(data[startIndex].date).format(format);
		rangeEnd = dateNow.format(format);
	} 
	else if (param == "3D") {
		var offset = 4;
		// switch (dateNow.getDay()) {
		// case 0:
		// case 6:
		// 	offset = 4;
		// 	break;
		// case 1:
		// case 2:
		// 	offset = 5;
		// 	break;
		// }
		// rangeStart = (new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() - offset)).format(format);
		var startIndex = SvgChartUtil.getStartIndex(data, data.length - 1, offset);
		rangeStart = new Date(data[startIndex].date).format(format);
		rangeEnd = dateNow.format(format);		
	} else if (param == "1W") {
		rangeStart = (new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate() - 7)).format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "1M") {
		rangeStart = (new Date(dateNow.getFullYear(), dateNow.getMonth() - 1, dateNow.getDate())).format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "3M") {
		rangeStart = (new Date(dateNow.getFullYear(), dateNow.getMonth() - 3, dateNow.getDate())).format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "6M" || param == "6M_weekly") {
		rangeStart = (new Date(dateNow.getFullYear(), dateNow.getMonth() - 6, dateNow.getDate())).format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "1Y") {
		rangeStart = (new Date(dateNow.getFullYear() - 1, dateNow.getMonth(), dateNow.getDate())).format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "3Y") {
		rangeStart = (new Date(dateNow.getFullYear() - 3, dateNow.getMonth(), dateNow.getDate())).format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "5Y") {
		rangeStart = (new Date(dateNow.getFullYear() - 5, dateNow.getMonth(), dateNow.getDate())).format(format);
		rangeEnd = dateNow.format(format);
	} else if (param == "10Y") {
		rangeStart = (new Date(dateNow.getFullYear() - 10, dateNow.getMonth(), dateNow.getDate())).format(format);
		rangeEnd = dateNow.format(format);
	}
	rangeEnd = rangeEnd + "23:59:59";

	var startIndex = SvgChartUtil.searchIndex(rangeStart, data, 0, data.length - 1, "next");
	var endIndex = SvgChartUtil.searchIndex(rangeEnd, data, 0, data.length - 1, "before");

	if (startIndex == 0) {
		endIndex = Math.min(Math.max(SvgChartGlobal.LAYOUT_SCROLL_MIN_PAGE_LENGTH - 1, endIndex), data.length - 1);
	}
	if (endIndex == data.length - 1) {
		startIndex = Math.max(Math.min(data.length - SvgChartGlobal.LAYOUT_SCROLL_MIN_PAGE_LENGTH, startIndex), 0);
	}

	if (param == "ALL") {
		startIndex = 0;
		endIndex = data.length - 1;
	} else if (param == "3D") {
		endIndex = data.length - 1;
		startIndex = SvgChartUtil.getStartIndex(data, data.length, 3);
	} else if (param == "1D") {
		endIndex = data.length - 1;
		startIndex = SvgChartUtil.getStartIndex(data, data.length);
//		startIndex = Math.max(startIndex - 50, 0);
	}

	return {period : period, startIndex : startIndex, dataLength : endIndex - startIndex + 1};
};


SvgChartUtil.getStartIndex = function(data, endIndex, offset) {
	if (endIndex <= 0) return 0;
	offset = offset || 1;
	for (var i = 0; i < offset; i++) {
		var date = data[endIndex - 1].date.substring(0, 10) + " 00:00:00 +00";
		var startIndex = SvgChartUtil.searchIndex(date, data, 0, endIndex - 1, "next");
		endIndex = startIndex;
		if (endIndex < 0) endIndex = 0;
		if (endIndex == 0) break;
	}
	return endIndex;
};


Date.prototype.format = function(formatStr) {
	var date = this;

	var str = formatStr.replace(/y{1,4}|m{1,4}|d{1,2}|h{1,2}|s{1,2}|w{3,4}/ig, function($0) {

		var m = date.getMonth();
		var m1 = m + 1;
		var d = date.getDate();
		var h = date.getHours();
		var mm = date.getMinutes();
		var s = date.getSeconds();

		switch ($0) {
			case 'yyyy':
			case 'YYYY':
				return date.getFullYear();
			case 'yy':
			case 'YY':
				var yy = date.getFullYear() % 100;
				return SvgChartUtil.fillZero(yy);
			case 'MMMM':
				return SvgChartGlobal.lang['select']['timeformat_MMMM'][m];
			case 'MMM':
				return SvgChartGlobal.lang['select']['timeformat_MMM'][m];
			case 'MM':
				return SvgChartUtil.fillZero(m1);
			case 'M':
				return m1.toString();
			case 'DD':
			case 'dd':
				return SvgChartUtil.fillZero(d);
			case 'D':
			case 'd':
				return d.toString();
			case 'HH':
			case 'hh':
				return SvgChartUtil.fillZero(h);
			case 'H':
			case 'h':
				return h.toString();
			case 'mm':
				return SvgChartUtil.fillZero(mm);
			case 'm':
				return mm.toString();
			case 'SS':
			case 'ss':
				return SvgChartUtil.fillZero(s);
			case 'S':
			case 's':
				return s.toString();
			case 'WWWW':
			case 'wwww':
				return SvgChartGlobal.lang['select']['timeformat_WWWW'][date.getDay()];
			case 'WWW':
			case 'www':
				return SvgChartGlobal.lang['select']['timeformat_WWW'][date.getDay()];
			default:
				return '';
		}
	});

	return str;
};

SvgChartUtil.searchIndex = function(date, data, fromIndex, toIndex, type)
{
	if (fromIndex == toIndex) {
		return fromIndex;
	}

	var half;
	if (type == "next") {
		half = Math.floor((fromIndex + toIndex) / 2);
		if (data[half].date < date) {
			return SvgChartUtil.searchIndex(date, data, half + 1, toIndex, type);
		} else {
			return SvgChartUtil.searchIndex(date, data, fromIndex, half, type);
		}
	} else {
		half = Math.ceil((fromIndex + toIndex) / 2);
		if (data[half].date > date) {
			return SvgChartUtil.searchIndex(date, data, fromIndex, half - 1, type);
		} else {
			return SvgChartUtil.searchIndex(date, data, half, toIndex, type);
		}
	}
};

MST.SvgChartUtil = SvgChartUtil;
}());