(function() {

var SvgChartMapping = function () {
	this.status = this.STATUS_NG;
};

SvgChartMapping.prototype.STATUS_NG = 0;
SvgChartMapping.prototype.STATUS_OK = 1;

SvgChartMapping.prototype.VAXIS_TYPE_AUTO = 0;
SvgChartMapping.prototype.VAXIS_TYPE_FIX = 1;

SvgChartMapping.prototype.status = 0;

SvgChartMapping.prototype.updated = false;

SvgChartMapping.prototype.drawAreaWidth = 0;
SvgChartMapping.prototype.drawAreaHeight = 0;

SvgChartMapping.prototype.paddingLeft = 0;
SvgChartMapping.prototype.paddingRight = 0;
SvgChartMapping.prototype.paddingTop = 0;
SvgChartMapping.prototype.paddingBottom = 0;
SvgChartMapping.prototype.paddingVaxis = 0;

SvgChartMapping.prototype.dataStartIndex = 0;
SvgChartMapping.prototype.dataLength = 0;
SvgChartMapping.prototype.dataBlank = 0;

SvgChartMapping.prototype.dataMinValue = [];
SvgChartMapping.prototype.dataMaxValue = [];

//SvgChartMapping.prototype.dataMinValue1 = 0;
//SvgChartMapping.prototype.dataMaxValue1 = 0;

SvgChartMapping.prototype.vAxisType = 1;
SvgChartMapping.prototype.vAxisValues = null;
SvgChartMapping.prototype.vAxisValues1 = null;
SvgChartMapping.prototype.hAxisValues = null;
SvgChartMapping.prototype.twoAxes = false;
SvgChartMapping.prototype.benchmark = false;
SvgChartMapping.prototype.logarithmic = false;
// SvgChartMapping.prototype.intraday = false;


SvgChartMapping.prototype.setStatus = function(s)
{
	if(this.status != s) {
		this.updated = true;
	}

	this.status = s;
};

SvgChartMapping.prototype.setDrawAreaSize = function(width, height)
{
	if(this.drawAreaWidth != width || this.drawAreaHeight != height) {
		this.updated = true;
	}

	this.drawAreaWidth = width;
	this.drawAreaHeight = height;
};

SvgChartMapping.prototype.setPadding = function(left, right, top, bottom, vaxis)
{
	if(this.paddingLeft != left || this.paddingRight != right || this.paddingTop != top || this.paddingBottom != bottom || this.paddingVaxis != vaxis) {
		this.updated = true;
	}

	this.paddingLeft = left;
	this.paddingRight = right;
	this.paddingTop = top;
	this.paddingBottom = bottom;
	this.paddingVaxis = vaxis;
};

SvgChartMapping.prototype.setDataRange = function(startIndex, length)
{
//	if(this.dataStartIndex != startIndex || this.dataLength != length) {
		this.updated = true;
//	}

	this.dataStartIndex = startIndex;
	this.dataLength = length;
};

SvgChartMapping.prototype.setDataMinMax = function(minValue, maxValue)
{
//	if(this.dataMinValue != minValue || this.dataMaxValue != maxValue) {
		this.updated = true;
//	}

	this.dataMinValue = minValue;
	this.dataMaxValue = maxValue;
};

//SvgChartMapping.prototype.setDataMinMax1 = function(minValue, maxValue)
//{
//	if(this.dataMinValue1 != minValue || this.dataMaxValue1 != maxValue) {
//		this.updated = true;
//	}
//
//	this.dataMinValue1 = minValue;
//	this.dataMaxValue1 = maxValue;
//};

SvgChartMapping.prototype.setVAxis = function(type)
{
	this.vAxisType = type;
};

SvgChartMapping.prototype.setVAxisValues = function(v, v1)
{
	this.vAxisValues = v;
	this.vAxisValues1 = v1;
};

SvgChartMapping.prototype.setHAxisValues = function(values)
{
	this.hAxisValues = values;
};

SvgChartMapping.prototype.transformIndex = function(index)
{
	var width = this.drawAreaWidth - this.paddingLeft - this.paddingRight - 1;
	var unitWidth = width / (this.dataLength + this.dataBlank);
	var result = this.paddingLeft + unitWidth * (index - this.dataStartIndex) + unitWidth / 2.0;

	return Math.round(result);
};

SvgChartMapping.prototype.unTransformIndex = function(x)
{
	var width = this.drawAreaWidth - this.paddingLeft - this.paddingRight - 1;
	var unitWidth = width / (this.dataLength + this.dataBlank);
	var result = (x - this.paddingLeft - unitWidth / 2.0) / unitWidth + this.dataStartIndex;

	return result;
};


SvgChartMapping.prototype.transformValue = function(value, vAxis)
{
	vAxis = vAxis || 0;
	var max = this.dataMaxValue[vAxis];
	var min = this.dataMinValue[vAxis];

	if (max == min) {
		return this.paddingTop;
	}

	var v = value;

	if (this.logarithmic && !this.benchmark) {
		max = Math.log(max);
		min = Math.log(min);
		var v = Math.log(v);
	}

	var height = this.drawAreaHeight - this.paddingTop - this.paddingBottom - 1;
	var result = this.paddingTop + height * (max - v) / (max - min);

	return Math.round(result);
};

SvgChartMapping.prototype.unTransformValue = function(y)
{
	var max = this.dataMaxValue[0];
	var min = this.dataMinValue[0];

	var height = this.drawAreaHeight - this.paddingTop - this.paddingBottom - 1;
	var result = max - (y - this.paddingTop) * (max - min) / height;

	return result;
};

SvgChartMapping.prototype.getUnitWidth = function()
{
	var width = this.drawAreaWidth - this.paddingLeft - this.paddingRight - 1;
	var unitWidth = width / (this.dataLength + this.dataBlank);

	return unitWidth;
};

SvgChartMapping.prototype.getPreferDataLength = function()
{
	// var width = this.drawAreaWidth - this.paddingLeft - this.paddingRight - 1;
	// var dataLength = Math.round(width / 3);
//
	// return dataLength;

	return 60;
};

SvgChartMapping.prototype.transform = function(point)
{
	var x = this.transformIndex(point.index);
	var v = point.value;
	if (this.benchmark == true && this.startValue != null) {
		v = (v / this.startValue - 1) * 100;
	}
	var y = this.transformValue(v);

	return {x: x, y: y};
};

MST.SvgChartMapping = SvgChartMapping;
}());