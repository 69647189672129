MST.SvgChartDrawUtil = (function() {

var SvgChartDrawUtil = function () {
};

// create canvas
SvgChartDrawUtil.createCanvas = function(parentDiv, w, h) {
	var svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
	svg.setAttribute("height", "100%");
	svg.setAttribute("width", "100%");
	parentDiv.appendChild(svg);
	var defs = document.createElementNS('http://www.w3.org/2000/svg', 'defs');
	svg.appendChild(defs);

	return svg;
};

// clear canvas
SvgChartDrawUtil.clear = function(canvas) {
	if(!canvas){
		return false;
	}
	var objs = canvas.childNodes;
	for (var i = objs.length - 1; i > 0; i--) {
		canvas.removeChild(objs[i]);
	}
	var obj;
	if (canvas.firstChild != null) {
		while((obj = canvas.firstChild.lastChild) != null) {
			canvas.firstChild.removeChild(obj);
		}
	}
};

// draw line
SvgChartDrawUtil.line = function(canvas, x1, y1, x2, y2, lineColor, strokeDasharray) {
	if (x1 == x2 && y1 == y2) {
		x1 -= 0.5;
		x2 += 0.5;
	}
	var obj;
	 if (true) {
		obj = document.createElementNS("http://www.w3.org/2000/svg", "line");
		obj.setAttribute("x1", x1);
		obj.setAttribute("y1", y1);
		obj.setAttribute("x2", x2);
		obj.setAttribute("y2", y2);
		obj.style.stroke = lineColor;
		if (strokeDasharray != null) {
			obj.style.strokeDasharray = strokeDasharray;
		}
		obj.setAttribute("transform", "scale(1, 0.8)");
		canvas.appendChild(obj);
	 } else {
		obj = canvas.path("M" + (x1 - 0.5) + ", " + (y1 - 0.5) + "L" + (x2 - 0.5) + "," + (y2 - 0.5));
		obj.attr("stroke", lineColor);
		if (strokeDasharray != null) {
			obj.attr("stroke-dasharray", "- ");
		}
	 }
	return obj;
};

// draw circle
SvgChartDrawUtil.drawPoint = function(canvas, x, y, fillColor, r, lineColor) {
	var obj;
	 if (true) {
		obj = document.createElementNS("http://www.w3.org/2000/svg", "circle");
		obj.setAttribute("cx", x);
		obj.setAttribute("cy", y);
		obj.setAttribute("r", r);
		if (lineColor == null) {
			obj.style.stroke = "#FFFFFF";
		} else {
			obj.style.stroke = lineColor;
		}
		obj.style.fill = fillColor;
		obj.setAttribute("stroke-width", 2);
		obj.setAttribute("transform", "scale(1, 0.8)");
		canvas.appendChild(obj);
	 } else {
		 obj = canvas.circle(x, y, r);
		 obj.attr("stroke", "#FFFFFF");
		 obj.attr("stroke-width", 2);
		 obj.attr("fill", fillColor);
	 }
	return obj;
};

// draw path
SvgChartDrawUtil.path = function(canvas, p, w, lineColor) {
	var s;
	if (typeof(p) == "string") {
		s = p;
	} else {
		if (p.length == 0) return;
		s = "";
		if (true) {
			for (var i = 0; i < p.length; i++) {
				if (i == 0) {
					s = s + "M" + p[i].x + ", " + p[i].y;
				} else {
					s = s + "L" + p[i].x + ", " + p[i].y;
				}
			}
		} else {
			 for (var i = 0; i < p.length; i++) {
				 if (i == 0) {
					 s = s + "M" + (p[i].x - 0.5) + ", " + (p[i].y - 0.5);
				 } else {
					 s = s + "L" + (p[i].x - 0.5) + ", " + (p[i].y - 0.5);
				 }
			 }
		}
	}
	
	if (true) {
		var obj = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		obj.setAttribute('d', s);
		obj.style.stroke = lineColor;
		obj.setAttribute("stroke-width", w);
		obj.setAttribute("fill", "none");
		obj.setAttribute("transform", "scale(1, 0.8)");
		canvas.appendChild(obj);
	 } else {
		 obj = canvas.path(s);
		 obj.attr("stroke-width", w);
		 obj.attr("stroke", lineColor);
	 }
};

// draw path and fill
SvgChartDrawUtil.pathFill = function(canvas, p, w, lineColor, fillColor, fillOpacity) {
	var s;
	if (typeof(p) == "string") {
		s = p;
	} else {
		if (p.length == 0) return;
		s = "";
		if (true) {
			for (var i = 0; i < p.length; i++) {
				if (i == 0) {
					s = s + "M" + p[i].x + ", " + p[i].y;
				} else {
					s = s + "L" + p[i].x + ", " + p[i].y;
				}
			}
		} else {
			 for (var i = 0; i < p.length; i++) {
				 if (i == 0) {
					 s = s + "M" + (p[i].x - 0.5) + ", " + (p[i].y - 0.5);
				 } else {
					 s = s + "L" + (p[i].x - 0.5) + ", " + (p[i].y - 0.5);
				 }
			 }
		}
	}

	fillOpacity = fillOpacity || 1;
	 if (true) {
		var obj = document.createElementNS('http://www.w3.org/2000/svg', 'path');
		obj.setAttribute('d', s);
		obj.setAttribute("fill-opacity", fillOpacity);
		if (lineColor != null) {
			obj.setAttribute("stroke", lineColor);
			obj.setAttribute("stroke-width", w);
		} else {
			obj.setAttribute("stroke-width", 0);
		}
		obj.setAttribute("transform", "scale(1, 0.8)");
		canvas.appendChild(obj);

		SvgChartDrawUtil.linearFill(canvas, obj, fillColor);
	 } else {
		 obj = canvas.path(s);
		 obj.attr("fill", fillColor);
		 obj.attr("fill-opacity", fillOpacity);
		 if (lineColor != null) {
			 obj.attr("stroke", lineColor);
			 obj.attr("stroke-width", w);
		 } else {
			 obj.attr("stroke-width", 0);
		 }
		 obj.setAttribute("transform", "scale(1, 0.8)");
	 }
	return obj;
};

// draw rectangle
SvgChartDrawUtil.rect = function(canvas, x, y, w, h, lineColor) {
	if (w == 0 && h == 0 ) {
		return SvgChartDrawUtil.line(canvas, x - 0.5, y, x + 0.5, y, lineColor);
	}
	if (h == 0) {
		return SvgChartDrawUtil.line(canvas, x, y, x + w, y, lineColor);
	} else if (w == 0) {
		return SvgChartDrawUtil.line(canvas, x, y, x, y + h, lineColor);
	}
	var obj;
	 if (true) {
		var obj = document.createElementNS("http://www.w3.org/2000/svg", "rect");
		obj.setAttribute("x", x);
		obj.setAttribute("y", y);
		obj.setAttribute("width", w);
		obj.setAttribute("height", h);
		obj.style.stroke = lineColor;
		obj.style.trokeWidth = 1;
		canvas.appendChild(obj);
	 } else {
		 obj = canvas.rect((x - 0.5), (y - 0.5), w, h);
		 obj.attr("stroke", lineColor);
	 }
	return obj;
};

// draw rectangle and fill
SvgChartDrawUtil.fillRect = function(canvas, x, y, w, h, lineColor, fillColor, fillOpacity) {
	if (w == 0 && h == 0 ) {
		return SvgChartDrawUtil.line(canvas, x - 0.5, y, x + 0.5, y, lineColor);
	}
	if (h == 0) {
		return SvgChartDrawUtil.line(canvas, x, y, x + w, y, lineColor);
	} else if (w == 0) {
		return SvgChartDrawUtil.line(canvas, x, y, x, y + h, lineColor);
	}
	fillOpacity = fillOpacity || 1;
	var obj;
	 if (true) {
		obj = document.createElementNS("http://www.w3.org/2000/svg", "rect");
		obj.setAttribute("x", x);
		obj.setAttribute("y", y);
		obj.setAttribute("width", w);
		obj.setAttribute("height", h);
		obj.setAttribute("fill", fillColor);
		obj.setAttribute("fill-opacity", fillOpacity);
		if (lineColor != null) {
			obj.setAttribute("stroke", lineColor);
		} else {
			obj.setAttribute("stroke-width", 0);
		}
		canvas.appendChild(obj);
	 } else {
		 obj = canvas.rect((x - 0.5), (y - 0.5), w, h);
		 obj.attr("fill", fillColor);
		 obj.attr("fill-opacity", fillOpacity);
		 if (lineColor != null) {
			 obj.attr("stroke", lineColor);
		 } else {
			 obj.attr("stroke-width", 0);
		 }
	 }
	return obj;
};

// draw text
SvgChartDrawUtil.fillText = function(canvas, x, y, t, textColor, align) {
	var text;
	 if (true) {
		var text = document.createElementNS("http://www.w3.org/2000/svg", "text");
		text.setAttribute("x", x);
		text.setAttribute("y", y);
		var textNode = document.createTextNode(t);
		text.appendChild(textNode);
		canvas.appendChild(text);

		if (align == "left") {
			text.setAttribute("text-anchor", "start");
		} else if (align == "center") {
			text.setAttribute("text-anchor", "middle");
		} else if (align == "right") {
			text.setAttribute("text-anchor", "end");
		}
		// text.setAttributeNS(null, "class", "SvgText");
		// text.setAttribute("font-size", "13px");
		// text.setAttribute("font-family", "Arial");
		text.setAttribute("fill", textColor);
		text.setAttribute("unselectable", "on");
		return text;
	 } else {
		 text = canvas.text((x - 0.5), (y - 0.5), t);
		 if (align == "left") {
			 text.attr("text-anchor", "start");
		 } else if (align == "center") {
			 text.attr("text-anchor", "middle");
		 } else if (align == "right") {
			 text.attr("text-anchor", "end");
		 }

		 text.attr("font-size", "12px");
//		 text.attr("font-family", "Arial");
		 text.attr("fill", textColor);
	 }
};

SvgChartDrawUtil.triangle = function(canvas, x, y, lineColor) {
	var w = 9;
	var h = 8;
	var s = "M" + (x + w) + ", " + (y - w);
	s = s + "A" + w  + ", " + w + " 0 1,0 " + (x + w) + ", " + (y - w + 1);
	s = s + "Z";

	var obj = document.createElementNS('http://www.w3.org/2000/svg', 'path');

	obj.setAttribute('d', s);
//	obj.setAttribute("fill-opacity", 0);
	obj.setAttribute("fill", lineColor);
//	if (lineColor != null) {
		obj.setAttribute("stroke", lineColor);
//	} else {
		obj.setAttribute("stroke-width", 1);
//	}
	canvas.appendChild(obj);
//	SvgChartDrawUtil.linearFill(canvas, obj, fillColor);

	return obj;
};

// linear gradient
SvgChartDrawUtil.linearGradientID = 1;
SvgChartDrawUtil.linearFill = function(canvas, obj, fillColor) {
	var colors = fillColor.split("-");
	if (colors.length != 3) {
		obj.style.fill = fillColor;
		return;
	}

	var linearGradient = document.createElementNS('http://www.w3.org/2000/svg', 'linearGradient');
	canvas.firstChild.appendChild(linearGradient);

	var stop1 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
	linearGradient.appendChild(stop1);
	var stop2 = document.createElementNS('http://www.w3.org/2000/svg', 'stop');
	linearGradient.appendChild(stop2);
	SvgChartDrawUtil.linearGradientID++;
	linearGradient.setAttribute('id', 'linear' + SvgChartDrawUtil.linearGradientID);

	linearGradient.setAttribute('x1', '0');
	linearGradient.setAttribute('y1', '1');
	linearGradient.setAttribute('x2', '0');
	linearGradient.setAttribute('y2', '0');

	stop1.setAttribute('offset', '0%');
	stop1.setAttribute('stop-color', colors[1]);
	stop1.setAttribute('stop-opacity', '1');
	stop2.setAttribute('offset', '100%');
	stop2.setAttribute('stop-color', colors[2]);
	stop2.setAttribute('stop-opacity', '1');
	linearGradient.appendChild(stop1);
	linearGradient.appendChild(stop2);
//	obj.setAttribute('fill', 'url(' + location.origin + location.pathname + '#' + 'linear' + SvgChartDrawUtil.linearGradientID + ')');
	obj.setAttribute('fill', 'url(#' + 'linear' + SvgChartDrawUtil.linearGradientID + ')');
};

SvgChartDrawUtil.clipPathID = 0;
SvgChartDrawUtil.clipPath = function(canvas, mapping, obj) {
	var clipPath = document.createElementNS('http://www.w3.org/2000/svg', 'clipPath');
	canvas.firstChild.appendChild(clipPath);

	var rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
	clipPath.appendChild(rect);
	SvgChartDrawUtil.clipPathID++;
	clipPath.setAttribute('id', 'clipPath' + SvgChartDrawUtil.clipPathID);

	rect.setAttribute('x', mapping.paddingLeft);
	rect.setAttribute('y', mapping.paddingTop);
	rect.setAttribute('width', mapping.drawAreaWidth - mapping.paddingLeft - mapping.paddingRight);
	rect.setAttribute('height', mapping.drawAreaHeight - mapping.paddingTop - mapping.paddingBottom);
	obj.setAttribute('clip-path', 'url(#' + 'clipPath' + SvgChartDrawUtil.clipPathID + ')');
};

// get attribute
SvgChartDrawUtil.getAttr = function(obj, attrName) {
	 if (true) {
		return obj.getAttribute(attrName);
	 } else {
		 return obj.attrs[attrName];
	 }
};

// set attribute
SvgChartDrawUtil.setAttr = function(obj, attrName, value) {
	 if (true) {
		obj.setAttribute(attrName, value);
	 } else {
		 obj.attr(attrName, value);
	 }
};

return SvgChartDrawUtil;

}());