MST.periodTimeformat = {
	'tick' : ['ymdHM', 'mdHM', 'ymd'],
	'1m' : ['ymdHM', 'mdHM', 'ymd'],
	'5m' : ['ymdHM', 'mdHM', 'ymd'],
	'30m' : ['ymdHM', 'mdHM', 'ymd'],
	'1h' : ['mdH', 'mdH', 'ymd'],
	'daily' : ['ymd', 'ymd', 'ym'],
	'weekly' : ['ymd', 'ymd', 'ym'],
	'monthly' : ['ym', 'ym', 'y']
};

MST.exchange = {
	'TYO' : ['00:00', '02:30/03:30', '06:00'],
	'TDG' : ['07:00', '14:00', '21:00'],
	'FRA' : ['06:00', '20:00'],
	'GER' : ['06:00', '20:00'],
	'PAR' : ['06:00', '20:00'],
	'.GDAXI' : ['07:00', '20:00']
};

MST.RES_LODING_IMG_URL = "images/loading.gif";
MST.requestCount = 0;
MST.PICK_WIDTH = 6;

// MST.LAYOUT_WIDTH = 700;
MST.LAYOUT_PANEL_FRAME_PADDING = 3;
MST.LAYOUT_PANEL_CONTENT_PADDING = 2;

MST.LAYOUT_PANEL_VAXIS_PADDING_TOP_0 = 25;
MST.LAYOUT_PANEL_VAXIS_PADDING_BOTTOM_0 = 5;
MST.LAYOUT_PANEL_VAXIS_PADDING_TOP = 20;
MST.LAYOUT_PANEL_VAXIS_PADDING_BOTTOM = 5;

MST.LAYOUT1_PANEL_VAXIS_PADDING_TOP_0 = 2;
MST.LAYOUT1_PANEL_VAXIS_PADDING_BOTTOM_0 = -2;
MST.LAYOUT1_PANEL_VAXIS_PADDING_TOP = -2;
MST.LAYOUT1_PANEL_VAXIS_PADDING_BOTTOM = 2;

MST.RANGE_VIEW_HEIGHT = 30;

MST.LAYOUT_SCROLL_HEIGHT = 64;
MST.LAYOUT_SCROLL_BAR_HEIGHT = 0;
MST.LAYOUT_SCROLL_DRAG_WIDTH = 40;
MST.LAYOUT_SCROLL_MIN_DATA_LENGTH = 20;
MST.LAYOUT_SCROLL_MIN_PAGE_LENGTH = 10;
MST.COLOR_PANDEL_FRAME = "rgb(220, 220, 220)";
MST.COLOR_PANDEL_GRID = "rgb(220, 220, 220)";
MST.COLOR_PANDEL_AXIS_TEXT = "rgb(92, 92, 92)";
// MST.COLOR_PANDEL_AXIS = "rgb(0, 0, 0)";
MST.COLOR_LAST_CLOSE = "#ff0000";
MST.COLOR_PANDEL_CROSS = "#96c5e3";
MST.COLOR_SCROLL_LINE = "#0070bf";
MST.COLOR_SCROLL_FILL = "rgba(224, 238, 247, 0.7)";
MST.COLOR_SCROLL_BKGND = "#f5f5f5";
MST.COLOR_SCROLL_LINE_RANGE = "#0070bf";
MST.COLOR_SCROLL_FILL_RANGE = "#cae1f1";
MST.COLOR_SCROLL_BKGND_RANGE = "#e6f0f9";
MST.COLOR_SCROLL_STONE_BKGND = "#ffffff";
MST.COLOR_SCROLL_STONE_BKGND_ACTION = "#0573c0";
MST.COLOR_SCROLL_MASK = "rgba(0, 0, 0, 0.3)";
MST.COLOR_SCROLL_MASK_ACTION = "rgba(100, 0, 0, 0.3)";
MST.COLOR_SCROLL_DRAG_LINE = "#a1a1a1";
MST.COLOR_SCROLL_DRAG_LINE_ACTION = "#69a7d6";
MST.COLOR_SCROLL_AREA_LINE = "#4572A7";
MST.COLOR_SCROLL_AREA_FILL = "rgba(115, 138, 166, 0.7)";
MST.COLOR_PANEL = ["#3c8dbc", "#ff0101", "#0101ff", "#a52a2a", "#000000", "#69e9ff", "#ff7a12",
                   "#23e2bd", "#9a69cb", "#4476d5", "#d8377a", "#e57d20", "#ffb700", "#1ac566",
                   "#73006a", "#286883", "#d5d593", "#c063bc", "#ffb0be", "#00a9c8", "#9f8136"];
MST.LINE_WIDTH =  [1, 2, 5];
// MST.colorIndex = 0;

MST.MIN_HEIGHT = 0.0;
// MST.INTRADAY_1M_STEP = 1;

// 日中图，当天的数据超过下面设置，计算最高最低时不参考昨天的数据
//while the data amount is less than the following threshold,see the previous day close value to calculate highest point
//if you don't want to use previous close value ,set the value by 0
//we use 1m bar data to draw Intraday Charts,so 120 means 120/60=2 hours
//MST.LASTDAY_REFERENCE_LIMIT = 120 / 5;
MST.LASTDAY_REFERENCE_LIMIT = 0;
// 日中图，计算最高最低时参考昨天的多少本数据
//threshold of how much we see the previous close value while calculate highest point
//if you don't want to use previous close value ,set the value by 0
//we use 1m bar data to draw Intraday Charts,so 120 means 120/60=3 hours
//MST.INTRADAY_LASTDAY_REFERENCE = 180;
MST.INTRADAY_LASTDAY_REFERENCE = 0;
MST.BENCHMARK_LIMIT = 10;

MST.prototype.dataRequest = null;
MST.prototype.dataRetryCount = 0;

MST.getThemeDataUrl = function(code, period, options)
{
    // return "/chart/theme_data?period=" + period + "&code=" + code;
    return "theme_" + code + "_" + period;
}

MST.getCompanyDataUrl = function(theme_id, code, key)
{
    return jsonp_server + JsonFile.theme_monthly.replace("%{theme_id}", theme_id).replace("%{company_code}", code);
}

MST.getStockDataUrl = function(code, period)
{
    // return "/chart/stock_data?code=" + code;
    return "stock_code_" + code + "_" + period;
}

MST.getUrl = function(path, name)
{
    return jsonp_server + "jsonp/" + path + "/" + name + ".jsonp";
}
