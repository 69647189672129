(function() {

var SvgChartGlobal = MST;
var SvgChartMapping = MST.SvgChartMapping;
var SvgChartDrawUtil = MST.SvgChartDrawUtil;
var SvgChartUtil = MST.SvgChartUtil;
var SvgChartProjector = MST.SvgChartProjector;
var SvgChartTechnical = MST.SvgChartTechnical;

var SvgChartScrollView = function(chart) {
	this.chart = chart;
	this.scrollMapping = new SvgChartMapping();
	this.mapping = new SvgChartMapping();

	this.scrollMapping.drawAreaWidth = this.chart.chartWidth;
	this.scrollMapping.drawAreaHeight = SvgChartGlobal.LAYOUT_SCROLL_HEIGHT;

	var leftVaxisWidth = 0;
	var rightVaxisWidth = 0;

	// if (this.mapping.twoAxes) {
		// leftVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
		// rightVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
	// } else if (this.mapping.vAxisType == 0) {
		// leftVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
		// rightVaxisWidth = 0;
	// } else {
		// leftVaxisWidth = 0;
		// rightVaxisWidth = SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH;
	// }

	// this.scrollMapping.setPadding(SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 + leftVaxisWidth, SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 + rightVaxisWidth,
	this.scrollMapping.setPadding(5 + leftVaxisWidth, 5 + rightVaxisWidth,
		SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING, SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + SvgChartGlobal.LAYOUT_SCROLL_BAR_HEIGHT);

	var scrollConfig = chart.chartConfig.scroll;
	this.scrollMapping.panel = scrollConfig.panel;
	this.scrollMapping.vAxis = scrollConfig.vAxis;
	this.scrollMapping.hAxis = scrollConfig.hAxis;

	this.frameDiv = document.createElement("div");
	this.frameDiv.style.position = "relative";
	this.frameDiv.className = "SvgChartScrollFrame";
	chart.chartDiv.appendChild(this.frameDiv);

	this.actionDiv = document.createElement("div");
	this.frameDiv.appendChild(this.actionDiv);

	this.actionDiv.style.position = "absolute";
	this.actionDiv.style.width = "100%";
	this.actionDiv.style.height = "100%";
	this.actionDiv.style.zIndex = 5;

	this.drawDiv = document.createElement("div");
	this.frameDiv.appendChild(this.drawDiv);

	this.drawDiv.style.position = "absolute";
	this.drawDiv.className = "SvgChartScrollMask";
	this.drawDiv.style.width = "100%";
	this.drawDiv.style.height = "100%";
	this.drawDiv.style.zIndex = 2;

	this.actionCanvasDiv = document.createElement("div");
	this.frameDiv.appendChild(this.actionCanvasDiv);

	this.actionCanvasDiv.style.position = "absolute";
	this.actionCanvasDiv.style.width = "100%";
	this.actionCanvasDiv.style.height = "100%";
	this.actionCanvasDiv.style.zIndex = "1";

	this.axisDiv = document.createElement("div");
	this.frameDiv.appendChild(this.axisDiv);

	this.axisDiv.style.position = "absolute";
	this.axisDiv.style.width = "100%";
	this.axisDiv.style.height = "100%";
	this.axisDiv.style.zIndex = "2";

	this.stoneDiv = document.createElement("div");
	this.frameDiv.appendChild(this.stoneDiv);

	this.stoneDiv.style.position = "absolute";
	this.stoneDiv.style.width = "100%";
	this.stoneDiv.style.height = "100%";
	this.stoneDiv.style.zIndex = 4;

	this.actionAreaDiv = document.createElement("div");
	this.frameDiv.appendChild(this.actionAreaDiv);

	this.actionAreaDiv.style.position = "absolute";
	this.actionAreaDiv.style.width = "100%";
	this.actionAreaDiv.style.height = "100%";
	this.actionAreaDiv.style.zIndex = 3;

	this.scrollDiv = document.createElement("div");
	this.frameDiv.appendChild(this.scrollDiv);

	this.scrollDiv.style.width = "100%";
	// this.scrollDiv.style.marginTop = "15px";
	this.scrollDiv.style.height = this.scrollMapping.drawAreaHeight + "px";
	this.scrollDiv.style.zIndex = 1;

	this.scrollDrawCanvas = SvgChartDrawUtil.createCanvas(this.drawDiv, this.chart.chartWidth, this.scrollMapping.drawAreaHeight);
	this.scrollActionCanvas = SvgChartDrawUtil.createCanvas(this.actionCanvasDiv, this.chart.chartWidth, this.scrollMapping.drawAreaHeight);
	this.scrollAxisCanvas = SvgChartDrawUtil.createCanvas(this.axisDiv, this.chart.chartWidth, this.scrollMapping.drawAreaHeight);
	this.scrollStoneCanvas = SvgChartDrawUtil.createCanvas(this.stoneDiv, this.chart.chartWidth, this.scrollMapping.drawAreaHeight);
	this.scrollActionAreaCanvas = SvgChartDrawUtil.createCanvas(this.actionAreaDiv, this.chart.chartWidth, this.scrollMapping.drawAreaHeight);

//	if (this.chart.chartConfig.actionView.active) {
		var util = new SvgChartUtil();
		util.attachTouchEvent(this, this.actionDiv);
		this.actionDiv.className = "SvgChartActionView";
		this.actionDiv.style.cursor = "pointer";
//	}

	// this.stoneDivLeft = document.createElement("div");
	// this.frameDiv.insertBefore(this.stoneDivLeft, this.scrollDiv);
//
	// this.stoneDivLeft.style.position = "absolute";
	// this.stoneDivLeft.style.width = SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH + "px";
	// this.stoneDivLeft.style.height = "100%";
	// // this.stoneDivLeft.style.background = "#ccc";
	// this.stoneDivLeft.style.zIndex = 3;
//
	// this.stoneDivRight = document.createElement("div");
	// this.frameDiv.insertBefore(this.stoneDivRight, this.scrollDiv);
//
	// this.stoneDivRight.style.position = "absolute";
	// this.stoneDivRight.style.width = SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH + "px";
	// this.stoneDivRight.style.height = "100%";
	// // this.stoneDivRight.style.background = "#ccc";
	// this.stoneDivRight.style.zIndex = 3;
//
	// this.stoneLeft = this.drawstoneDiv(this.stoneDivLeft, SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH, this.scrollMapping.drawAreaHeight);
	// this.stoneRight = this.drawstoneDiv(this.stoneDivRight, SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH, this.scrollMapping.drawAreaHeight);
};

SvgChartScrollView.prototype.drawstoneDiv = function(div, w, h)
{
	var context = SvgChartDrawUtil.createCanvas(div, w, h);
	var y0 = this.scrollMapping.paddingTop + 0.5;
	var x = Math.round(w / 2) + 0.5;
	var y = Math.round((h - SvgChartGlobal.LAYOUT_SCROLL_BAR_HEIGHT) / 2) - 1.5;
	SvgChartDrawUtil.line(context, x, y0, x, h - y0, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);
	var r = SvgChartDrawUtil.fillRect(context, x - 4, y - 7, 8, 14, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE, SvgChartGlobal.COLOR_SCROLL_STONE_BKGND);
	SvgChartDrawUtil.line(context, x - 1, y - 4, x - 1, y + 4, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);
	SvgChartDrawUtil.line(context, x + 1, y - 4, x + 1, y + 4, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);
	return r;
};

SvgChartScrollView.prototype.drawstone = function(x, active)
{
	var context = this.scrollStoneCanvas;
	var y0 = this.scrollMapping.paddingTop - 0.5;
	var h = this.scrollMapping.drawAreaHeight - SvgChartGlobal.LAYOUT_SCROLL_BAR_HEIGHT;
	var y = Math.round(h / 2) - 1.5;

	var fillColor = SvgChartGlobal.COLOR_SCROLL_STONE_BKGND;
	var lineColor = SvgChartGlobal.COLOR_SCROLL_DRAG_LINE;
	if (active) {
		fillColor = SvgChartGlobal.COLOR_SCROLL_STONE_BKGND_ACTION;
		lineColor = SvgChartGlobal.COLOR_SCROLL_DRAG_LINE_ACTION;
	}

	SvgChartDrawUtil.line(context, x, y0 - 1, x, h - 1, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);
	// SvgChartDrawUtil.fillRect(context, x - 4, y - 7, 8, 14, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE, fillColor);

//	var d = "M " + (x - 4) + ", " + (y - 10);
//	d += "L " + (x - 4) + ", " + (y + 10);
//	// d += "A 4,3,0,0,0," + (x + 4) + ", " + (y + 6);
//	d += "L " + (x + 4) + ", " + (y + 10);
//	d += "L " + (x + 4) + ", " + (y - 10);
//	// d += "A 4,3,0,0,0," + (x - 4) + ", " + (y - 6);
//	d += "L " + (x - 4) + ", " + (y - 10);
//	SvgChartDrawUtil.pathFill(context, d, 1, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE, fillColor);

	SvgChartDrawUtil.fillRect(context, x - 4, y - 10, 8, 20, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE, fillColor);

	SvgChartDrawUtil.line(context, x - 1, y - 4, x - 1, y + 4, lineColor);
	SvgChartDrawUtil.line(context, x + 1, y - 4, x + 1, y + 4, lineColor);
};

SvgChartScrollView.prototype.chart = null;
SvgChartScrollView.prototype.mapping = null;
SvgChartScrollView.prototype.scrollMapping = null;
SvgChartScrollView.prototype.scrollDiv = null;
SvgChartScrollView.prototype.frameDiv = null;
SvgChartScrollView.prototype.actionDiv = null;
SvgChartScrollView.prototype.axisDiv = null;
SvgChartScrollView.prototype.actionCanvasDiv = null;
SvgChartScrollView.prototype.scrollDrawCanvas = null;
SvgChartScrollView.prototype.scrollActionCanvas = null;
SvgChartScrollView.prototype.scrollAxisCanvas = null;
SvgChartScrollView.prototype.scrollActionStatus = null;
SvgChartScrollView.prototype.stoneDivLeft = null;
SvgChartScrollView.prototype.stoneDivRight = null;
SvgChartScrollView.prototype.stoneLeft = null;
SvgChartScrollView.prototype.stoneRight = null;
SvgChartScrollView.prototype.stoneLeftActive = false;
SvgChartScrollView.prototype.stoneRightActive = false;

SvgChartScrollView.prototype.drawActionArea = function()
{
	// console.log("========SvgChartScrollView draw start===========");

	this.mapping = this.chart.mainMapping;
	var startIndex = Math.min(0, this.mapping.dataStartIndex);
	this.scrollMapping.setDataRange(startIndex, this.chart.mainTechnical.data.length - startIndex);

//	this.scrollMapping.setPadding(5 - this.scrollMapping.getUnitWidth() / 2, 5 - this.scrollMapping.getUnitWidth() / 2,
//		this.scrollMapping.paddingTop, this.scrollMapping.paddingBottom);

	// Update width
	// this.updateCanvasSize();

	// if(this.mapping.updated == true) {
		// this.drawPanel();
		var projector = new SvgChartProjector();

		this.scrollMapping.setDataMinMax(this.chart.mainMapping.dataMinValue, this.chart.mainMapping.dataMaxValue);
		projector.updateDataMinMax([this.chart.mainTechnical], this.scrollMapping);

		SvgChartDrawUtil.clear(this.scrollActionAreaCanvas);
		projector.projectAxis(this.chart, this.scrollActionAreaCanvas, this.scrollMapping, -1);
		projector.projectAreaScroll(this.scrollActionAreaCanvas, [this.chart.mainTechnical], this.scrollMapping, this.mapping);
	// }

	// this.mapping.updated = false;
};

SvgChartScrollView.prototype.draw = function()
{
	// console.log("========SvgChartScrollView draw start===========");

	var startIndex = Math.min(0, this.mapping.dataStartIndex);
	this.scrollMapping.setDataRange(startIndex, this.chart.mainTechnical.data.length - startIndex);
	this.scrollMapping.dataBlank = this.chart.dataBlank;

	this.scrollMapping.setPadding(5 - this.scrollMapping.getUnitWidth() / 2, 5 - this.scrollMapping.getUnitWidth() / 2,
		this.scrollMapping.paddingTop, this.scrollMapping.paddingBottom);

	// Update width
	// this.updateCanvasSize();

	// if(this.mapping.updated == true) {
		// this.drawPanel();
		var projector = new SvgChartProjector();
		this.mapping = this.chart.mainMapping;

		this.scrollMapping.setDataMinMax(this.chart.mainMapping.dataMinValue, this.chart.mainMapping.dataMaxValue);
		projector.updateDataMinMax([this.chart.mainTechnical], this.scrollMapping);

		var hAxisValues = new Array();

		var timeFormat = SvgChartUtil.getTimeFormat(this.chart.period, "all");

		var label = timeFormat(new Date());
		var labelWidth = SvgChartUtil.measureText(this.chart, label);
		var labelGap = Math.ceil((labelWidth + 30) / this.scrollMapping.getUnitWidth());
		var rightGap = Math.max(Math.ceil((labelWidth / 2 - this.scrollMapping.paddingRight) / this.scrollMapping.getUnitWidth()), 0);
		var leftGap = Math.max(Math.ceil((labelWidth / 2 - this.scrollMapping.paddingLeft) / this.scrollMapping.getUnitWidth()), 0);

		var lastIndex = this.chart.mainTechnical.data.length - 1;
		var prevIndex = lastIndex + labelGap;

		for (var i = this.scrollMapping.dataStartIndex + this.scrollMapping.dataLength - 1 - rightGap; i >= this.scrollMapping.dataStartIndex + leftGap - 1 && i >= 0; i--) {
			var label = timeFormat(this.chart.mainTechnical.data[i].date);
			var labelPrev = i > 0 ? timeFormat(this.chart.mainTechnical.data[i - 1].date) : "";

			if (label != labelPrev && prevIndex - i >= labelGap) {
				hAxisValues.push({value:i, label:label});
				prevIndex = i;
			}
		}
		this.scrollMapping.setHAxisValues(hAxisValues);

		SvgChartDrawUtil.clear(this.scrollAxisCanvas);
		SvgChartDrawUtil.clear(this.scrollDrawCanvas);
//		projector.projectAxis(this.scrollAxisCanvas, this.scrollMapping, -1);
//		projector.projectScroll(this.scrollDrawCanvas, [this.chart.mainTechnical], this.scrollMapping);
		// this.drawActionArea();
		this.drawScrollAction();
	// }

	// this.mapping.updated = false;
};

SvgChartScrollView.prototype.hitTestScroll = function(x, y)
{
	var extendSpace = 0;

	var x0 = this.scrollMapping.transformIndex(this.mapping.dataStartIndex);
	var x1 = this.scrollMapping.transformIndex(this.mapping.dataStartIndex + this.mapping.dataLength + this.mapping.dataBlank - 1);
	// var y0 = this.scrollMapping.drawAreaHeight + this.scrollMapping.paddingBottom / 2;
	var h = this.scrollMapping.drawAreaHeight - SvgChartGlobal.LAYOUT_SCROLL_BAR_HEIGHT;
	var y0 = Math.round(h / 2) - 1.5;
	var y1 = y0 - 15;
	var y2 = y0 + 15;	
	var x2 = (x0 + x1) / 2;

	if (x <= x1 && x >= x0 && y > y0) {
		return "dragmiddle";
	}

	// if (x < x2 && x < x0 + SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 && x > x0 - SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2) {
	if (x < x0 + SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 + extendSpace && x > x0 - SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 - extendSpace) {
		if(y > y1 && y < y2){
			return "dragleft";
		}
	// } else if (x >= x2 && x < x1 + SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 && x > x1 - SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2) {
	} else if (x < x1 + SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 + extendSpace && x > x1 - SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 - extendSpace) {
		if(y > y1 && y < y2){
			return "dragright";
		}
	} else if (x >= x0 + SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 && x <= x1 - SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2) {
		return "dragmiddle";
	} else {
		return "moveto";
	}
};

SvgChartScrollView.prototype.onTouchEvent = function(target, pharse, cursorCoords)
{
	if(this.chart.actionView.mapping.status != SvgChartTechnical.DATA_STATUS_OK) {
		return true;
	}

	return this.onScrollTouchEvent(pharse, cursorCoords);
};


SvgChartScrollView.prototype.onScrollTouchEvent = function(pharse, cursorCoords)
{
//	if (this.chart.period == "5m") return;
	
	var preventDefault = false;

	var x, y;

	if(cursorCoords != null) {
		x = cursorCoords.x;
		y = cursorCoords.y;
	}
	var needUpdate = false;

	if(pharse == "touchstart") {
		var hittest = this.hitTestScroll(x, y);

		if (hittest == "moveto") {
//			if (this.chart.period == "5m") return;
			
			var len = this.mapping.dataLength + this.mapping.dataBlank;
			var toIndex = Math.round(this.scrollMapping.unTransformIndex(x) - len / 2);

			var startIndexMin = this.scrollMapping.dataStartIndex;
			var startIndexMax = this.chart.mainTechnical.data.length + this.chart.dataBlank - len;

			var startIndex = Math.max(startIndexMin, Math.min(toIndex, startIndexMax));
			
			var dataLength = Math.min(len, this.chart.mainTechnical.data.length - startIndex);
			this.mapping.setDataRange(startIndex, dataLength);
			this.mapping.dataBlank = len - dataLength;

			needUpdate = true;

		} else if (hittest != null) {
			// preventDefault = true;

			this.scrollActionStatus = {type: hittest, x: x, y: y, dataStartIndex:this.mapping.dataStartIndex, dataLength: this.mapping.dataLength + this.mapping.dataBlank};
			// this.drawScrollAction();
		}

		if(window.current_scrolling_target == undefined || window.current_scrolling_target == null){
			window.current_scrolling_target = this;
		}
	}
	else if(pharse == "touchmove") {
		// console.log("========  touchmove  ===========");
		if(this.scrollActionStatus != null) {
			preventDefault = true;

			var offset = Math.round(this.scrollMapping.unTransformIndex(x) - this.scrollMapping.unTransformIndex(this.scrollActionStatus.x));

			var dataStartIndexLast = this.mapping.dataStartIndex;
			var dataLengthLast = this.mapping.dataLength;
			if(this.scrollActionStatus.type == "dragleft") {
//				if (this.chart.period == "5m") return;
				
				var startIndexMin = this.scrollMapping.dataStartIndex;
				var startIndexMax = this.scrollActionStatus.dataStartIndex + this.scrollActionStatus.dataLength - this.chart.minPageLength;

				var startIndex = Math.max(startIndexMin, Math.min(this.scrollActionStatus.dataStartIndex + offset, startIndexMax));
				var len = this.scrollActionStatus.dataStartIndex + this.scrollActionStatus.dataLength - startIndex;

				var dataLength = Math.min(len, this.chart.mainTechnical.data.length - startIndex);
				this.mapping.setDataRange(startIndex, dataLength);
				this.mapping.dataBlank = len - dataLength;

			}
			else if(this.scrollActionStatus.type == "dragright") {
//				var lengthMin = Math.max(this.chart.minPageLength, -this.scrollActionStatus.dataStartIndex + 1);
				var lengthMin = this.chart.minPageLength;

//				if (this.chart.period == "5m") {
//					lengthMin = this.chart.mainTechnical.data.length;
//				}
				
				var lengthMax = this.chart.mainTechnical.data.length + this.chart.dataBlank - this.scrollActionStatus.dataStartIndex;

				var len = Math.max(lengthMin, Math.min(this.scrollActionStatus.dataLength + offset, lengthMax));

				var dataLength = Math.min(len, this.chart.mainTechnical.data.length - this.scrollActionStatus.dataStartIndex);
				
				this.mapping.setDataRange(this.scrollActionStatus.dataStartIndex, dataLength);
				this.mapping.dataBlank = len - dataLength;
			}
			else if(this.scrollActionStatus.type == "dragmiddle") {
//				if (this.chart.period == "5m") return;
				
				var startIndexMin = Math.max(this.scrollMapping.dataStartIndex, -this.scrollActionStatus.dataLength + 1);
				var startIndexMax = this.chart.mainTechnical.data.length + this.chart.dataBlank - this.scrollActionStatus.dataLength;

				var startIndex = Math.max(startIndexMin, Math.min(this.scrollActionStatus.dataStartIndex + offset, startIndexMax));

				var dataLength = Math.min(this.scrollActionStatus.dataLength, this.chart.mainTechnical.data.length - startIndex);
				this.mapping.setDataRange(startIndex, dataLength);
				this.mapping.dataBlank = this.scrollActionStatus.dataLength - dataLength;
			}

//			if (dataStartIndexLast != this.mapping.dataStartIndex || dataLengthLast != this.mapping.dataLength) {
//				 if (Raphael.svg) {
					needUpdate = true;
//				 } else {
//					 this.drawScrollAction();
//				 }
//			}
		// } else {
			// SvgChartDrawUtil.setAttr(this.stoneLeft, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND);
			// SvgChartDrawUtil.setAttr(this.stoneRight, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND);
			// var hittest = this.hitTestScroll(x, y);
			// if (hittest == "dragleft") {
				// SvgChartDrawUtil.setAttr(this.stoneLeft, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND_ACTION);
			// } else if (hittest == "dragright") {
				// SvgChartDrawUtil.setAttr(this.stoneRight, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND_ACTION);
			// } else if (hittest == "dragmiddle") {
				// SvgChartDrawUtil.setAttr(this.stoneLeft, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND_ACTION);
				// SvgChartDrawUtil.setAttr(this.stoneRight, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND_ACTION);
			// }
		}
	}
	else if(pharse == "touchend") {
		this.actionDiv.style.cursor = "pointer";
		if(this.scrollActionStatus != null) {
			// preventDefault = true;

			this.scrollActionStatus = null;
			// this.drawScrollAction();
			needUpdate = true;
		}
		// SvgChartDrawUtil.setAttr(this.stoneLeft, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND);
		// SvgChartDrawUtil.setAttr(this.stoneRight, "fill", SvgChartGlobal.COLOR_SCROLL_STONE_BKGND);
	}

	this.stoneLeftActive = false;
	this.stoneRightActive = false;
	var hittest = this.hitTestScroll(x, y);
	if (hittest == "dragleft") {
		this.actionDiv.style.cursor = "e-resize";
		this.stoneLeftActive = true;
	} else if (hittest == "dragright") {
		this.actionDiv.style.cursor = "e-resize";
		this.stoneRightActive = true;
	} else if (hittest == "dragmiddle") {
		this.actionDiv.style.cursor = "pointer";
		this.stoneLeftActive = true;
		this.stoneRightActive = true;
	}
	else{
		this.actionDiv.style.cursor = "pointer";
	}
	if (pharse == "touchout") {
		this.actionDiv.style.cursor = "pointer";
		this.stoneLeftActive = false;
		this.stoneRightActive = false;

		// if mouseout of scrolling view
		// this.scrollActionStatus = null;
		// needUpdate = true;
	}

	if (needUpdate) {
		this.chart.setDataRange(this.mapping.dataStartIndex, this.mapping.dataLength, this.mapping.dataBlank);
	} else {
		this.drawScrollAction();
	}

	return !preventDefault;
};

SvgChartScrollView.prototype.drawScrollAction = function()
{
	// console.log("========  drawScrollAction  ===========" + new Date());
	// Get context
	// var context = this.scrollActionCanvas;

	// Clear it
	SvgChartDrawUtil.clear(this.scrollActionCanvas);
	SvgChartDrawUtil.clear(this.scrollStoneCanvas);

	// Project technicals
	if(this.mapping.status == SvgChartTechnical.DATA_STATUS_OK) {

		var y0 = this.scrollMapping.paddingTop - 1.5;
		var y1 = this.scrollMapping.drawAreaHeight - this.scrollMapping.paddingBottom + 0.5;
		// var x0 = this.scrollMapping.transformIndex(Math.max(0, this.mapping.dataStartIndex)) - 1;
		var x0 = this.scrollMapping.transformIndex(this.mapping.dataStartIndex) + 0.5;
		var x1 = this.scrollMapping.transformIndex(this.mapping.dataStartIndex + this.mapping.dataLength - 1 + this.mapping.dataBlank) + 0.5;

		// console.debug("this.mapping.dataStartIndex:" + this.mapping.dataStartIndex + ", this.mapping.dataLength:" + this.mapping.dataLength);
		// console.debug("x0:" + x0 + ", x1:" + x1);

		// this.stoneDivLeft.style.left = x0 - SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 + "px";
		// this.stoneDivRight.style.left = x1 - SvgChartGlobal.LAYOUT_SCROLL_DRAG_WIDTH / 2 + "px";

		if (this.chart.rangeView != null) {
			this.chart.rangeView.setDataRange(this.mapping.dataStartIndex, this.mapping.dataLength);
		}

		// if (Raphael.svg) {
			// var projector = new SvgChartProjector();
			// projector.projectScrollRange(context, [this.chart.mainTechnical], this.scrollMapping, this.mapping);


			// SvgChartDrawUtil.fillRect(this.scrollActionCanvas, x0, y0, x1 - x0, y1 - y0, null, SvgChartGlobal.COLOR_SCROLL_BKGND_RANGE);

			y1 += 1;
			var y2 = this.scrollMapping.drawAreaHeight - 1.5;
			// SvgChartDrawUtil.fillRect(this.scrollStoneCanvas, x0, y1, x1 - x0, y2 - y1, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE, SvgChartGlobal.COLOR_SCROLL_STONE_BKGND);
			// if ((x1 - x0) > 15) {
			// 	var x = Math.round((x1 + x0) / 2) + 0.5;
			// 	for (var i = -1; i <= 1; i++) {
			// 		SvgChartDrawUtil.line(this.scrollStoneCanvas, x + i * 2, y1 + 3, x + i * 2, y2 - 2, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);
			// 	}
			// }
		// }

		this.drawstone(x0, this.stoneLeftActive);
		this.drawstone(x1, this.stoneRightActive);

		this.drawActionArea();

		SvgChartDrawUtil.line(this.scrollStoneCanvas, 0.5, 1.5, x0 + 0.5, 1.5, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);
		SvgChartDrawUtil.line(this.scrollStoneCanvas, x1 - 0.5, 1.5, this.scrollMapping.drawAreaWidth + 0.5, 1.5, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);

		SvgChartDrawUtil.line(this.scrollStoneCanvas, x0, y1, x1, y1, SvgChartGlobal.COLOR_SCROLL_DRAG_LINE);
	}
};

MST.SvgChartScrollView = SvgChartScrollView;
}());
