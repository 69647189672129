import '../packs-js/chart.js';
import {$, MstCom} from '../packs-js/common/_all.js';


export function initializeCharts(selector = '.mini-chart-list') {
    // 遍历所有的指定元素
    $(selector).each(function () {
        let chart = this;
        if (!$(chart).hasClass('hidden_chart')) {
            let theme_id = $(chart).data('theme-id');
            let myChart = new MST.SvgChart(chart);
            let url = `theme/index/daily/${theme_id}.json`;

            MstCom.fetchJson(url, {
                cloud: true,
                type: 'text',
                error: function (response) {
                    const nativeDiv = $(chart).get(0); // 获取原生 DOM 元素
                    nativeDiv.innerHTML = ''; // 清空内容
                    const noImgPath = $(chart).attr('no_img_path'); // 获取 no_img_path 属性
                    let newImg = document.createElement('img');
                    newImg.src = noImgPath;
                    nativeDiv.appendChild(newImg); // 添加新的 img 元素
                },
                success: function (response) {

                    var newData = []

                    response.data["i"].forEach(item => {

                        // 将日期格式从 'yyyyMMdd' 转换为 'yyyy/MM/dd'
                        let formattedDate = item.d.replace(/(\d{4})(\d{2})(\d{2})/, '$1/$2/$3');
                        let closeValue = parseFloat(item.v);
                        newData.push({
                            date: formattedDate,
                            close: closeValue
                        });
                    });
                    $(chart).empty();
                    myChart.drawSimpleMountainChart("", newData, {
                        viewConfig: {main: {layout: {height: 35}}},
                        technicalConfig: {
                            mountain: {
                                colorIsConstant: true,
                                dataColor: ["#4572A7"],
                                dataFillcolor: ["#dddddd"]
                            }
                        }
                    });
                },
                debug: false
            })
        }
    });
};