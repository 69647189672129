// console.debug('import common exception');
export let com = {};

// Exp: 
// com.tryCatch(function(){
//     {programme block}
// });
com.tryCatch = function(block) {
    try {
        block();
    } catch (e) {
        console.error(e);
    }
}