MST.lang['zh-cn'] = {
	'timeframe' : '时间',
	'1D' : '1天',
	'1W' : '1周',
	'1M' : '1个月',
	'3M' : '3个月',
	'6M' : '6个月',
	'YTD' : 'Year To Date',
	'1Y' : '1年',
	'5Y' : '5年',
	'ALL' : 'ALL',
	'charttype' : '图表类型',
	'mountain' : '山图',
	'line' : '线图',
	'candle' : 'K线图',
	'open' : '开盘价',
	'high' : '最高价',
	'low' : '最低价',
	'close' : '收盘价',
	'transit' : '转换线',
	'base' : '基准线',
	'precede1' : '先行1',
	'precede2' : '先行2',
	'late' : '迟行',
	'volume' : '交易量',
	'stockPrice' : '股价',

    'noData_simple' : '没有数据',
    'loadFail_simple' : '数据准备中',
	
	'timeformat_MMM' : ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
	'timeformat_MMMM' : ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
	'timeformat_WWW' : ['日', '一', '二', '三', '四', '五', '六'],
	'timeformat_WWWW' : ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
};

MST.timeformat['zh-cn'] = {
	'y' : 'YYYY',
    'ym' : 'yyyy/MM',
    'ymd' : 'yyyy/MM/dd',
    'ymdW' : 'yyyy/MM/dd（WWWW）',
	'YMDW' : 'YYYY年MM月DD日 WWWW',
	'MDh' : 'MM月DD日hh:mm',
	'h' : 'hh:00',
	'hm' : 'hh:mm'
};
