import global from 'global';
import $ from 'jquery';
import noUiSlider from 'nouislider';
import Odometer from 'odometer';

global.jQuery = global.$ = $;

import {com as MstCom} from  './_base';
global.MstCom = MstCom;

// 引入国际化文本
import { TEXT } from '../../vendor/custom/message.js';
global.TEXT = TEXT;

export { global, $, MstCom, noUiSlider, Odometer, TEXT };