(function() {

var SvgChartGlobal = MST;
var SvgChartDrawUtil = MST.SvgChartDrawUtil;

var SvgChartProjector = function () {
};

SvgChartProjector.prototype.updateDataMinMax = function(technicalList, mapping)
{
	// console.log("========updateDataMinMax start ===========");
	var min_arr = [];
	var max_arr = [];

	var data;
	var name;

	for(var t = 0; t < technicalList.length; t++) {
		var min = null;
		var max = null;

		var technical = technicalList[t];
		if (technical.type == "dummy") continue;

		var start = Math.max(0, mapping.dataStartIndex);
		var end = mapping.dataStartIndex + mapping.dataLength;
		
		if (technical.name == "Ichimoku") {
			end += mapping.dataBlank;
		}

		switch(technical.name) {
		case "candle":
		case "line":
		case "sub_line":
		case "mountain":
			data = technical.data;
			name = "close";
			for(var i = start; i < end; i++) {
				var v = data[i];

				if(v == null || v == "") continue;

				var vh = Number(v.close);
				var vl = Number(v.close);
				if (technical.name == "candle") {
					vh = Number(v.high);
					vl = Number(v.low);
				}

//                if (mapping.benchmark == true) {
//                    vh = Number(v.close);
//                    vl = Number(v.close);
//                }

				if(v != null) {
					if(vl != 0 && (min == null || min > vl)) {
						min = vl;
					}

					if(vh != 0 && (max == null || max < vh)) {
						max = vh;
					}
				}
			}

			break;

//		case "line":
//		case "mountain":
		case "intraday":
		case "twoDays":
			data = technical.data;
			name = "close";
			for(var i = start; i < end; i++) {
				var v = data[i];

				if(v == null || v.close == null) continue;

				var vc = Number(v.close);

				if(v != null) {
					if(vc != 0 && (min == null || min > vc)) {
						min = vc;
					}

					if(vc != 0 && (max == null || max < vc)) {
						max = vc;
					}
				}
			}

			break;

//		case "Ichimoku":
//		case "MACD":
//		case "BollingerBand":
//		case "SRV":
//		case "MA18":
//		case "MA38":
//		case "MA90":
//		case "MA":
		case "RSI":
		case "PSY":
		case "SRV":
			max = 100;
			min = 0;
		default:

			if(technical.dataLoadType == MST.SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN) {
				data = technical.view.chart.mainTechnical.data;
				name = technical.dataLoadName;

				for(var i = start; i < end; i++) {
					if(data[i] == null) continue;

					v = data[i][name];

					if(v == null || v == "") continue;

					v = Number(v);

					if(v != null) {
						if(min == null || min > v) {
							min = v;
						}

						if(max == null || max < v) {
							max = v;
						}
					}
				}
			} else {
				data = technical.data;
				if (data != null && (technical.type == "sub" || !mapping.benchmark)) {
					for (var j = 0; j < technical.labels.length; j++) {
						for (var i = start; i < end; i++) {
							var v = data[technical.labels[j].data][i];
							if (v != null) {
								if (min == null || min > v) {
									min = v;
								}
								if (max == null || max < v) {
									max = v;
								}
							}
						}
					}
				}
			}
		

//			break;

//		default:
//			if(technical.dataLoadType == MST.SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN) {
//				data = technical.view.chart.mainTechnical.data;
//			}
//			else {
//				data = technical.data;
//			}
//			name = technical.dataLoadName;
//
//			for(var i = start; i < end; i++) {
//				if(data[i] == null) continue;
//
//				v = data[i][name];
//
//				if(v == null || v == "") continue;
//
//				v = Number(v);
//
//				if(v != null) {
//					if(min == null || min > v) {
//						min = v;
//					}
//
//					if(max == null || max < v) {
//						max = v;
//					}
//				}
//			}
		}

		if (technical == technical.view.chart.mainTechnical && technical.view.chart.drawLastClose()) {
			if (technical.lastCloseStartIndex + 1 < end && technical.lastCloseValue != null) {
				v = technical.lastCloseValue;
				if (min == null || min > v) {
					min = v;
				}
				if (max == null || max < v) {
					max = v;
				}
			}
			if (technical.lastlastCloseStartIndex + 1 < end && technical.lastlastCloseValue != null) {
				v = technical.lastlastCloseValue;
				if (min == null || min > v) {
					min = v;
				}
				if (max == null || max < v) {
					max = v;
				}
			}
		}
		if (technical.name == "volume") {
			min = 0;
			if (max <= 0) max = 10;
		}
		if (technical.name == "volume1" || technical.name == "volume2") {
			min = 0;
			if (max == null) max = 10;
		}

		if (min != null && max != null && mapping.benchmark == true) {
			if(data[start] != null && data[start][name] && data[start][name] != 0){
				min = (min / data[start][name] - 1) * 100;
				max = (max / data[start][name] - 1) * 100;
			}
			else {
				// process two benchmar data has different data length
				for(var k = start; k < end; k++){
					if(data[k] && data[k][name] && data[k][name] != 0) break;
				}
				if(k != end){
					min = (min / data[k][name] - 1) * 100;
					max = (max / data[k][name] - 1) * 100;
				}else{
					min = 0;
					max = 1;
				}
			}
		// } else if (mapping.intraday == true) {
			// v = technical.data[0]["close"];
			// min = Math.min(min, v);
			// max = Math.max(max, v);
		}

		if (min > 0 && max < min * (1 + SvgChartGlobal.MIN_HEIGHT)) {
			max = min * (1 + SvgChartGlobal.MIN_HEIGHT);
		}

		if (min != null && max != null) {
			if (min_arr[technical.vAxis.vAxis] == null || min_arr[technical.vAxis.vAxis] > min) {
				min_arr[technical.vAxis.vAxis] = min;
			}
		
			if (max_arr[technical.vAxis.vAxis] == null || max_arr[technical.vAxis.vAxis] < max) {
				max_arr[technical.vAxis.vAxis] = max;
			}
		}
	}

	mapping.setDataMinMax(min_arr, max_arr);
};

SvgChartProjector.prototype.project = function(context, technicalList, mapping) {
	for (var t = 0; t < technicalList.length; t++) {
		var technical = technicalList[t];

//		if (technical.type == "dummy") continue;

		var name = technical.name;
		var period = technical.view.chart.period;
		// if (mapping.benchmark && (name == "candle" || name == "mountain")) {
		if (mapping.benchmark && name != 'news') {
			name = "line";
		}

		switch(name) {
			case "candle":
				this.projectCandle(context, technical, mapping);
				break;

			case "Ichimoku":
				if (!mapping.benchmark) {
					this.projectIchimoku(context, technical, mapping);
				}
				break;
				
			case "LinearTrend":
				if (!mapping.benchmark) {
					this.projectLinearTrend(context, technical, mapping);
				}
				break;		

			case "MACD":
			case "BollingerBand":
			case "SRV":
				this.projectLines(context, technical, mapping);
				break;
			case "MA18":
			case "MA38":
			case "MA90":
			case "MA":
				if (technical.type == "sub" || !mapping.benchmark) {
					this.projectLines(context, technical, mapping);
				}
				break;

			case "intraday":
				this.projectIntraday(context, technical, mapping);
				break;

			case "volume1":
			case "volume2":
			case "twoDays":
			case "line":
            case "sub_line":
			case "mountain":
			case "portfolioLine":
			case "portfolioMountain":
				this.projectLine(context, technical, mapping);
				break;

			case "volume":
				this.projectVolume(context, technical, mapping);
				break;

			case "news":
				this.projectNews(context, technical, mapping);
				break;

			case "maxMin":
				this.projectMaxMin(context, technical, mapping);
				break;

			case "Parabolic":
				this.projectParabolic(context, technical, mapping);
				break;

			default:
				 this.projectLines(context, technical, mapping);
		}
	}

	var mainTechnical = technicalList[0].view.chart.mainTechnical;
//	if (technicalList[0] == mainTechnical && mainTechnical.showPick() && technical.view.chart.pickTechnical.data != null) {
//		this.projectPick(context, technical.view.chart.pickTechnical, mapping);
//	}
	if (technicalList[0] == mainTechnical && mainTechnical.view.chart.drawLastClose()) {
		this.projectLastClose(context, mainTechnical, mapping);
	}
};

SvgChartProjector.prototype.projectNews= function(context, technical, mapping) {
	var chart = technical.view.chart;
	var context = chart.newsCanvas;
//	SvgChartDrawUtil.clear(context);
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;
	var dataColor = technical.view.chart.pickColors;

	var mainTechnical = technical.view.chart.mainTechnical;
	var candleData = mainTechnical.data;
	
	if (candleData[start] == null) return;
	
	var ssTechnical = technical.view.chart.newsSSTechnical;
	var hrTechnical = technical.view.chart.newsHRTechnical;
	
	if (technical.merge == null) {
//		console.log("merge");
		technical.data = technical.data.concat(ssTechnical.data);
		technical.data = technical.data.concat(hrTechnical.data);
		technical.data.sort(function(a, b){
			  var x = a.date;
			  var y = b.date;
			  if (x < y) {return -1;}
			  if (x > y) {return 1;}
			  return 0;
		});
		technical.merge = true;
	}

	var start_date = new Date(candleData[start].date);
	var end_date = new Date(candleData[end - 1].date);
	var time = Math.round((end_date.getTime() - start_date.getTime()) / (24*3600*1000));
//	alert(time);
	
	var d = 1;
	if (time > 7) {
		d = 2;
	}
	if (time > 32) {
		d = 7;
	}
	if (time > 190) {
		d = 30;
	}
	if (time > 366 * 3) {
		d = 180;
	}

	var data = technical.analysisNews(candleData, d);
	
	var length = 0;
	for (var i = start; i < end; i++) {
		if (data[i] == null) continue;
    	length += data[i].length;
	}
	var newsStatus = technical.view.chart.newsStatus;
	var news_redraw = false;
	if (newsStatus == null) {
		news_redraw = true;
	} else if (newsStatus.start != start || newsStatus.end != end
			|| newsStatus.length != length || newsStatus.newsType != technical.view.chart.newsType) {
		news_redraw = true;
	}
	news_redraw = true;
	
//	if (news_redraw && technical.view.chart == myChart) {
	if (news_redraw) {
//		insert_news(data, start, end);
		
		(function (t, data, start, end) {
			if (t.newsTimer != null) {
				clearTimeout(t.newsTimer);
			}
	       	t.newsTimer = setTimeout(function() {
	       		insert_news(data, start, end);
	       	}, 500);
		})(technical, data, start, end);

		technical.view.chart.newsStatus = {data : data, start : start, end : end, length : length, newsType : technical.view.chart.newsType};
	}
	
//	return;
	
//	var arr = [];
//	var selected_i = null;

	var actionView = technical.view.chart.actionView;
	var objs = actionView.actionDiv.childNodes;
	for (var i = objs.length - 1; i > 0; i--) {
		actionView.actionDiv.removeChild(objs[i]);
	}
	var color = "#014099";
	var newsType = technical.view.chart.newsType;
	
	technical.view.chart.newsDiv.innerHTML = "";


	for (var i = start; i < end; i++) {
		var show_data = [];
		if (data[i] == null || candleData[i].close == null) continue;
		
		var news_len = 0;
		for (var j = 0; j < data[i].length; j++) {
			var news = data[i][j];
//			if(!newsType[news.type]) continue;
//			if (news.type != "mk" && news.type != "edi") {
				news_len++;
				show_data.push(news);
//			}
			//if (news.type != "mk" && news.type.indexOf("mk") != -1) {
				//var show = false;
                //for (var t = 0; t < mk_types.length; t++) {
			    //	if (newsType[mk_types[t]]) {
			    //		show = true;
			    //		break;
			    //	}
			    //}

				//if (!show) continue;
			//}

		}
		if (news_len == 0) continue;

		//var x = mapping.transformIndex(i) + 12.5;
		var x = mapping.transformIndex(i);
		
		var v = candleData[i].close;
		if (mapping.benchmark == true) {
			v = (v / candleData[start]["close"] - 1) * 100;
		} else if (mainTechnical.name == "candle" || mainTechnical.name == "bar") {
			v = candleData[i].high;
		}
		
		//var y = mapping.transformValue(v, 0) + 0.5 - 7;
//		var y = mapping.transformValue(v, 0) + 0.5;
		var y = mapping.drawAreaHeight - 22;

		var newsItem = SvgChartDrawUtil.triangle(context, x, y - 1, color);
		
		var text = SvgChartDrawUtil.fillText(context, x, y - 5, news_len, "#fff", "center", 'bold 14px Helvetica');
		
		
		var div = document.createElement("div");
		chart.actionView.actionDiv.appendChild(div);
		div.className = "newsFlagDiv";
//		div.style.width = "29px";
//		div.style.height = "16px";
//		div.style.top = y - 17 + "px";
//		div.style.left = x - 15 + "px";
		//div.style.backgroundColor = "#fff";
		div.style.width = "20px";
		div.style.height = "20px";
		div.style.top = y - 19 + "px";
		div.style.left = x - 10 + "px";

		//sort
		show_data.sort(function(a,b) {
			var a_date = Date.parse(a["date"].replace(/-/g, "/"));
			var b_date = Date.parse(b["date"].replace(/-/g, "/"));
			return b_date - a_date;
		});

		actionView.showNews(show_data);
		(function (news_list, newsType, x, y, newsItem) {
			var fun = function(event) {
				
				actionView.showNews(news_list, newsType, x, y, null);
				if(!event) var event = window.event;
				event.cancelBubble = true;
				event.returnValue = false;
				if (event.stopPropagation) event.stopPropagation();
				if (event.preventDefault) event.preventDefault();
			};
			div.onmousemove = fun;
			
		})(show_data, technical.view.chart.newsType, x, y, div);
		
	}

};

SvgChartProjector.prototype.projectMaxMin = function(context, technical, mapping) {
	var chart = technical.view.chart;
	if (chart.period == '1m' || chart.period == '5m') return;
	if (technical.view.benchmark) return;
	
	var context = chart.newsCanvas;
//	SvgChartDrawUtil.clear(context);
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var mainTechnical = technical.view.chart.mainTechnical;
	var candleData = mainTechnical.data;
	
	if (candleData[start] == null) return;
	var last_date = candleData[candleData.length - 1].date;
	var last_year = last_date.substring(0, 4);
	var last_month = last_date.substring(5, 7);
	
	var max_list = [];
	var min_list = [];
	var max = null;
	var min = null;
	var year = 1900;
	
	for (var i = 0; i < candleData.length; i++) {
		if (candleData[i] == null || candleData[i].close == null || candleData[i].date == null) continue;
		
		var _year = candleData[i].date.substring(0, 4);
		if (year != _year && max != null && min != null) {
			if (_year < last_year || (_year == last_year && last_month >= '04')) {
				max_list.push(max);
				min_list.push(min);
				max = null;
				min = null;
			}
		}
		year = _year;
		
		if (max == null || candleData[i].high > candleData[max].high) {
			max = i;
		}
		if (min == null || candleData[i].low < candleData[min].low) {
			min = i;
		}
	}
	max_list.push(max);
	min_list.push(min);
	
	var type = "volume";
	if (chart.exchange == 'US') type = "price2";

	var last_i = 1000000;
	var last_y = 1000000;
	var span_i = Math.floor(75 / mapping.getUnitWidth());
	var span_y = 30;
	for (var i = max_list.length - 1; i >= 0; i--) {
		if (max_list[i] < (start + span_i / 2) || max_list[i] >= end) continue;
		
		var candle = candleData[max_list[i]];
		var x = mapping.transformIndex(max_list[i]);
		
		var v = candle.high;
		var y = mapping.transformValue(v, 0) + 0.5;

		if (Math.abs(last_i - max_list[i]) <= span_i && Math.abs(last_y - y) <= span_y) continue;
		last_i = max_list[i];
		last_y = y;

		var date = chart.timeFormat(candle.date);
		var showv = MST.SvgChart.numberFormatFunc(v, type);
		SvgChartDrawUtil.fillText(context, x + 2, y - 20, date, technical.dataColor[0], "center");
		SvgChartDrawUtil.fillText(context, x, y - 7, showv, technical.dataColor[0], "center");
	}
	var last_i = 1000000;
	for (var i = min_list.length - 1; i >= 0; i--) {
		if (min_list[i] < (start + span_i / 2) || min_list[i] >= end) continue;
		
		var candle = candleData[min_list[i]];
		var x = mapping.transformIndex(min_list[i]);
		
		var v = candle.low;
		var y = mapping.transformValue(v, 0) + 0.5;

		if (Math.abs(last_i - min_list[i]) <= span_i && Math.abs(last_y - y) <= span_y) continue;
		last_i = min_list[i];
		last_y = y;

		var date = chart.timeFormat(candle.date);
		var showv = MST.SvgChart.numberFormatFunc(v, type);
		SvgChartDrawUtil.fillText(context, x + 2, y + 25, date, technical.dataColor[1], "center");
		SvgChartDrawUtil.fillText(context, x, y + 13, showv, technical.dataColor[1], "center");
	}
}
	
SvgChartProjector.prototype.projectLastClose = function(context, technical, mapping) {
	// console.log("======projectLastClose======");
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;
	var lastCloseStartIndex = technical.lastCloseStartIndex;
	if (lastCloseStartIndex + 1 >= end) return;

	var dataColor = technical.view.chart.pickColors;

	// var data = technical.view.chart.mainTechnical.data;
//
	// if (data[lastCloseStartIndex] == null) return;

	var v = technical.lastCloseValue;

	if (v == null) return;

	var x = mapping.transformIndex(lastCloseStartIndex) + 0.5;
	var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;
	var x1 = mapping.transformIndex(end) + 0.5;

	SvgChartDrawUtil.line(context, x, y, x1, y, SvgChartGlobal.COLOR_LAST_CLOSE, "5,5");

	var v = technical.lastlastCloseValue;

	if (v == null) return;

	var x0 = mapping.transformIndex(technical.lastlastCloseStartIndex) + 0.5;
	var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;

	SvgChartDrawUtil.line(context, x0, y, x, y, SvgChartGlobal.COLOR_LAST_CLOSE, "5,5");
};

SvgChartProjector.prototype.projectIchimoku = function(context, technical, mapping) {
	// console.log("======projectIchimoku======");
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength + mapping.dataBlank;

	var data = technical.data;

	context.lineWidth = 1;

	var pTransit = new Array();
	var pBase = new Array();
	var pPrecede1 = new Array();
	var pPrecede2 = new Array();
	var pLater1 = new Array();
	var cloud = new Array();

	for(var i = start; i < end; i++) {
		var vTransit = data["resultTransit"][i];
		var vBase = data["resultBase"][i];
		var vPrecede1 = data["resultPrecede1"][i];
		var vPrecede2 = data["resultPrecede2"][i];
		var vLater1 = data["resultLater1"][i];

		var x = mapping.transformIndex(i) + 0.5;

		if(vTransit != null) {
			var yTransit = mapping.transformValue(vTransit) + 0.5;
			pTransit.push({x : x, y : yTransit});
		}
		if(vBase != null) {
			var yBase = mapping.transformValue(vBase) + 0.5;
			pBase.push({x : x, y : yBase});
		}
		if(vPrecede1 != null) {
			var yPrecede1 = mapping.transformValue(vPrecede1) + 0.5;
			pPrecede1.push({x : x, y : yPrecede1});
		}
		if(vPrecede2 != null) {
			var yPrecede2 = mapping.transformValue(vPrecede2) + 0.5;
			pPrecede2.push({x : x, y : yPrecede2});
			if(vPrecede1 != null) {
				cloud.push({x : x, y : yPrecede2});
			}
		}
		if(vLater1 != null) {
			var yLater1 = mapping.transformValue(vLater1) + 0.5;
			pLater1.push({x : x, y : yLater1});
		}

//		if(vPrecede1 != null && vPrecede2 != null) {
//			var yPrecede1 = mapping.transformValue(vPrecede1) + 0.5;
//			var yPrecede2 = mapping.transformValue(vPrecede2) + 0.5;
//			pPrecede2.push({x : x, y : yPrecede2});
//		}
	}
	for(var i = end - 1; i >= start; i--) {
		var vPrecede1 = data["resultPrecede1"][i];
		var vPrecede2 = data["resultPrecede2"][i];
		if(vPrecede1 != null && vPrecede2 != null) {
			var x = mapping.transformIndex(i) + 0.5;
			var yPrecede1 = mapping.transformValue(vPrecede1) + 0.5;
			cloud.push({x : x, y : yPrecede1});
		}
	}

//	SvgChartDrawUtil.pathFill(context, cloud, 1, "#FFFFFF", "#AAAAAA", 0.5);
	SvgChartDrawUtil.pathFill(context, cloud, 1, "#FFFFFF", "rgba(170, 170, 170, 0.5)");
	SvgChartDrawUtil.path(context, pTransit, technical.lineWidth[0], technical.dataColor[0]);
	SvgChartDrawUtil.path(context, pBase, technical.lineWidth[1], technical.dataColor[1]);
	SvgChartDrawUtil.path(context, pPrecede1, technical.lineWidth[2], technical.dataColor[2]);
	SvgChartDrawUtil.path(context, pPrecede2, technical.lineWidth[3], technical.dataColor[3]);
	SvgChartDrawUtil.path(context, pLater1, technical.lineWidth[4], technical.dataColor[4]);
};

SvgChartProjector.prototype.projectLinearTrend = function(context, technical, mapping)
{
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var data = technical.data;

	for (var j = 0; j < technical.labels.length; j++) {
		var p = new Array();

		for (var i = start; i < end; i++) {
			var v = data[technical.labels[j].data][i];

			if (v == null)
				continue;

			var x = mapping.transformIndex(i) + 0.5;
			var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;

			p.push({x : x, y : y});
		}

		if (p.length > 1) {
			var x0 = p[0].x;
			var y0 = p[0].y;
			var x1 = p[p.length - 1].x;
			var y1 = p[p.length - 1].y;
			SvgChartDrawUtil.line(context, x0, y0, x1, y1, technical.dataColor[j]);
		}
	}
};

SvgChartProjector.prototype.projectVolume = function(context, technical, mapping) {
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var data = technical.view.chart.mainTechnical.data;

	// var candleWidth = Math.max(0.5, Math.round((mapping.getUnitWidth() - 2) / 2) - 0.5);
	var candleWidth = Math.max(0.5, Math.floor(mapping.getUnitWidth() * 0.35) - 0.5);

	var y0 = mapping.drawAreaHeight - mapping.paddingBottom - 0.5;
	// console.debug(mapping.drawAreaHeight + "," + mapping.paddingBottom);
	var fillStyle;

	elements = [];
	for (var i = start; i < end; i++) {
		if (data[i] == null || data[i] == undefined)
			continue;
		if (data[i].volume == null || data[i].volume == undefined)
			continue;

		var v = Number(data[i].volume);
		if (v <= 0)
			continue;

		// if (data[i].close > data[i].open) {
			fillStyle = technical.dataColor[0];
		// } else {
			// fillStyle = technical.dataColor[1];
		// }

		var x = mapping.transformIndex(i);
		var y = mapping.transformValue(v) - 0.5;

		var rect = SvgChartDrawUtil.fillRect(context, x - candleWidth, y, candleWidth * 2, y0 - y, fillStyle, fillStyle);
		elements[i] = rect;
	}
	technical.elements = elements;

	var x0, x1;

	x0 = mapping.paddingLeft - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
	x1 = mapping.drawAreaWidth - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;

	SvgChartDrawUtil.line(context, x0, y0, x1, y0, SvgChartGlobal.COLOR_PANDEL_GRID);
};

// draw candles
SvgChartProjector.prototype.projectCandle = function(context, technical, mapping)
{
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	// var candleWidth = Math.max(0, Math.round((mapping.getUnitWidth() - 2) / 2));
	var candleWidth = Math.floor(mapping.getUnitWidth() * 0.45);

	context.lineWidth = 1;
//	var dataColor = ["#000000", "#ffffff", "#000000", "#000000"];
	dataColor = ["#FF2B11", "#ffffff", "#1379F9", "#1379F9"];
	if (technical.view.chart.theme == "black") {
		dataColor = ["#FF2B11", "#1c1c1c", "#1379F9", "#1379F9"];
	}

	for(var i = start; i < end; i++) {
		if (technical.data[i].close == null) continue;

		var vo = Number(technical.data[i].open);
		var vh = Number(technical.data[i].high);
		var vl = Number(technical.data[i].low);
		var vc = Number(technical.data[i].close);

		var x = mapping.transformIndex(i) + 0.5;
		var yo = mapping.transformValue(vo) + 0.5;
		var yh = mapping.transformValue(vh) + 0.5;
		var yl = mapping.transformValue(vl) + 0.5;
		var yc = mapping.transformValue(vc) + 0.5;

		if(vc > vo) {
			// SvgChartDrawUtil.line(context, x, yh, x, yc, technical.dataColor[0]);
			// SvgChartDrawUtil.line(context, x, yo, x, yl, technical.dataColor[0]);
			SvgChartDrawUtil.line(context, x, yh, x, yl, dataColor[0]);

			if (candleWidth > 1) {
				SvgChartDrawUtil.fillRect(context, x - candleWidth, yc, candleWidth * 2, yo - yc, dataColor[0], dataColor[1]);
			}
		}
		else {
			// SvgChartDrawUtil.line(context, x, yh, x, yo, technical.dataColor[2]);
			// SvgChartDrawUtil.line(context, x, yc, x, yl, technical.dataColor[2]);
			SvgChartDrawUtil.line(context, x, yh, x, yl, dataColor[2]);

			if (candleWidth > 1) {
				SvgChartDrawUtil.fillRect(context, x - candleWidth, yo, candleWidth * 2, yc - yo, dataColor[2], dataColor[3]);
			}
		}
	}
};

SvgChartProjector.prototype.projectLines = function(context, technical, mapping)
{
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var data = technical.data;

	for (var j = 0; j < technical.labels.length; j++) {
		var pointCount = 0;
		var p = new Array();

		for (var i = start; i < end; i++) {
			var v = data[technical.labels[j].data][i];

			if (v == null)
				continue;

			pointCount++;

			var x = mapping.transformIndex(i) + 0.5;
			var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;

			p.push({x : x, y : y});
		}

		if (pointCount > 1) {
			SvgChartDrawUtil.path(context, p, technical.lineWidth[j], technical.dataColor[j]);
		}
	}
};

// draw line chart
SvgChartProjector.prototype.projectLine = function(context, technical, mapping)
{
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var data, name;

	if(technical.dataLoadType == MST.SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN) {
		data = technical.view.chart.mainTechnical.data;
		name = technical.dataLoadName;
	}
	else {
		data = technical.data;
		name = technical.dataLoadName;
	}

	var pointCount = 0;
	var p = new Array();
	var cloud = new Array();

	// process tow benchmar data has different data length
	for(var j = start; j < end; j++){
		if(data[j] && data[j][name]){
//			baseValue = data[j][name];
			break;
		}
	}

	for(var i = start; i < end; i++) {
		if (data[i] == null) continue;
		var v = data[i][name];

		if (v == null || v == undefined) continue;

		if (mapping.benchmark == true) {
			v = (v / data[j][name] - 1) * 100;
			if (technical == technical.view.chart.mainTechnical) {
				mapping.startValue = data[j][name];
			}
		}

		pointCount++;

		var x = mapping.transformIndex(i) + 0.5;
		var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;

        if (p.length > 0 && p[p.length - 1].y != y) {
            // p.push({x : x, y : p[p.length - 1].y});
        }

		p.push({x : x, y : y});
		cloud.push({x : x, y : y});
	}

	if(pointCount > 1) {
		if ((technical.name == "mountain" || technical.name == "portfolioMountain" || technical.name == "twoDays") && mapping.benchmark == false) {
			v = mapping.vAxisValues[0].value;
			y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;

			// x = mapping.transformIndex(end - 1) + 0.5;
			x = cloud[cloud.length - 1].x;
			cloud.push({x : x, y : y});

//			x = mapping.transformIndex(start) + 0.5;
			cloud.push({x : cloud[0].x, y : y});
			// console.log(cloud);

			var fillColor;
			if(technical.dataFillcolor){
				fillColor = "0-#FFFFFF-" +  technical.dataFillcolor[0];
			}
			else{
				fillColor = "90-#FFFFFF-" + technical.dataColor[0];
			}
			
			SvgChartDrawUtil.pathFill(context, cloud, 0, null, fillColor, 1);
		}

//		var lineWidth = 1;
//		if (technical.view.chart.mainTechnical == technical) {
//			lineWidth = 2;
//		}
		var lineWidth = technical.lineWidth[0];

		var dataColor = technical.dataColor[0];
//		if (technical.name == "candle" && mapping.benchmark == true) {
//			dataColor = SvgChartTechnical.technicalMaster["line"].dataColor[0];
//		}
		SvgChartDrawUtil.path(context, p, lineWidth, dataColor);
	}
};

SvgChartProjector.prototype.projectParabolic = function(context, technical, mapping)
{
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength + mapping.dataBlank;

	var data = technical.data;

	var w = Math.min(4, Math.floor(mapping.getUnitWidth() * 0.45));

	for(var i = start; i < end; i++) {
		var v = data["result"][i];
		
		if (v == null) continue;

		var x = mapping.transformIndex(i) + 0.5;
		var y = mapping.transformValue(v.sar) + 0.5;
		
		if (v.type == "UP") {
			var s = "M" + (x - w) + ", " + (y + w * 1.7);
			s = s + "L" + x + ", " + y;
			s = s + "L" + (x + w) + ", " + (y + w * 1.7);
			s = s + "Z";
			SvgChartDrawUtil.pathFill(context, s, 1, technical.dataColor[0], technical.dataColor[0]);

//			SvgChartDrawUtil.line(context, x - w, y, x + w, y, technical.dataColor[0]);
//			SvgChartDrawUtil.line(context, x, y - w, x, y + w, technical.dataColor[0]);
		} else {
			var s = "M" + (x - w) + ", " + (y - w * 1.7);
			s = s + "L" + x + ", " + y;
			s = s + "L" + (x + w) + ", " + (y - w * 1.7);
			s = s + "Z";
			SvgChartDrawUtil.pathFill(context, s, 1, technical.dataColor[1], technical.dataColor[1]);
			
//			SvgChartDrawUtil.line(context, x - w, y, x + w, y, technical.dataColor[1]);
//			SvgChartDrawUtil.line(context, x, y - w, x, y + w, technical.dataColor[1]);
		}
			

	}

};

// draw intraday chart
SvgChartProjector.prototype.projectIntraday = function(context, technical, mapping)
{
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var data, name;

	data = technical.data;
	name = "close";

	if (data.length == 0) {
		return;
	}

	var pointCount = 0;
	var fu = new Array();
	var fd = new Array();
	var pu = new Array();
	var pd = new Array();
	var pus = new Array();
	var pds = new Array();

	// var v0 = technical.view.chart.lastCloseTechnical.data[0][name];
	// v0 = data[start][name] - data[start]["net_change"];
	v0 = technical.lastCloseValue;
	// v0 = 87.55;
	// v0 = (data[start][name] + data[data.length - 1][name]) / 2;

	// var v0 = data[start][name];
	// for (var i = technical.view.chart.lastCloseTechnical.data.length - 1; i >= 0; i--) {
		// if (technical.view.chart.lastCloseTechnical.data[i].date.substring(0, 10) < data[0].date.substring(0, 10)) {
			// v0 = technical.view.chart.lastCloseTechnical.data[i][name];
			// break;
		// }
	// }

	y0 = mapping.transformValue(v0, technical.vAxis.vAxis) + 0.5;
	x0 = mapping.transformIndex(start) + 0.5;
	x1 = mapping.transformIndex(end - 1) + 0.5;

	for(var i = start; i < end; i++) {

		if (data[i] == null) {
			continue;
		}

		var v = data[i][name];

		if (mapping.benchmark == true) {
			v = (v / data[start][name] - 1) * 100;
		}

		if(v == null) continue;

		pointCount++;

		var x = mapping.transformIndex(i) + 0.5;
		var y = mapping.transformValue(v, technical.vAxis.vAxis) + 0.5;

		var color0 = "#0f9d49";
		var color1 = "#e44326";
		// var fillColor0 = "90-#FFFFFF-#a5d7ba";
		// var fillColor1 = "90-#f4d1c8-#FFFFFF";
		var fillColor0 = "90-#d8fdeb-#279f65";
		var fillColor1 = "90-#ee7d62-#fae5e1";

		var fillOpacity = 0.3;


		// if (y > y0) {
			// if (pd.length > 0) {
				// pd.push({x : x, y : y});
				// // SvgChartDrawUtil.path(context, pd, 2, color0);
				// pds.push(pd);
				// pd = new Array();
			// }
			// fu.push({x : x, y : y});
			// pu.push({x : x, y : y});
//
			// fd.push({x : x, y : y0});
		// } else if (y <= y0) {
			// if (pu.length > 0) {
				// pu.push({x : x, y : y});
				// // SvgChartDrawUtil.path(context, pu, 2, color1);
				// pus.push(pu);
				// pu = new Array();
			// }
			// fd.push({x : x, y : y});
			// pd.push({x : x, y : y});
//
			// fu.push({x : x, y : y0});
		// }

		if (y > y0) {
			if (pd.length > 0) {
				var x_last = pd[pd.length - 1].x;
				var y_last = pd[pd.length - 1].y;
				var xx = (x_last * (y - y0) + x * (y0 - y_last)) / (y - y_last);
				pd.push({x : xx, y : y0});
				pu.push({x : xx, y : y0});
				pds.push(pd);
				pd = new Array();
			}
			fu.push({x : x, y : y});
			pu.push({x : x, y : y});

			fd.push({x : x, y : y0});
		} else if (y < y0) {
			if (pu.length > 0) {
				var x_last = pu[pu.length - 1].x;
				var y_last = pu[pu.length - 1].y;
				var xx = (x_last * (y - y0) + x * (y0 - y_last)) / (y - y_last);
				pd.push({x : xx, y : y0});
				pu.push({x : xx, y : y0});
				pus.push(pu);
				pu = new Array();
			}
			fd.push({x : x, y : y});
			pd.push({x : x, y : y});

			fu.push({x : x, y : y0});
		} else {
			if (pd.length > 0) {
				pd.push({x : x, y : y});
				pds.push(pd);
				pd = new Array();
			}
			if (pu.length > 0) {
				pu.push({x : x, y : y});
				pus.push(pu);
				pu = new Array();
			}

			pu.push({x : x, y : y});
			pd.push({x : x, y : y});

			fu.push({x : x, y : y0});
			fd.push({x : x, y : y0});
		}

	}

	if (fd.length > 0) {
		fd.push({x : fd[fd.length - 1].x, y : y0});
		fd.push({x : fd[0].x, y : y0});
		SvgChartDrawUtil.pathFill(context, fd, 0, null, fillColor0, fillOpacity);
	}
	if (fu.length > 0) {
		fu.push({x : fu[fu.length - 1].x, y : y0});
		fu.push({x : fu[0].x, y : y0});
		SvgChartDrawUtil.pathFill(context, fu, 0, null, fillColor1, fillOpacity);
	}

	for (var i = 0; i < pds.length; i++) {
		SvgChartDrawUtil.path(context, pds[i], 2, color0);
	};
	for (var i = 0; i < pus.length; i++) {
		SvgChartDrawUtil.path(context, pus[i], 2, color1);
	};

	if (pd.length > 1) {
		SvgChartDrawUtil.path(context, pd, 2, color0);
	} else if (pd.length == 1) {
		SvgChartDrawUtil.drawPoint(context, pd[0].x, pd[0].y, color0, 3);
	}
	if (pu.length > 1) {
		SvgChartDrawUtil.path(context, pu, 2, color1);
	} else if (pu.length == 1) {
		SvgChartDrawUtil.drawPoint(context, pu[0].x, pu[0].y, color1, 3);
	}
	// SvgChartDrawUtil.line(context, x0, y0, x1, y0, "#808080");
};

// SvgChartProjector.prototype.projectAxis = function(context, mapping, index)
// {
//
	// var x0, x1, y0, y1;
//
	// if (index < 0) {
		// x0 = mapping.paddingLeft - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
		// x1 = mapping.drawAreaWidth - mapping.paddingRight + SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
		// y0 = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
		// y1 = mapping.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
	// } else if (index == 0) {
		// x0 = mapping.paddingLeft - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
		// x1 = mapping.drawAreaWidth - mapping.paddingRight + SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
		// y0 = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
		// y1 = mapping.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING - SvgChartGlobal.LAYOUT_PANEL_HAXIS_HEIGHT + 0.5;
	// } else {
		// // x0 = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
		// // x1 = mapping.drawAreaWidth - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING - SvgChartGlobal.LAYOUT_PANEL_VAXIS_WIDTH + 0.5;
		// x0 = mapping.paddingLeft - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
		// x1 = mapping.drawAreaWidth - mapping.paddingRight + SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
		// y0 = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
		// y1 = mapping.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
	// }
//
	// var x2 = x1 + 4.5;
	// var y2 = mapping.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
//
	// if (index >= 0) {
		// SvgChartDrawUtil.rect(context, x0, y0, x1 - x0, y1 - y0, SvgChartGlobal.COLOR_PANDEL_FRAME);
	// } else {
		// SvgChartDrawUtil.line(context, x0, y1, x1, y1, SvgChartGlobal.COLOR_PANDEL_FRAME);
	// }
//
	// for(var i = 0; i < mapping.hAxisValues.length; i++) {
		// var x = mapping.transformIndex(mapping.hAxisValues[i].value) + 0.5;
		// var y;
//
		// SvgChartDrawUtil.line(context, x, y0, x, y1, SvgChartGlobal.COLOR_PANDEL_GRID);
//
		// if (index == 0) {
			// y = y2 - 5;
			// SvgChartDrawUtil.fillText(context, x, y, mapping.hAxisValues[i].label, SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT, "center");
		// } else if (index < 0) {
			// y = y2 - 10;
			// SvgChartDrawUtil.fillText(context, x, y, mapping.hAxisValues[i].label, SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT, "center");
		// }
	// }
//
	// var xt = -1;
	// var xt1 = -1;
	// if (mapping.twoAxes) {
		// xt = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
		// xt1 = x1 + 4.5;
	// } else if (mapping.vAxisType == 0) {
		// xt = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
	// } else {
		// xt = x1 + 4.5;
	// }
//
	// if (mapping.vAxisValues != null) {
		// for (var i = 0; i < mapping.vAxisValues.length; i++) {
			// var y = mapping.transformValue(mapping.vAxisValues[i].value) + 0.5;
			// SvgChartDrawUtil.line(context, x0, y, x1, y, SvgChartGlobal.COLOR_PANDEL_GRID);
			// SvgChartDrawUtil.fillText(context, xt, y - 2, mapping.vAxisValues[i].label, SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT, "left");
			// if (xt1 > 0) {
				// SvgChartDrawUtil.fillText(context, xt1, y - 2, mapping.vAxisValues1[i].label, SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT, "left");
			// }
		// }
	// }
//
	// // Draw title
	// if (mapping.chartTitle != null) {
		// SvgChartDrawUtil.fillText(context, x2 - 10, y - 8, mapping.chartTitle, "#5C5C5C", "right");
	// }
// };

// draw axis
SvgChartProjector.prototype.projectAxis = function(chart, context, mapping, index)
{
	var textColor = SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT;
	if (chart.theme == "black") {
		textColor = "#ffffff";
	}

	var x0 = SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
	var x1 = mapping.drawAreaWidth - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
	var y0 = mapping.paddingTop - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;
	var y1 = mapping.drawAreaHeight - mapping.paddingBottom + 0.5;

	var x2 = x1 + 4.5;
	var y2 = mapping.drawAreaHeight - SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;

//	if (index >= 0) {
//		// SvgChartDrawUtil.rect(context, x0, y0, x1 - x0, y1 - y0, SvgChartGlobal.COLOR_PANDEL_FRAME);
//	} else {
//		// SvgChartDrawUtil.line(context, x0, y1, x1, y1, SvgChartGlobal.COLOR_PANDEL_FRAME);
//	}

	for(var i = 0; i < mapping.hAxisValues.length; i++) {
		var x = mapping.transformIndex(mapping.hAxisValues[i].value) + 0.5;
		var y;

		// SvgChartDrawUtil.line(context, x, y0, x, y1, SvgChartGlobal.COLOR_PANDEL_GRID);

		var text;
		if (index == 0) {
			y = y2 - 5;
//			text = SvgChartDrawUtil.fillText(context, x, y, mapping.hAxisValues[i].label, SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT, (mapping.hAxisValues[i].value > mapping.dataStartIndex ? "center" : "left"));
			text = SvgChartDrawUtil.fillText(context, x, y, mapping.hAxisValues[i].label, textColor, "center");
//			text.setAttributeNS(null, "class", mapping.hAxis.textClassName);
			SvgChartDrawUtil.line(context, x, y1, x, y1 + 5, mapping.panel.gridColor);
		} else if (index < 0) {
			y = y1 - 4;
			SvgChartDrawUtil.line(context, x, mapping.paddingTop + 0.5, x, y1, mapping.panel.gridColor);
			text = SvgChartDrawUtil.fillText(context, x, y, mapping.hAxisValues[i].label, textColor, "center");
//			text.setAttributeNS(null, "class", mapping.hAxis.textClassName);
		}
	}

	var xt = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;

	if (mapping.vAxisValues != null) {
		for (var i = 0; i < mapping.vAxisValues.length; i++) {
			var y = mapping.transformValue(mapping.vAxisValues[i].value) + 0.5;
			if (index > 0 && i == mapping.vAxisValues.length - 1) {
				continue;
			}
			SvgChartDrawUtil.line(context, x0, y, x1, y, mapping.panel.gridColor);
			// SvgChartDrawUtil.fillText(context, xt, y - 10, mapping.vAxisValues[i].label, SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT, "left");
		}
	}

	// // Draw title
	// if (mapping.chartTitle != null) {
		// SvgChartDrawUtil.fillText(context, x2 - 10, y - 8, mapping.chartTitle, "#5C5C5C", "right");
	// }
};

// draw axis text
SvgChartProjector.prototype.projectAxisText = function(chart, context, mapping, index)
{	
	var textColor = SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT;
	if (chart.theme == "black") {
		textColor = "#ffffff";
	}
	
	var xl = SvgChartGlobal.LAYOUT_PANEL_FRAME_PADDING + 0.5;
	var xr = mapping.drawAreaWidth - SvgChartGlobal.LAYOUT_PANEL_CONTENT_PADDING + 0.5;

	if (mapping.vAxisValues != null) {
		for (var i = 0; i < mapping.vAxisValues.length; i++) {
			var y = mapping.transformValue(mapping.vAxisValues[i].value, 0) + 0.5;
			// if (index == 0) {
				if (i < mapping.vAxisValues.length - 1) {
					var text = SvgChartDrawUtil.fillText(context, xr, y - 4, mapping.vAxisValues[i].label, textColor, "right");
//					text.setAttributeNS(null, "class", mapping.vAxis.textClassName);
				}
			// } else {
				// SvgChartDrawUtil.fillText(context, xl, y - 10, mapping.vAxisValues[i].label, SvgChartGlobal.COLOR_PANDEL_AXIS_TEXT, "left");
			// }
		}
	}
	// for (var i = 0; i < mapping.vAxisValues1.length; i++) {
	// 	var y = mapping.transformValue(mapping.vAxisValues[i].value) + 0.5;
	// 		if (i < mapping.vAxisValues.length - 1) {
	// 			SvgChartDrawUtil.fillText(context, xl, y - 4, mapping.vAxisValues1[i].label, textColor, "left");
	// 		}
	// }
};

// draw scroll bar
SvgChartProjector.prototype.projectScroll = function(context, technicalList, mapping)
{
	for(var t = 0; t < technicalList.length; t++) {
		var technical = technicalList[t];

		this.projectScrollMountain(context, technical, mapping);
	}
};

// draw scroll area
SvgChartProjector.prototype.projectAreaScroll = function(context, technicalList, mapping, mainMapping)
{
	for(var t = 0; t < technicalList.length; t++) {
		var technical = technicalList[t];

		this.projectScrollMountainArea(context, technical, mapping, mainMapping);
	}
};

// SvgChartProjector.prototype.projectScrollRange = function(context, technicalList, scrollMapping, mapping)
// {
	// for(var t = 0; t < technicalList.length; t++) {
		// var technical = technicalList[t];
//
		// this.projectScrollMountainRange(context, technical, scrollMapping, mapping);
	// }
// };

SvgChartProjector.prototype.projectScrollMountain = function(context, technical, mapping)
{
	// SvgChartDrawUtil.clear(context);

	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var p = new Array();
	var cloud = new Array();

	for(var i = start; i < end; i++) {
		var v = technical.data[i].close;
		if (v == null) continue;

		var x = mapping.transformIndex(i) + 0.5;
		var y = mapping.transformValue(v) + 0.5;

		p.push({x : x, y : y});
		cloud.push({x : x, y : y});
	}

	if(p.length > 1) {
		SvgChartDrawUtil.path(context, p, 1, SvgChartGlobal.COLOR_SCROLL_LINE);

		y = mapping.drawAreaHeight - mapping.paddingBottom + 0.5;

		// x = mapping.transformIndex(end - 1) + 0.5;
		x = p[p.length - 1].x + 0.5;
		cloud.push({x : x, y : y});

		x = mapping.transformIndex(start) + 0.5;
		cloud.push({x : x, y : y});
		SvgChartDrawUtil.pathFill(context, cloud, 0, null, SvgChartGlobal.COLOR_SCROLL_FILL);

		// var x0 = mapping.transformIndex(start) + 0.5;
		// var x1 = mapping.transformIndex(end - 1) + 0.5;
		var x0 = 0.5;
		var x1 = mapping.drawAreaWidth - 0.5;
		var y1 = mapping.drawAreaHeight - mapping.paddingBottom + 0.5;
		var h = mapping.drawAreaHeight - 0.5 - y1;
		// SvgChartDrawUtil.fillRect(context, x0, y1, x1 - x0, h, null, SvgChartGlobal.COLOR_SCROLL_BKGND);
		SvgChartDrawUtil.line(context, x0, y1, x1, y1, SvgChartGlobal.COLOR_PANDEL_FRAME);
	}
};

SvgChartProjector.prototype.projectScrollMountainArea = function(context, technical, mapping, mainMapping)
{
	// SvgChartDrawUtil.clear(context);
	var start = Math.max(0, mapping.dataStartIndex);
	var end = mapping.dataStartIndex + mapping.dataLength;

	var areaStart = Math.max(0, mainMapping.dataStartIndex);
	var areaEnd = mainMapping.dataStartIndex + mainMapping.dataLength - 1;

	var p = new Array();
	var cloudArea = new Array();
	var cloudLeft = new Array();
	var cloudRight = new Array();

	for(var i = start; i < end; i++) {
		if (technical.data[i] == null) continue;
		var v = technical.data[i].close;
		if (v == null) continue;

		var x = mapping.transformIndex(i) + 0.5;
		var y = mapping.transformValue(v) + 0.5;

		if (i < areaStart){
			cloudLeft.push({x : x, y : y});
		}
		else if (i == areaStart){
			cloudLeft.push({x : x, y : y});
			p.push({x : x, y : y});
			cloudArea.push({x : x, y : y});
		}
		else if (i > areaStart && i < areaEnd){
			p.push({x : x, y : y});
			cloudArea.push({x : x, y : y});
		}
		else if (i == areaEnd){
			p.push({x : x, y : y});
			cloudArea.push({x : x, y : y});
			cloudRight.push({x : x, y : y});
		}
		else if (i > areaEnd){
			cloudRight.push({x : x, y : y});
		}
	
	}

	if(p.length > 1) {
		var fillColor = "0-#000000-#4572A7";
		var line = SvgChartDrawUtil.path(context, p, 1, SvgChartGlobal.COLOR_SCROLL_AREA_LINE);
		y = mapping.drawAreaHeight - mapping.paddingBottom + 0.5;
		x = p[p.length - 1].x;
		cloudArea.push({x : x, y : y});

		x = p[0].x;
		cloudArea.push({x : x, y : y});
		SvgChartDrawUtil.pathFill(context, cloudArea, 0, null, fillColor, 0.4);
	}
	if(cloudLeft.length > 0){
		y = mapping.drawAreaHeight - mapping.paddingBottom + 0.5;
		x = cloudLeft[cloudLeft.length - 1].x;
		cloudLeft.push({x : x, y : y});
		x = cloudLeft[0].x;
		cloudLeft.push({x : x, y : y});

		SvgChartDrawUtil.pathFill(context, cloudLeft, 0, null, SvgChartGlobal.COLOR_SCROLL_AREA_FILL);
	}
	if(cloudRight.length > 0){
		y = mapping.drawAreaHeight - mapping.paddingBottom + 0.5;
		x = cloudRight[cloudRight.length - 1].x;
		cloudRight.push({x : x, y : y});
		x = cloudRight[0].x;
		cloudRight.push({x : x, y : y});

		SvgChartDrawUtil.pathFill(context, cloudRight, 0, null, SvgChartGlobal.COLOR_SCROLL_AREA_FILL);
	}
};

MST.SvgChartProjector = SvgChartProjector;
}());