(function() {

var SvgChartGlobal = MST;
var SvgChartUtil = MST.SvgChartUtil;
var SvgChartView = MST.SvgChartView;
var SvgChartActionView = MST.SvgChartActionView;
var SvgChartControllerView = MST.SvgChartControllerView;
var SvgChartScrollView = MST.SvgChartScrollView;
var SvgChartTechnical = MST.SvgChartTechnical;

var SvgChart = function (chart) {
	this.chartDiv = chart;
	this.chartWidth = this.chartDiv.clientWidth;
	var c = this;
	window.addEventListener("resize", function() {c.resize();});
};

SvgChart.prototype.chartTitle = null;
SvgChart.prototype.chartDiv = null;
SvgChart.prototype.viewDiv = null;
SvgChart.prototype.viewList = null;
SvgChart.prototype.techGroupList = null;
SvgChart.prototype.mainMapping = null;
SvgChart.prototype.mainTechnical = null;
SvgChart.prototype.code = null;
SvgChart.prototype.name = null;
SvgChart.prototype.timeFrame = null;
SvgChart.prototype.period = null;
//SvgChart.prototype.defaultUnitCount = 600;
SvgChart.prototype.loadingDiv = null;
SvgChart.prototype.errorDiv = null;
SvgChart.prototype.statusDiv = null;
SvgChart.prototype.measureTextDiv = null;
SvgChart.prototype.actionView = null;
SvgChart.prototype.scrollView = null;
SvgChart.prototype.rangeView = null;
//SvgChart.prototype.indicatorView = null;
SvgChart.prototype.timeFormat = null;
SvgChart.prototype.detailTimeFormat = null;
//SvgChart.prototype.interval = null;
SvgChart.prototype.language = null;
//SvgChart.prototype.benchmarks = null;
SvgChart.prototype.colorIndex = 0;

SvgChart.prototype.indicatorLabels = [];
SvgChart.prototype.timeFrameUpdated = true;

SvgChart.prototype.dataBlank = 0;
SvgChart.prototype.drawTopCompare = false;

SvgChart.timeFormatFunc = null;
SvgChart.numberFormatFunc = null;
SvgChart.translateFunc = null;

SvgChart.prototype.noDataMessage = "noData_simple";
SvgChart.prototype.loadFailMessage = "loadFail_simple";

SvgChart.prototype.drawSimpleMountainChart = function(name, data, config) {
	this.chartConfig = MST.deepMerge(MST.MiniChartConfig(), config);
	this.showScroll = this.chartConfig.scroll.showScroll;
	this.minPageLength = 5;

	this.name = name;

	this.init();

	this.setPeriod("monthly");

	this.techGroupList = new Array();

	var technicalList = this.chartConfig.technicalList;
	for (var i = 0; i < technicalList.length; i++) {
		var techGroup = new Array();
		this.techGroupList.push(techGroup);
		for (var j = 0; j < technicalList[i].length; j++) {
			var name = technicalList[i][j];
			var tech = new SvgChartTechnical();
			tech.setName(this, name);
			tech.name = name;
			techGroup.push(tech);

			if (i == 0 && j == 0) {
				tech.data = data;
				this.mainTechnical = tech;
			}
		}
	}

	this.prepareView();
	this.prepareActionView();

	this.globalLoadingDiv.style.visibility = "hidden";

	this.mainTechnical.dataStatus = SvgChartTechnical.DATA_STATUS_OK;
	this.mainTechnical.view.onTechnicalDataStatusChange(this.mainTechnical);

	this.draw();
}

SvgChart.prototype.setParams = function(code, name, config)
{
	this.noDataMessage = "noData_simple";
	this.loadFailMessage = "loadFail_simple";
	
	this.chartConfig = MST.deepMerge(MST.SvgChartConfig(), config);
	// console.log(this.chartConfig);
	
	this.showScroll = this.chartConfig.scroll.showScroll;
	this.showController = this.chartConfig.controller.showController;
	
	this.setPeriod("monthly");
	this.setPeriod("monthly");

	this.code = code;
	this.name = name;

	if (this.loadingDiv == null) {
		this.init();
	}
	
	this.techGroupList = new Array();

	var technicalList = this.chartConfig.technicalList
	for (var i = 0; i < technicalList.length; i++) {
		var techGroup = new Array();
		this.techGroupList.push(techGroup);
		for (var j = 0; j < technicalList[i].length; j++) {
			var name = technicalList[i][j];
			var _name = name;
			if (name.indexOf("news") >= 0) _name = "news";
			var tech = new SvgChartTechnical();
			tech.setName(this, _name);
			tech.name = name;
			techGroup.push(tech);

			if (i == 0 && j == 0) {
                // var callback = MST.getThemeDataUrl(this.code, this.period, this.isIndex);
                // var dataURL = MST.getUrl("theme/" + this.period, callback);
                var dataURL = jsonp_server + theme_json;

				tech.code = this.code;
				// tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);
                // tech.callback = callback;
				this.mainTechnical = tech;
			}

			if (name == 'volume') {
				this.showVolume = true;
			}
		}
	}
	
	this.prepareView();
	this.prepareActionView();

	this.initIndicatorList(this.chartConfig.indicatorList);
	
	if (this.chartConfig.controller.showController) {
		this.controllerView.init();
		this.controllerView.rangeView.setChartType();
	}

    // this.loadData();
    this.globalLoadingDiv.style.visibility = "hidden";

    var t = this;
    this.ajaxAndDecode(dataURL, function (data) {
        SvgChartTechnical.loadSuccess(t.mainTechnical, data);
    });
};

SvgChart.prototype.ajaxAndDecode = function(dataURL, func) {
    $.ajax({
        url: dataURL,
        dataType: "text",
        success: function (data) {
            // console.log(data);
            if (data.length == 0) return;
            var c = data.substr(0, 1);
            if (c != '{' && c != '[') {
                data = decode(data);
            }
            // console.log(data);
            data = JSON.parse(data)
            // console.log(data);

            if (data.data == null) {
                data = {data: data};
            }
            func(data);
        },
        error: function () {}
    });
}

SvgChart.prototype.initIndicatorList = function(indicatorList) {
	if (indicatorList != null) {
		for (var name in indicatorList) {
			var technical = MST.technicalConfig[name];
			if (technical == null) continue;
			
			if (technical.plural) {
				for (var i = 0; i < indicatorList[name].length; i++) {
					var tech = this.initIndicator(name, indicatorList[name][i]);
					this.addIndicator(tech);
				}
			} else {
				var tech = this.initIndicator(name, indicatorList[name]);
				this.addIndicator(tech);
			}
		}
	}
}

SvgChart.prototype.initIndicator = function(name, params) {

	var tech = new SvgChartTechnical();
	tech.setName(this, name);
	if (params != null) {
		tech.params = params.slice(0);
	}
	
	return tech;
//	if (name == "Ichimoku") {
//		this.offsetIchimoku = tech.params[2];
//		var blankLength = 0;
//		if (this.mainTechnical.blankLength != null) {
//			blankLength = this.mainTechnical.blankLength;
//		}
//		this.dataBlank = Math.max(0, tech.params[2] - blankLength);
//	}

//	if (tech.type == "sub") {
//		var techList = new Array();
//		this.techGroupList.push(techList);
//
//		techList.push(tech);
//		return true;
//	} else {
//		this.techGroupList[0].push(tech);
//		return false;
//	}
}

SvgChart.prototype.setBenchmarks = function(obj)
{
	this.indicatorViewBenchmarks = obj;
	for (var i in this.indicatorViewBenchmarks) {
		SvgChartGlobal.lang["select"][i] = this.indicatorViewBenchmarks[i].name;
	}
};

SvgChart.prototype.setIndicators = function(obj)
{
	this.indicatorViewIndicators = obj;
};

SvgChart.prototype.setAverages = function(obj)
{
	this.indicatorViewAverages = obj;
};

SvgChart.setTimeFormatFunction = function(func)
{
	SvgChart.timeFormatFunc = func;
};

function toThousands(num, n, complete = false) {
	if(num == null || num == "num" || num == "-" || num == "－"){
		return "－";
	}
	//n = n > 0 && n <= 20 ? n : 0;
	var plus = num.toString().indexOf("+") != -1 ? "+" : "";
	num = num.toString().indexOf("+") != -1 ? num.toString().split("+")[1] : num;
	var leftNum = num.toString().indexOf(".") != -1 ? num.toString().split(".")[0] : num;
	var rightStr = "";
	var tmpNum = num.toString();
	if (n >= 0) {
		// tmpNum = parseFloat(num).toFixed(n); //toThousands(1.255,2) error
		tmpNum = parseFloat(num).myRound(n);
	}
	var rightNum = tmpNum.toString().split(".")[1];

	// 针对异常数据情况进行修正
	// if(tmpNum.toString().split(".")[0] > parseInt(leftNum)){
	//     leftNum = (parseInt(leftNum) + 1).toString();
	// }
	if(tmpNum > 0 && tmpNum.toString().split(".")[0] > parseInt(leftNum)){
		leftNum = (parseInt(leftNum) + 1).toString();
	}else if(tmpNum < 0 && tmpNum.toString().split(".")[0] < parseInt(leftNum)){
		leftNum = (parseInt(leftNum) - 1).toString();
	}

	var leftStr = leftNum.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');

	if(n > 0 && complete){
		rightNum = rightNum || 0;
		rightNum = rightNum.toString().padEnd(n, '0');
	}

	if (rightNum) {
		rightStr = "." + rightNum;
	}

	if(plus + leftStr + rightStr == "-0") return "0";

	return plus + leftStr + rightStr;
}

SvgChart.numberFormatFunc = toThousands;
// SvgChart.setNumberFormatFunction = function(func)
// {
// 	SvgChart.numberFormatFunc = func;
// };

SvgChart.prototype.init = function()
{
	// document.onselectstart = function () {
		// return false;
	// };
	if (this.language == null) {
		this.setLanguage(SvgChartGlobal.language);
	}

	// this.chartDiv.className = "SvgChart";
	this.chartDiv.style.position = "relative";
	
	this.viewDiv = document.createElement("div");
	this.viewDiv.style.position = "relative";
	this.loadingDiv = document.createElement("div");
	this.errorDiv = document.createElement("div");

	this.loadingDiv.className = "svgChartLoading";
	this.errorDiv.className = "svgChartError";

	// Loading div
	// this.loadingDiv.style.lineHeight = "200px";
	this.loadingDiv.style.position = "absolute";
	this.loadingDiv.style.width = "100%";
	this.loadingDiv.style.height = "100%";
	this.loadingDiv.style.zIndex = 9;
	this.loadingDiv.style.textAlign = "center";
	this.loadingDiv.style.visibility = "hidden";

//	var loadingImg = document.createElement("img");
//	loadingImg.src = SvgChartGlobal.path + SvgChartGlobal.RES_LODING_IMG_URL;
//	loadingImg.alt = 'Loading...';
//	this.loadingDiv.appendChild(loadingImg);

	// error div
	// this.errorDiv.style.lineHeight = "200px";
	this.errorDiv.style.position = "absolute";
	this.errorDiv.style.width = "100%";
	this.errorDiv.style.height = "100%";
	this.errorDiv.style.zIndex = 9;
	this.errorDiv.style.textAlign = "center";
	this.errorDiv.style.visibility = "hidden";
	// this.errorDiv.innerHTML = "Loading is failed.Please try again later.";
	this.errorDiv.innerHTML = "<div>" + this.getLabel(this.loadFailMessage) + "</div>";


	// measureText div
	this.measureTextDiv = document.createElement("div");
	this.measureTextDiv.className = "SvgText";
	this.measureTextDiv.style.position = "absolute";
	this.errorDiv.appendChild(this.measureTextDiv);

	// status div
	this.statusDiv = document.createElement("div");
	this.statusDiv.className = "SvgChartStatus";

	this.statusDiv.style.lineHeight = "200px";
	this.statusDiv.style.position = "absolute";
	this.statusDiv.style.width = "100%";
	this.statusDiv.style.height = "100%";
	this.statusDiv.style.zIndex = 8;
	this.statusDiv.style.textAlign = "center";
	this.statusDiv.style.visibility = "hidden";
	// this.statusDiv.innerHTML = this.getLabel("no_data");
//	this.statusDiv.innerHTML = "<div>" + this.getLabel(this.noDataMessage) + "</div>";
	var div = document.createElement("div");
	this.statusDiv.appendChild(div);
	div.appendChild(document.createTextNode(this.getLabel(this.noDataMessage)));

//	div.appendChild(document.createTextNode("  "));
//
//	var a = document.createElement("a");
//	a.setAttribute("href", "javascript:void(0)");
//	a.appendChild(document.createTextNode("リロード"));
//	div.appendChild(a);
//
//	(function(chart) {
//		a.onclick = function() {
//			chart.chartDiv.innerHTML = "";
//			chart.loadingDiv = null;
//			chart.viewList = null;
//			chart.timeFrameUpdated = true;
//			chart.benchmarks = [];
//			chart.setParams(chart.code, chart.name, chart.exchange, chart.chartConfig);
//		};
//	})(this);
//	div.appendChild(a);

	this.backgroundView = new SvgChartBackground(this);

	this.chartDiv.appendChild(this.loadingDiv);
//	this.chartDiv.appendChild(this.errorDiv);
//
//	this.chartDiv.appendChild(this.statusDiv);
	
	
	this.globalLoadingDiv = document.createElement("div");
	this.globalLoadingDiv.className = "svgChartGlobalLoading";
	this.globalLoadingDiv.style.position = "absolute";
	this.globalLoadingDiv.style.width = "100%";
	this.globalLoadingDiv.style.height = "100%";
	this.globalLoadingDiv.style.zIndex = 9;
	this.chartDiv.appendChild(this.globalLoadingDiv);
	

	if (this.chartConfig.controller.showController) {
		this.controllerView = new SvgChartControllerView(this);
	}

	this.chartDiv.appendChild(this.errorDiv);

	this.chartDiv.appendChild(this.statusDiv);

	this.chartDiv.appendChild(this.viewDiv);

//	if (this.chartConfig.actionView.active) {
		this.actionView = new SvgChartActionView(this, this.viewDiv);
//	}

	if (this.showScroll) {
		this.scrollView = new SvgChartScrollView(this);
	}
};

SvgChart.prototype.prepareView = function(setRange)
{
	if (setRange != null) {
		var dataStartIndex = this.viewList[0].mapping.dataStartIndex;
		var dataLength = this.viewList[0].mapping.dataLength;
	}

	if (this.viewList != null) {
		for (var i = this.viewList.length - 1; i >= 0; i--) {
			this.viewDiv.removeChild(this.viewList[i].frameDiv);
		}

		// clear settingPanel cache  of technical
		for(var k = 0; k < this.techGroupList.length; k++){
			for(var m = 0; m < this.techGroupList[k].length; m++){
				this.techGroupList[k][m].settingPanel = null;
			}
		}
	}
	this.viewList = new Array();
	for(var i = 0; i < this.techGroupList.length; i++) {
		var view = new SvgChartView(this, i);
		this.viewList.push(view);

//		if(view.index == 0) {
//			view.setTitle(this.chartTitle);
//		}
	}
	
	if (setRange != null) {
		for (var i = 0; i < this.viewList.length; i++) {
			this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
		}
	}
};

SvgChart.prototype.prepareActionView = function()
{
	if (this.chartConfig.resizeHeight) {
		this.resizeHeight();
	}
	
	this.actionView.drawAreaHeight = 0;
	this.actionView.dataList = new Array();

	this.backgroundView.drawAreaHeight = 0;

	for(var i = 0; i < this.viewList.length; i++) {
		this.actionView.drawAreaHeight += this.viewList[i].mapping.drawAreaHeight;
		this.backgroundView.drawAreaHeight += this.viewList[i].mapping.drawAreaHeight + 2;
		for(var j = 0; j < this.viewList[i].technicalList.length; j++) {
			this.actionView.dataList.push(this.viewList[i].technicalList[j]);
		}
	}
	this.actionView.actionCanvasDiv.childNodes[0].style.height = this.actionView.drawAreaHeight + "px";
//	MST.SvgChartDrawUtil.resizeCanvas(this.actionView.actionCanvas, this.chartWidth, this.actionView.drawAreaHeight);
//	MST.SvgChartDrawUtil.resizeCanvas(this.actionView.actionCanvas1, this.chartWidth, this.actionView.drawAreaHeight);
	// this.backgroundView.drawAreaHeight += 50;
	//MST.SvgChartDrawUtil.resizeCanvas(this.newsCanvas, this.chartWidth, this.actionView.drawAreaHeight);
	if(this.chartConfig.controller.showIndicator){
		this.controllerView.indicatorControllerView.viewDiv.style.maxHeight = this.actionView.drawAreaHeight + "px";
		this.controllerView.indicatorControllerView.viewDiv.style.overflowY = "auto";
	}
};

SvgChart.prototype.addCompany = function(stock_code, name, key, index) {
    var tech = new SvgChartTechnical();
    tech.setName(this, "sub_line");
    // tech.vAxis.vAxis = 1;
    tech.stock_code = stock_code;
    tech.stock_key = key;
    tech.stock_name = name;
    tech.dataColor = [SvgChartGlobal.COLOR_PANEL[index % SvgChartGlobal.COLOR_PANEL.length]];

    var dataURL = MST.getCompanyDataUrl(theme_id, stock_code, key);
    // var dataURL = MST.getUrl("theme_relation", callback);
    // tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);
    // tech.callback = callback;

    var data_json = MST.SvgChartCache.load(dataURL);
    var t = this;
    if (data_json == null) {
        this.ajaxAndDecode(dataURL, function (data) {
            MST.SvgChartCache.save(dataURL, 'monthly', data)
            t.addCompany1(tech, data);
        });
    } else {
        this.addCompany1(tech, data_json);
    }

    // var techList = new Array();
    // this.techGroupList.push(techList);
    // techList.push(tech);
    // this.addView(techList);
    //
    // this.loadData();
};

SvgChart.prototype.addCompany1 = function(tech, data_json) {
    var idx;
    for (idx = 0; idx < data_json.fields.length; idx++) {
        if (data_json.fields[idx] == tech.stock_key) {
            break;
        }
    }
    // console.log("idx:" + idx);

    var arr = []
    var list = data_json.data;
    for (var i = 0; i < list.length; i++) {
        arr.push({date: new Date(list[i][0]).toISOString(), close: Number(list[i][idx])});
    }
    // console.log("arr:" + arr);

    tech.data = arr;
    tech.data = tech.analysisBenchmark(tech.data, this.mainTechnical.data);
    tech.dataStatus = SvgChartTechnical.DATA_STATUS_OK;

    var techList = new Array();
    this.techGroupList.push(techList);
    techList.push(tech);
    this.addView(techList);
    //
    // this.loadData();
    tech.view.onTechnicalDataStatusChange(tech);
}

SvgChart.prototype.removeCompany = function(stock_code, key) {
    for(var i = this.techGroupList.length - 1; i >= 0; i--) {
        for(var j = this.techGroupList[i].length - 1; j >= 0; j--) {
            if (this.techGroupList[i][j].stock_code == stock_code &&
                this.techGroupList[i][j].stock_key == key) {

                this.techGroupList[i].splice(j, 1);
                this.removeTechnicalAfter(i);
                break;
            }
        }
    }
    // this.drawAll();
};

SvgChart.prototype.addStock = function(stock_code, name, index) {
    var tech = new SvgChartTechnical();
    tech.setName(this, "sub_line");
    // tech.vAxis.vAxis = 1;
    tech.stock_code = stock_code;
    tech.stock_name = name;
    tech.dataColor = [SvgChartGlobal.COLOR_PANEL[index % SvgChartGlobal.COLOR_PANEL.length]];

    var callback = MST.getStockDataUrl(stock_code, this.period);
    var dataURL = MST.getUrl("bar/" + this.period, callback);
    tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);
    tech.callback = callback;

    var techList = new Array();
    this.techGroupList.push(techList);
    techList.push(tech);
    this.addView(techList);

    this.loadData();
};

SvgChart.prototype.removeStock = function(stock_code) {
    for(var i = this.techGroupList.length - 1; i >= 0; i--) {
        for(var j = this.techGroupList[i].length - 1; j >= 0; j--) {
            if (this.techGroupList[i][j].stock_code == stock_code) {
                this.techGroupList[i].splice(j, 1);
                this.removeTechnicalAfter(i);
                break;
            }
        }
    }
    // this.drawAll();
};

SvgChart.prototype.removeTechnicalAfter = function(index)
{
    if (this.techGroupList[index].length == 0) {
        this.viewDiv.removeChild(this.viewList[index].frameDiv);
        this.techGroupList.splice(index, 1);
        this.removeView(index);
    } else {
        this.viewList[index].setTechnicalList(this.techGroupList[index]);
        for (var i = 0; i < this.techGroupList[index].length; i++) {
            if (this.viewList[index].mapping.benchmark) {
                this.techGroupList[index][i].vAxis.vAxis = 0;
            } else {
                this.techGroupList[index][i].vAxis.vAxis = i;
            }
        }
        this.viewList[index].mapping.updated = true;
    }
    this.drawAll();
};


SvgChart.prototype.mergeView = function(index1, index0) {
    var techList = this.techGroupList[index1];
    for (var i = 0; i < techList.length; i++) {
        this.techGroupList[index0].push(techList[i]);
    }

    for (var i = 0; i < this.techGroupList[index0].length; i++) {
        if (this.viewList[index0].mapping.benchmark) {
            this.techGroupList[index0][i].vAxis.vAxis = 0;
        } else {
            this.techGroupList[index0][i].vAxis.vAxis = i;
        }

    }
    this.viewList[index0].setTechnicalList(this.techGroupList[index0]);
    this.viewList[index0].mapping.updated = true;

    this.techGroupList.splice(index1, 1);
    this.viewDiv.removeChild(this.viewList[index1].frameDiv);
    this.removeView(index1);

    this.drawAll();
}

SvgChart.prototype.splitView = function(index) {
    for(var i = this.techGroupList.length - 1; i > index; i--) {
        this.viewDiv.removeChild(this.viewList[i].frameDiv);
        this.removeView(i);
    }

    var techList = this.techGroupList[index];
    for (var i = 1; i < techList.length; i++) {
        var tech = techList[i];
        tech.vAxis.vAxis = 0;
        var techList1 = new Array();
        techList1.push(tech);
        this.techGroupList.splice(index + i, 0, techList1);
        this.addView(techList1, index + i);
    }
    for(var i = techList.length - 1; i > 0; i--) {
        // var tech = techList[i];
        // tech.vAxis.vAxis = 0;
        techList.splice(i, 1);

        // var techList1 = new Array();
        // techList1.push(tech);
        // this.techGroupList.splice(index + 1, 0, techList1);
        // this.addView(techList1, index + 1);
    }
    this.viewList[index].setTechnicalList(techList);
    this.viewList[index].mapping.benchmark = false;

    for (var i = this.viewList.length; i < this.techGroupList.length; i++) {
        this.addView(i, i);
    }

    this.loadData();

    // this.drawAll();
    // console.log(this.techGroupList);
    // console.log(this.viewList);
}


SvgChart.prototype.addVolume = function() {
	this.addTechnical("volume");
	this.showVolume = true;
};

SvgChart.prototype.removeVolume = function() {
	this.removeTechnical("volume");
	this.showVolume = false;
};

SvgChart.prototype.addTechnical = function(name)
{
	for(var i = 1; i < this.techGroupList.length; i++) {
		// console.log(this.techGroupList[i][0].name);
		if (this.techGroupList[i][0].name == name) {
			return;
		}
	}
	for(var i = 1; i < this.techGroupList[0].length; i++) {
		// console.log(this.techGroupList[0][i].name);
		if (this.techGroupList[0][i].name == name) {
			return;
		}
	}

	var technical = SvgChartTechnical.technicalMaster[name];
	if (technical == null)
		return;

	var tech = new SvgChartTechnical();
	tech.setName(this, name);

	if (technical.type == "sub") {
		var techList = new Array();
		this.techGroupList.push(techList);

		techList.push(tech);

		this.addView(techList);
	} else {
		this.techGroupList[0].push(tech);
		this.viewList[0].setTechnicalList(this.techGroupList[0]);
		this.prepareActionView();
		this.analysis();
		this.viewList[0].mapping.updated = true;
		this.viewList[0].draw();
	}
	if (this.drawFinish != null) {
		this.drawFinish();
	}
};

SvgChart.prototype.removeTechnical = function(techId)
{
	for(var i = this.techGroupList.length - 1; i >= 0; i--) {
		// console.log(this.techGroupList[i][0].techId);
		// clear settingPanel cache  of technical
//		for(var m = 0; m < this.techGroupList[i].length; m++){
//			this.techGroupList[i][m].settingPanel = null;
//		}
		if(i > 0){
			if (this.techGroupList[i][0].techId == techId) {		
				this.viewDiv.removeChild(this.viewList[i].frameDiv);
				this.techGroupList.splice(i, 1);
				this.removeView(i);
			}
		}
		
	}
	for(var i = this.techGroupList[0].length - 1; i > 0; i--) {
		// console.log(this.techGroupList[0][i].techId);
		if (this.techGroupList[0][i].techId == techId) {
			
//			if (this.techGroupList[0][i].name == "Ichimoku") {
//				this.offsetIchimoku = 0;
//				this.dataBlank = 0;
//				
//				if (this.viewList[0].mapping.dataBlank > 0) {
//					for (var i = 0; i < this.viewList.length; i++) {
//						var dataStartIndex = this.viewList[0].mapping.dataStartIndex;
//						var dataLength = this.viewList[0].mapping.dataLength;
//						dataStartIndex = Math.max(0, Math.min(dataStartIndex, dataStartIndex + dataLength - this.minPageLength));
//						this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
//						this.viewList[i].mapping.dataBlank = 0;
//					}
//				}
//			}
			
			this.techGroupList[0].splice(i, 1);
			this.prepareActionView();
			this.viewList[0].setTechnicalList(this.techGroupList[0]);
//			this.viewList[0].mapping.updated = true;
//			this.viewList[0].draw();
		}
	}
	this.drawAll();
};

SvgChart.prototype.addView = function(techGroup, index)
{
	// console.log("========addView start===========");
    if (index != null) {
        view = new SvgChartView(this, index);
        this.viewList.splice(index, 0, view);
    } else {
        view = new SvgChartView(this, this.techGroupList.length - 1);
        this.viewList.push(view);
        index = this.techGroupList.length - 1;
    }

    for(var i = 0; i < this.viewList.length; i++) {
        this.viewList[i].index = i;
    }
	var dataStartIndex = this.viewList[0].mapping.dataStartIndex;
	var dataLength = this.viewList[0].mapping.dataLength;
	this.viewList[index].mapping.setDataRange(dataStartIndex, dataLength);
    this.prepareActionView();
	// view.loadData();
};

SvgChart.prototype.removeView = function(i)
{
	this.viewList.splice(i, 1);
    for(var i = 0; i < this.viewList.length; i++) {
        this.viewList[i].index = i;
    }
	this.prepareActionView();
};

SvgChart.prototype.changeMainTechnical = function(name) {
	var dataColor = this.mainTechnical.dataColor;
	var lineWidth = this.mainTechnical.lineWidth;
	var technicalName = this.mainTechnical.name;
	this.mainTechnical.setName(this, name);
	this.mainTechnical.dataColor = dataColor;
	this.mainTechnical.lineWidth = lineWidth;

	if (this.period == "1m" && technicalName != "candle" && name == "candle") {

		this.changeTimeFrame("3M");
		this.period = "daily";
	}

	this.viewList[0].setTechnicalList(this.viewList[0].technicalList);
	this.viewList[0].mapping.updated = true;

	if (this.chartConfig.controller.showChartRange) {
		this.controllerView.rangeView.setChartType(name, this.mainMapping.benchmark);
	}

	this.draw();
};

SvgChart.prototype.resizeHeight = function()
{
	this.chartHeight = this.chartDiv.clientHeight;
	var height = this.chartHeight;

	if (this.showScroll) {
		height -= this.scrollView.scrollMapping.drawAreaHeight;
	}
	
	height -= this.controllerView.rangeViewPositionDiv.clientHeight;
	
	var sub = 10;
	if (this.viewList.length == 4) {
		sub = 15;
	} else if (this.viewList.length == 3) {
		sub = 20;
	} else if (this.viewList.length == 2) {
		sub = 20;
	}
	var h0 = height;
	for(var i = 1; i < this.viewList.length; i++) {
		var h = Math.round(height * sub / 100);
		this.viewList[i].mapping.drawAreaHeight = h;
		this.viewList[i].mapping.updated = true;
		this.viewList[i].panelDiv.style.height = h + "px";		
		this.viewList[i].sketchDiv.style.height = h + "px";		
//		MST.SvgChartDrawUtil.resizeCanvas(this.viewList[i].panelDrawCanvas, this.chartWidth, h);

		h0 -= h;
	}
	this.viewList[0].mapping.drawAreaHeight = h0;
	this.viewList[0].panelDiv.style.height = h0 + "px";
	this.viewList[0].sketchDiv.style.height = h0 + "px";
//	MST.SvgChartDrawUtil.resizeCanvas(this.viewList[0].panelDrawCanvas, this.chartWidth, h0);
	this.viewList[0].mapping.updated = true;
	
};

SvgChart.prototype.redraw = function()
{
	this.resize();
};

SvgChart.prototype.resize = function()
{
	if (this.actionView != null && this.actionView.mapping.status == SvgChartTechnical.DATA_STATUS_OK) {
		this.prepareActionView();
		this.draw();
		if (this.showScroll) {
			this.scrollView.draw();
		}
	}
	// this.rangeView.rangeViewDiv.style.width = this.chartDiv.clientWidth - 16 + "px";
};

SvgChart.prototype.loadData = function()
{
	for (var i = 0; i < this.viewList.length; i++) {
		var view = this.viewList[i];
		view.mapping.status = SvgChartTechnical.DATA_STATUS_LOADING;
		view.loadData();
	}
};

SvgChart.prototype.analysis = function()
{
	// console.log("================analysis==================");

	for (var i = 0; i < this.techGroupList.length; i++) {
		for (var j = 0; j < this.techGroupList[i].length; j++) {
			var tech = this.techGroupList[i][j];
			if (tech.dataLoadType == SvgChartTechnical.DATA_ANALYSIS) {
				tech.analysis.analysis(tech, this.mainTechnical.data);
			}

			// if (tech.name == 'pick') {
				// tech.analysisPick(this.mainTechnical.data);
			// }

			if (i == 0 && j > 0 && tech.type != "dummy" && tech.dataLoadType == SvgChartTechnical.DATA_LOAD_TYPE_URL) {
				tech.data = tech.analysisBenchmark(tech.data, this.mainTechnical.data, 16);
			}

			if (i == 0 && tech.name == "lastClose") {
				tech.analysisLastClose(this.mainTechnical.data);
			}
			// if (i == 0 && (this.period == "1m") && tech.name != "lastClose") {
				// var exchangeCode = this.exchange;
				// var base_data = tech.analysisIntradayBase(tech.data, SvgChartGlobal.exchange[exchangeCode], this.period);
				// // console.warn(base_data);
				// tech.analysisBenchmark(base_data, 16);
				// // console.warn(tech.data);
				// tech.analysisIntraday(base_data);
				// // console.warn(tech.data);
			// }
		}
	}
};

SvgChart.prototype.onStatusChange = function() {
	var dataReady = true;
	var dataFailure = false;
	var noData = false;

	for (var i = 0; i < this.viewList.length; i++) {
		var view = this.viewList[i];
		// console.debug("view " + i + " status : " + view.mapping.status);
		if (view.mapping.status != SvgChartTechnical.DATA_STATUS_OK) {
			dataReady = false;

			if (view.mapping.status == SvgChartTechnical.DATA_STATUS_FAILURE) {
				dataFailure = true;
			}

			if (view.mapping.status == SvgChartTechnical.DATA_STATUS_NO_DATA) {
				noData = true;
			}
		}
	}

	// console.debug("SvgChart dataReady : " + dataReady);
	// console.debug("SvgChart dataFailure : " + dataFailure);
	// console.debug("SvgChart noData : " + noData);

	if (noData == true) {
		this.loadingDiv.style.visibility = "hidden";
		this.errorDiv.style.visibility = "hidden";
		this.statusDiv.style.visibility = "visible";
		//this.indicatorLabelDiv.style.visibility = "hidden";
		if(this.indicatorView){
			this.indicatorView.indicatorLabelDiv.style.visibility = "hidden";
		}

//		if (this.chartConfig.actionView.active) {
			this.actionView.mapping.setStatus(SvgChartTechnical.DATA_STATUS_NO_DATA);
//		}
		this.mainTechnical.data = [];
		this.draw();
	} else if (dataFailure == true) {
		this.loadingDiv.style.visibility = "hidden";
		this.errorDiv.style.visibility = "visible";
		this.statusDiv.style.visibility = "hidden";
		if(this.indicatorView){
			this.indicatorView.indicatorLabelDiv.style.visibility = "hidden";
		}

//		if (this.chartConfig.actionView.active) {
			this.actionView.mapping.setStatus(SvgChartTechnical.DATA_STATUS_FAILURE);
//		}
	} else if (dataReady == false) {
		this.loadingDiv.style.visibility = "visible";
		this.errorDiv.style.visibility = "hidden";
		//this.indicatorView.indicatorLabelDiv.style.visibility = "hidden";
		//this.statusDiv.style.visibility = "hidden";

//		if (this.chartConfig.actionView.active) {
			this.actionView.mapping.setStatus(SvgChartTechnical.DATA_STATUS_LOADING);
//		}
	} else {
		//while noData occurs,we should hide indicator div.it's just temp solution from GU 20141027
		if(this.indicatorView){
		   this.indicatorView.indicatorLabelDiv.style.visibility = "visible";
		}
        //End

		if(this.rangeView){
		   this.rangeView.rangeViewDiv.style.visibility = "visible";
		}
		this.draw();

		// if (this.showRange) {
			// if (!this.rangeView.finish) {
				// this.rangeView.onClickEvent(null, this.rangeView.param);
			// }
		// }

		if (this.showScroll) {
			this.scrollView.draw();
		}

//		if (this.chartConfig.actionView.active) {
			this.actionView.mapping.setStatus(SvgChartTechnical.DATA_STATUS_OK);
			this.actionView.drawPanelAction();
//		}

		this.loadingDiv.style.visibility = "hidden";
		this.errorDiv.style.visibility = "hidden";
		this.statusDiv.style.visibility = "hidden";
		if (this.showIndicator) {
			this.indicatorView.showMenu();
		}

		if (this.loadFinish != null) {
			this.loadFinish();
			this.loadFinish = null;
		}
	}
};

SvgChart.prototype.timeout = function()
{
	this.loadingDiv.style.visibility = "hidden";
	this.errorDiv.style.visibility = "visible";
	this.statusDiv.style.visibility = "hidden";

	if (this.active) {
		this.actionView.mapping.setStatus(SvgChartTechnical.DATA_STATUS_FAILURE);
	}
};

SvgChart.prototype.draw = function()
{
	this.offsetIchimoku();

	this.backgroundView.drawBackground();
	this.actionView.dataDiv.style.visibility = "hidden";
	this.actionView.xAxisDiv.style.visibility = "hidden";
	this.actionView.yAxisDiv.style.visibility = "hidden";
	for(var i = 0; i < this.viewList.length; i++) {
		var view = this.viewList[i];
		// if(i == 0){
			view.draw();
		// }
		// else{
		// 	if(view){
		// 		if(!this.mainMapping.benchmark){
		// 			view.draw();
		// 			view.show();
		// 		}else{
		// 			view.hidden();
		// 		}
		// 	}
		// }
	}
	if (this.drawFinish != null) {
		this.drawFinish();
	}
};

SvgChart.prototype.drawAll = function()
{
	this.analysis();
	
	this.offsetIchimoku();
	
	this.actionView.dataDiv.style.visibility = "hidden";
	this.actionView.xAxisDiv.style.visibility = "hidden";
	this.actionView.yAxisDiv.style.visibility = "hidden";
	for(var i = 0; i < this.viewList.length; i++) {
		var view = this.viewList[i];
		view.setTechnicalList(view.technicalList);
		view.mapping.updated = true;
		view.draw();
	}
	if (this.showScroll) {
		this.scrollView.draw();
	}
	if (this.showController) {
		this.controllerView.init();
	}
};

SvgChart.prototype.offsetIchimoku = function() {
	var offsetIchimoku = 0;
	if(!this.mainMapping.benchmark) {
		for (var i = 0; i < this.techGroupList.length; i++) {
			for (var j = 0; j < this.techGroupList[i].length; j++) {
				var tech = this.techGroupList[i][j];
				if (tech.name == 'Ichimoku') {
					offsetIchimoku = tech.params[2];
				}
			}
		}
	}
	if (offsetIchimoku == 0) {
		if (this.viewList[0].mapping.dataBlank > 0) {
			for (var i = 0; i < this.viewList.length; i++) {
				var dataStartIndex = this.viewList[0].mapping.dataStartIndex;
				var dataLength = this.viewList[0].mapping.dataLength;
				dataStartIndex = Math.max(0, Math.min(dataStartIndex, dataStartIndex + dataLength - this.minPageLength));
				this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
				this.viewList[i].mapping.dataBlank = 0;
			}
		}
		this.dataBlank = 0;

	} else {
		var blankLength = 0;
		if (this.mainTechnical.blankLength != null) {
			blankLength = this.mainTechnical.blankLength;
		}
		this.dataBlank = Math.max(0, offsetIchimoku - blankLength);

		if (this.viewList[0].mapping.dataBlank > this.dataBlank) {
			for (var i = 0; i < this.viewList.length; i++) {
				var dataStartIndex = this.viewList[0].mapping.dataStartIndex;
				var dataLength = this.viewList[0].mapping.dataLength;
				dataStartIndex = Math.max(0, Math.min(dataStartIndex, dataStartIndex + dataLength - this.minPageLength));
				this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
				this.viewList[i].mapping.dataBlank = 0;
			}
		}
	}
}

SvgChart.prototype.drawTimer = null;
SvgChart.prototype.setDataRange = function(dataStartIndex, dataLength, dataBlank) {
	// Update mapping of sub charts
	for (var i = 0; i < this.viewList.length; i++) {
		this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
		this.viewList[i].mapping.dataBlank = dataBlank || 0;
	}

	if(this.chartConfig.scroll.showScroll){
		this.scrollView.mapping.setDataRange(dataStartIndex, dataLength);
		this.scrollView.drawScrollAction();
	}
	// Redraw all chart
	(function (t) {
		if (t.drawTimer != null) {
			clearTimeout(t.drawTimer);
		}
       	t.drawTimer = setTimeout(function() {
           	t.draw();
       	}, 10);
	})(this);
	// this.draw();
};

SvgChart.prototype.setPeriod = function(period) {
	this.period = period;
	this.timeFormat = SvgChartUtil.getTimeFormat(period);
	this.detailTimeFormat = SvgChartUtil.getTimeFormat(period, "detail");
};

SvgChart.prototype.changePeriod = function(period) {
	// this.removeTempSketch();
    this.timeFrameUpdated = true;

	this.setPeriod(period);

    for (var i = 0; i < this.viewList.length; i++) {
        for (var j = 0; j < this.viewList[i].technicalList.length; j++) {
            var tech = this.viewList[i].technicalList[j];

            if (this.mainTechnical == tech) {
                var callback = MST.getThemeDataUrl(this.code, this.period, this.isIndex);
                var dataURL = MST.getUrl("theme/" + this.period, callback);
                tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);
                tech.callback = callback;
            }

            if (tech.stock_code != null) {
                var callback = MST.getStockDataUrl(tech.stock_code, this.period);
                var dataURL = MST.getUrl("bar/" + this.period, callback);
                tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);
                tech.callback = callback;
            }

        }
    }

	for (var i = 0; i < this.techGroupList.length; i++) {
		this.viewList[i].mapping.setDataRange(0, 0);
		this.viewList[i].mapping.dataBlank = 0;
		for (var j = 0; j < this.techGroupList[i].length; j++) {
			this.techGroupList[i][j].dataStatus = SvgChartTechnical.DATA_STATUS_INIT;
		}
	}

	this.loadData();
};

SvgChart.prototype.findBenchmark = function(code) {
	for (var i = 1; i < this.techGroupList[0].length; i++) {
		if (this.techGroupList[0][i].code == code) {
			return this.techGroupList[0][i];
		}
	}
	return null;
}

SvgChart.prototype.findIndicator = function(name) {
	for(var i = this.techGroupList.length - 1; i > 0; i--) {
		if (this.techGroupList[i][0].name == name) {
			return this.techGroupList[i][0];
		}
	}
	for(var i = this.techGroupList[0].length - 1; i > 0; i--) {
		if (this.techGroupList[0][i].name == name) {
			return this.techGroupList[0][i];
		}
	}
	return null;
}

SvgChart.prototype.checkExistBenchmarkIndex = function(code) {
	if(this.benchmarks == null || this.benchmarks.length == 0) return -1; 

	var index = -1;

	for(var i = 0; i < this.benchmarks.length; i++){
		if(code == this.benchmarks[i]){
			index = i;
			break;
		}
	}

	return index;
};

SvgChart.prototype.setBenchmarksIndicators = function() {
	
	if (this.color_panel_cache == null) {
		this.color_panel_cache = {};
	}
		
	for (var t = 1; t < this.techGroupList[0].length; t++) {
		if (this.techGroupList[0][t].code != null) {
			this.color_panel_cache[this.techGroupList[0][t].code] = this.techGroupList[0][t].dataColor;
		}
	}

	var benchmarks = [];
//	var indicators = [];
	
	if(this.benchmarks == null) this.benchmarks = [];

	if(this.benchmarks.length > 0){
		for(var i = 0; i < this.benchmarks.length; i++){
		    if(this.benchmarkObjs[this.benchmarks[i]] != null){
                benchmarks.push(this.benchmarks[i]);
            }
		}
	}

	for (var i in this.benchmarkObjs) {
		if (this.benchmarkObjs[i].checked) {
			if(this.checkExistBenchmarkIndex(i) == -1) {
				benchmarks.push(i);
			}
		}
		else{
			var index = this.checkExistBenchmarkIndex(i);

			if(index != -1){
				benchmarks.splice(index, 1);
			}
		}
	}

	this.benchmarks = benchmarks;
	if(benchmarks.length > 0){
		this.controllerView.typeControllerView.toggleStatus(false);
//		this.controllerView.indicatorControllerView.toggleStatus(false);
	}
	else{
		this.controllerView.typeControllerView.toggleStatus(true);
//			this.controllerView.indicatorControllerView.toggleStatus(true);
	}
//	for (i in this.indicatorObjs) {
//		if (this.indicatorObjs[i].checked) {
//			indicators.push(i);
//		}
//	}

//	var techGroupList = new Array();
	var techGroup = this.techGroupList[0];
//	techGroupList.push(techGroup);

//	this.mainTechnical = this.techGroupList[0][0];
//	techGroup.push(this.mainTechnical);

	var begin_at = this.mainTechnical.data[0].date;
	var end_at = this.mainTechnical.data[this.mainTechnical.data.length -1].date;
	var format = "YYYY/MM/DD";
	begin_at = new Date(begin_at).format(format);
	end_at = new Date(new Date(end_at) + 60 * 60 * 24).format(format);

	for (var i = techGroup.length - 1; i > 0; i--) {
		
		var tech = techGroup[i];
		if (tech.code == null)
			continue;
				
		var found = false;
		for (var b = 0; b < benchmarks.length; b++) {
			if (tech.code == benchmarks[b])
				found = true;
		}
		if (!found) {
			var tech = techGroup.splice(i, 1)[0];
			tech.checkbox.colorPanel.style.backgroundColor = "#eee";
			tech.checkbox.colorPanel.style.cursor = "default";
		}
		
	}
	for (var i = 0; i < benchmarks.length; i++) {
		
		var tech = this.findBenchmark(benchmarks[i]);
		
		if (tech == null) {
			tech = new SvgChartTechnical();
			tech.code = benchmarks[i];
			tech.setName(this, "line");
			tech.isBenchmarks = true;

			var dataURL = MST.getDataUrl(tech.code, this.period, {begin_at : begin_at});
			if(this.drawTopCompare) dataURL = MST.getTOPDataURL(tech.code, this.period);
			tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);

			techGroup.push(tech);
		}
		
		this.benchmarkObjs[benchmarks[i]].technical = tech;
		tech.checkbox = this.benchmarkObjs[benchmarks[i]];
	}

	this.viewList[0].setTechnicalList(techGroup);
//	if (this.showVolume) {
//		var techList2 = new Array();
//		techGroupList.push(techList2);
//		var techVolume = new SvgChartTechnical();
//		techVolume.setName("volume");
//		techList2.push(techVolume);
//	}
//
//	for (var i = 0; i < indicators.length; i++) {
//		var tech = this.findIndicator(indicators[i]);
//		
//		if (tech == null) {
//			var name = indicators[i];
//			var technical = SvgChartTechnical.technicalMaster[name];
//			if (technical == null)
//				continue;
//
//			tech = new SvgChartTechnical();
//			tech.setName(name);
//		}
//
//		if (tech.type == "sub") {
//			var techList = new Array();
//			techGroupList.push(techList);
//			techList.push(tech);
//		} else {
//			techGroup.push(tech);
//		}
//	}

//	this.techGroupList = techGroupList;
//
//	var dataStartIndex = this.viewList[0].mapping.dataStartIndex;
//	var dataLength = this.viewList[0].mapping.dataLength;

	if (benchmarks.length == 0) {
		this.viewList[0].mapping.benchmark = false;
	} else {
		this.viewList[0].mapping.benchmark = true;
		
		for (var i = 0; i < benchmarks.length; i++) {
			this.benchmarkObjs[benchmarks[i]].colorPanel.style.backgroundColor = this.benchmarkObjs[benchmarks[i]].technical.dataColor[0];
			this.benchmarkObjs[benchmarks[i]].colorPanel.style.cursor = "pointer";
		}
	}

//	while (this.viewList.length > techGroupList.length) {
//		this.viewDiv.removeChild(this.viewList[this.viewList.length - 1].frameDiv);
//		this.viewList.splice(this.viewList.length - 1, 1);
//	}
//	while (this.viewList.length < techGroupList.length) {
//		var view = new SvgChartView(this, this.viewList.length);
//		this.viewList.push(view);
//	}
//	for (var i = 0; i < this.viewList.length; i++) {
//		this.viewList[i].setTechnicalList(techGroupList[i]);
//	}

	// this.prepareView();
//	this.prepareActionView();

//	for (var i = 0; i < this.viewList.length; i++) {
//		this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
//	}

//	this.chartControlBenchmark(benchmarks);
	this.loadData();
};

SvgChart.prototype.setBenchmark = function(benchmarks) {

	// chartControlBenchmark(benchmarks);

	this.benchmarks = benchmarks;
	var techGroupList = new Array();
	var techGroup = new Array();
	techGroupList.push(techGroup);

	this.mainTechnical = this.techGroupList[0][0];
	techGroup.push(this.mainTechnical);

	if (benchmarks.length > 0) {
		this.viewList[0].mapping.benchmark = true;

		// SvgChartGlobal.colorIndex = 1;
		for (var i = 0; i < benchmarks.length; i++) {
			var tech = new SvgChartTechnical();
			tech.code = benchmarks[i];
			tech.setName(this, "line");

			var dataURL = MST.getDataUrl(tech.code, this.period);
			if(this.drawTopCompare) dataURL = MST.getTOPDataURL(tech.code, this.period);
			tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);

			techGroup.push(tech);
		}
	} else {
		this.viewList[0].mapping.benchmark = false;
	}
	
	if (this.showRange) {
		this.rangeView.setChartType(this.mainTechnical.name, this.viewList[0].mapping.benchmark);
	}

	for (var i = 1; i < this.techGroupList[0].length; i++) {
		var tech = this.techGroupList[0][i];
		if (tech.dataLoadType != SvgChartTechnical.DATA_LOAD_TYPE_URL) {
			techGroup.push(tech);
		}
	}

	for (var i = 1; i < this.techGroupList.length; i++) {
		techGroupList.push(this.techGroupList[i]);
	}

	this.techGroupList = techGroupList;

	var dataStartIndex = this.viewList[0].mapping.dataStartIndex;
	var dataLength = this.viewList[0].mapping.dataLength;

	this.viewList[0].setTechnicalList(techGroup);
	// this.prepareView();
	this.prepareActionView();

	if (benchmarks.length == 0) {

		this.viewList[0].mapping.benchmark = false;

		for (var i = 0; i < this.viewList.length; i++) {
			this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
			this.viewList[i].mapping.status = SvgChartTechnical.DATA_STATUS_OK;
			this.viewList[i].mapping.updated = true;
		}

		this.draw();
		this.setDataRange(dataStartIndex, dataLength);

	} else {

		this.viewList[0].mapping.benchmark = true;

		for (var i = 0; i < this.viewList.length; i++) {
			this.viewList[i].mapping.setDataRange(dataStartIndex, dataLength);
			this.viewList[i].mapping.status = SvgChartTechnical.DATA_STATUS_INIT;
			this.viewList[i].mapping.updated = true;
		}

		this.loadData();
	}
};

SvgChart.prototype.setScale = function(scale) {

	if (scale == "logarithmic") {
		this.viewList[0].mapping.logarithmic = true;
	} else {
		this.viewList[0].mapping.logarithmic = false;
	}

	// this.indicatorView.optionsControl();

	this.viewList[0].mapping.updated = true;

	this.draw();
};

SvgChart.prototype.setLanguage = function(lang)
{
	this.language = lang;
	// SvgChartGlobal.loadLanguage(lang);

	var timeformat = SvgChartGlobal.timeformat[this.language];
	if (timeformat == null) {
		timeformat = SvgChartGlobal.timeformat[SvgChartGlobal.language];
	}
	SvgChartGlobal.timeformat["select"] = timeformat;

	var lang = SvgChartGlobal.lang[this.language];
	if (lang == null) {
		lang = SvgChartGlobal.lang[SvgChartGlobal.language];
	}
	SvgChartGlobal.lang["select"] = lang;
};

SvgChart.prototype.getLabel = function(label)
{
	// // var lang = SvgChartGlobal.lang[this.language];
	// // if (lang == null) {
		// // lang = SvgChartGlobal.lang[SvgChartGlobal.language];
	// // }
// //
	var t = SvgChartGlobal.lang["select"][label];
	if (t == undefined) {
		return label;
	}
	return t;
//	var t = this.indicatorLabels[label];
//	if (t != undefined) {
//		return t;
//	}
	// return SvgChart.translateFunc(label);
};

SvgChart.prototype.changeTimeFrame = function(timeFrame) {
	this.timeFrame = timeFrame;
	this.timeFrameUpdated = true;
	// this.changePeriod(this.period);
	// return;
	
	if (this.chartConfig != null && this.chartConfig.actionView.active) {
		this.actionView.dataDiv.style.visibility = "hidden";
		this.actionView.xAxisDiv.style.visibility = "hidden";
		this.actionView.yAxisDiv.style.visibility = "hidden";
	}
	var ret = SvgChartUtil.getPeriod(timeFrame, this.mainTechnical.data);
	var period = ret["period"];
	
	// if (timeFrame == '1D' && !drawDayFlg) {
	// 	var technical = this.mainTechnical;
	// 	technical.data = [];
	// 	technical.dataStatus = SvgChartTechnical.DATA_STATUS_NO_DATA;
	// 	this.period = "";
	// 	technical.view.onTechnicalDataStatusChange(technical);
	// 	return;
	// }

	if (this.period != period) {
		this.changePeriod(period);
	} else {
		var startIndex = ret["startIndex"];
		var dataLength = ret["dataLength"];
		this.setDataRange(startIndex, dataLength);
	}

	if(this.chartConfig.showScroll){
		this.scrollView.scrollActionStatus = null;
	}
};

SvgChart.prototype.onclick = function() {
	var controllerView = this.controllerView;

	// controllerView.indicatorControllerView.viewDiv.style.visibility = "hidden";
	// controllerView.benchmarkControllerView.viewDiv.style.visibility = "hidden";
//	controllerView.calendarControllerView.viewDiv.style.visibility = "hidden";
	if(this.chartConfig.controller.showIndicator){
		controllerView.indicatorControllerView.hiddenItem();
	}
	if(this.chartConfig.controller.showBenchmark){
		controllerView.benchmarkControllerView.hiddenItem();
	}
}

var SvgChartBackground = function (chart) {
	this.chart = chart;
//	this.mapping = new SvgChartMapping();

	this.backgroundDiv = document.createElement("div");
	this.backgroundDiv.style.position = "absolute";
	this.backgroundDiv.style.width = "100%";
	this.backgroundDiv.style.height = "100%";
	this.backgroundDiv.style.zIndex = 0;

	chart.chartDiv.appendChild(this.backgroundDiv);
	this.panelDrawCanvas = MST.SvgChartDrawUtil.createCanvas(this.backgroundDiv, chart.chartWidth, 100);
};
SvgChartBackground.prototype.panelDiv = null;
SvgChartBackground.prototype.panelDrawCanvas = null;
SvgChartBackground.prototype.drawAreaHeight = null;

SvgChartBackground.prototype.drawBackground = function(context, mapping)
{
	var chartWidth = this.chart.chartDiv.clientWidth;
	MST.SvgChartDrawUtil.clear(this.panelDrawCanvas);
	MST.SvgChartDrawUtil.fillRect(this.panelDrawCanvas, 0, 0, chartWidth, this.drawAreaHeight, "#ffffff", "#ffffff");
};

SvgChart.prototype.drawLastClose = function()
{
	return false;
//	return (this.period == "5m" || this.period == "1m");
//	return this.period == "1m";
};

SvgChart.prototype.closeMenu = function(){
//	var indictorView = this.controllerView.hiddenOtherItems("all");
}

SvgChart.prototype.removeIndicator = function(code) {
	for(var i = this.techGroupList.length - 1; i >= 0; i--) {
		if(i > 0){
			if (this.techGroupList[i][0].code == code) {
				this.viewDiv.removeChild(this.viewList[i].frameDiv);
				this.techGroupList.splice(i, 1);
				this.removeView(i);
			}
		}
	}
	for(var i = this.techGroupList[0].length - 1; i > 0; i--) {
		// console.log(this.techGroupList[0][i].techId);
		if (this.techGroupList[0][i].code == code) {
			this.techGroupList[0].splice(i, 1);
			this.prepareActionView();
			this.viewList[0].setTechnicalList(this.techGroupList[0]);
			this.viewList[0].mapping.updated = true;
			this.viewList[0].draw();
		}
	}

};
SvgChart.prototype.addIndicator = function(tech) {

	if (tech.type == "sub") {
		var techList;
//		if (this.techGroupList.length < 3) {
			techList = new Array();
			var index = this.techGroupList.length - 1;
			//this.techGroupList.splice(index, 0, techList);
			this.techGroupList.push(techList);
			techList.push(tech);
			this.addView(techList, index);
//		} else {
//			techList = this.techGroupList[2];
//			techList.push(tech);
//			this.viewList[2].setTechnicalList(techList);
//		}

	} else {
		var index = 2;
		while (index < this.techGroupList[0].length
				&& this.techGroupList[0][index].dataLoadType == SvgChartTechnical.DATA_ANALYSIS
				&& (this.techGroupList[0][index].name != tech.name
				|| this.techGroupList[0][index].name == tech.name
				&& this.techGroupList[0][index].params[0] < tech.params[0])) {
			index++;
		}
		
		this.techGroupList[0].splice(index, 0, tech);
		this.viewList[0].setTechnicalList(this.techGroupList[0]);
		if (this.viewList[0].mapping.status == SvgChartTechnical.DATA_STATUS_OK) {
			this.analysis();
			this.viewList[0].mapping.updated = true;
			this.draw();
		}
	}
};

SvgChart.prototype.benchmarkIndex = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
SvgChart.prototype.removeBenchmark = function(code) {
	
	var techGroup = this.techGroupList[0];
	for (var i = techGroup.length - 1; i > 0; i--) {
		
		var tech = techGroup[i];
		if (tech.code == null)
			continue;
				
		var found = false;
		if (tech.code == code) {
			var tech = techGroup.splice(i, 1)[0];
			this.benchmarkIndex[tech.vAxis.vAxis_bak - 7] = 0;
		}
	}
	this.viewList[0].setTechnicalList(techGroup);
	
	var count = 0;
	for (var i = 0; i < techGroup.length; i++) {
		if (techGroup[i].isBenchmarkTechnical()) {
			count++;
		}
	}
	this.viewList[0].mapping.benchmark = count > 1;
	this.controllerView.typeControllerView.toggleStatus(!this.viewList[0].mapping.benchmark);
};

SvgChart.prototype.addBenchmark = function(code, vAxis) {
	var index = -1;
	for (var i = 0; i < this.benchmarkIndex.length; i++) {
		if (this.benchmarkIndex[i] == 0) {
			index = i;
			break;
		}		
	}
	if (index == -1) return;
	
	this.benchmarkIndex[index] = 1;
	index += 7;
	
	var techGroup = this.techGroupList[0];

	var tech = this.findBenchmark(code);
	
	if (tech == null) {
		tech = new SvgChartTechnical();
		tech.code = code;
		tech.setName(this, "line");
		tech.dataColor[0] = SvgChartGlobal.COLOR_PANEL[index];
		//tech.vAxis.vAxis = index;
		tech.vAxis.vAxis_bak = index;
		tech.vAxis.vAxis = vAxis || 0;
		tech.isBenchmarks = true;
//		tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, MST.getDataUrl(tech.code, this.period, {begin_at : begin_at}));

		var dataURL = MST.getDataUrl(tech.code, this.period);
		if(this.drawTopCompare) dataURL = MST.getTOPDataURL(tech.code, this.period);
		if (this.exchange == 'US') dataURL = MST.getUSDataUrl(tech.code, this.period);
		tech.setLoadType(SvgChartTechnical.DATA_LOAD_TYPE_URL, dataURL);

		techGroup.push(tech);
	}
		
	this.viewList[0].setTechnicalList(techGroup);
	
	var count = 0;
	for (var i = 0; i < techGroup.length; i++) {
		if (techGroup[i].isBenchmarkTechnical()) {
			count++;
		}
	}
	this.viewList[0].mapping.benchmark = count > 1;
	// 追加非空判断
	if(this.controllerView){
        this.controllerView.typeControllerView.toggleStatus(!this.viewList[0].mapping.benchmark);
    }

	this.loadData();
};

SvgChart.prototype.clearSketchPeriod = function() {
	for (var i = 0; i < this.viewList.length; i++) {
//		this.viewList[i].sketchList = [];
		for (var s = this.viewList[i].sketchList.length - 1; s >= 0 ; s--) {
			if (this.viewList[i].sketchList[s].period == this.period) {
				this.viewList[i].sketchList.splice(s, 1);
			}
		}
		this.viewList[i].drawSketch();
	}
	this.sketch = null;
}

SvgChart.prototype.removeTempSketch = function() {
	for (var i = 0; i < this.viewList.length; i++) {
		if (this.viewList[i].sketch != null) {
			this.viewList[i].sketch = null;
			this.viewList[i].drawSketch();
			lineIconShow(false);
		}
	}
	if (this.sketchMode && this.sketch != null) {
		this.sketch.reset();
	}
}

SvgChart.prototype.deleteCurrentSketch = function() {
	for (var i = 0; i < this.viewList.length; i++) {
		if (this.viewList[i].sketch != null) {
			for (var s = 0; s < this.viewList[i].sketchList.length; s++) {
				if (this.viewList[i].sketchList[s] == this.viewList[i].sketch) {
					this.viewList[i].sketchList.splice(s, 1);
					break;
				}
			}
			
			this.viewList[i].sketch = null;
			this.viewList[i].drawSketch();
		}
	}
	if (this.sketch != null) {
		this.sketch.reset();
	}
}

SvgChart.prototype.newSketch = function(name) {
	this.removeTempSketch();

	if (name == 'reset') {
		this.sketchMode = false;
	} else {
		name = name.slice(0, 1).toUpperCase() + name.slice(1);
		this.sketch = eval("new MST.SvgChartSketch" + name + "()");
		this.sketchMode = true;
	}
}

SvgChart.prototype.setDrawTopCompare = function(flag) {
	this.drawTopCompare = flag;
}

MST.SvgChart = SvgChart;
}());