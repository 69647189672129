(function() {

var cache = {};
var cacheTime = {};
var cacheReloadTime = {};
var SvgChartCache = function () {
};

SvgChartCache.save = function(url, period, data)
{
	cache[url] = data;
	cacheTime[url] = (new Date()).getTime();
	var t = 60;
	if (period == "1m" || period == "tick") {
		t = 0.5;
	} else if (period == "5m") {
		t = 3;
	} else if (period == "1h") {
		t = 10;
	}
	cacheReloadTime[url] = t * 60 * 1000;
};

SvgChartCache.load = function(url)
{
	var data = cache[url];
	if (data == null) {
		return null;
	}
	var t = (new Date()).getTime() - cacheTime[url];
	if (t > cacheReloadTime[url]) {
		return null;
	}
	return data;
};

MST.SvgChartCache = SvgChartCache;
}());