(function() {

var SvgChartGlobal = MST;
var SvgChartUtil = MST.SvgChartUtil;
var SvgChartCache = MST.SvgChartCache;

var SvgChartTechnical = function ()
{
	this.dataStatus = SvgChartTechnical.DATA_STATUS_INIT;
};

SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN = 0;
SvgChartTechnical.DATA_LOAD_TYPE_URL = 1;
SvgChartTechnical.DATA_LOAD_TYPE_URL_DELAY = 2;
SvgChartTechnical.DATA_ANALYSIS = 3;
SvgChartTechnical.DATA_STATUS_INIT = 0;
SvgChartTechnical.DATA_STATUS_LOADING = 1;
SvgChartTechnical.DATA_STATUS_OK = 2;
SvgChartTechnical.DATA_STATUS_FAILURE = 3;
SvgChartTechnical.DATA_STATUS_NO_DATA = 4;
SvgChartTechnical.DATA_MAX_RETRY_COUNT = 3;

SvgChartTechnical.prototype.view = null;
SvgChartTechnical.prototype.code = null;
SvgChartTechnical.prototype.name = null;
SvgChartTechnical.prototype.label = null;
SvgChartTechnical.prototype.data = null;
SvgChartTechnical.prototype.dataLoadType = 0;
SvgChartTechnical.prototype.dataLoadUrl = null;
SvgChartTechnical.prototype.dataLoadName = null;
SvgChartTechnical.prototype.dataRequest = null;
SvgChartTechnical.prototype.dataStatus = 0;
SvgChartTechnical.prototype.dataRetryCount = 0;
SvgChartTechnical.prototype.dataColor = null;
SvgChartTechnical.prototype.dataColorSelect = null;
SvgChartTechnical.prototype.elements = null;


SvgChartTechnical.prototype.isMainTechnical = function()
{
	return this == this.view.chart.mainTechnical;
};

SvgChartTechnical.prototype.isBenchmarkTechnical = function()
{
	return this.code != null && this.dataLoadType == SvgChartTechnical.DATA_LOAD_TYPE_URL;
};

SvgChartTechnical.prototype.setLoadType = function(type, value)
{
	this.dataLoadType = type;

	if(type == SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN) {
		this.dataLoadName = value;
	}
	else if(type == SvgChartTechnical.DATA_LOAD_TYPE_URL) {
		this.dataLoadUrl = value;
	}
};

SvgChartTechnical.loadSuccess = function(technical, json)
{
	if (json == null || !(json["data"] instanceof Array)) {
		technical.dataStatus = SvgChartTechnical.DATA_STATUS_FAILURE;
		technical.view.onTechnicalDataStatusChange(technical);
	}

	if (technical.name.indexOf("news") >= 0) {
		var data = [];
		technical.data = data;
		technical.merge = null;
		//setNewsList(json);
		var list = json["news_list"];
		for (var i = 0; i < list.length; i++) {
			var date = list[i].date.replace(/-/g, "/");
			var item = {date : new Date(date).toISOString(), type : list[i].type,
					id : list[i].id, source : list[i].source, title : list[i].title, url : list[i].url};
			data.push(item);
		}
	} else {
		var data = []
//		var ric = json["ric"];
//		var currentTime =  new Date(json["current_time"]).toISOString();
//		var currencyCode = json["currency_code"];

		var list = [];
		if (json["data"] && json["data"] instanceof Array) {
			var list = json["data"]
		}
		else if (json instanceof Array) {
			list = json;
		}

		var last_date = "";
		var last_volume = 0;
		for(var k = 0; k < list.length; k++){
//			if (list[k] && list[k] instanceof Array && list[k].length >= 6) {
//				if (list[k][4] == null) continue;
//				
//				var d = list[k][0];
//				var _date = d.substring(0,4) + "-" + d.substring(4,6) + "-" + d.substring(6,8);
//				if (d.length == 12) {
//					_date = _date + " " + d.substring(8,10) + ":" + d.substring(10,12);
//				}
//				var date = new Date(_date).toISOString();
//				var volume = Number(list[k][5]);
//				if (technical.view.chart.period == "1m" || technical.view.chart.period == "5m") {
//					if (last_date == _date.substring(0, 10)) {
//						volume -= last_volume;
//					}
//				}
//				
//				var item = {date: date, open: Number(list[k][1]), high: Number(list[k][2]), low: Number(list[k][3]), close: Number(list[k][4]), volume: volume}
//				data.push(item);
//				last_date = _date.substring(0, 10);
//				last_volume = Number(list[k][5]);
			if (list[k] && list[k] instanceof Array) {
				if (technical.view.chart.period == "tick") {
					data.push({date: new Date(list[k][0]).toISOString(), close: Number(list[k][4])});
				} else {
					//%w(date open high low close volume adjustment volume1 volume2)
					var adj = 1;
					if (list[k].length >= 7 && list[k][6] != null) adj = list[k][6];
					var volume = null;
					var volume1 = null;
					var volume2 = null;
					if (list[k].length >= 6 && list[k][5] != null) volume = list[k][5] / adj;
					if (list[k].length == 9 && list[k][7] != null) volume1 = list[k][7] / adj;
					if (list[k].length == 9 && list[k][8] != null) volume2 = list[k][8] / adj;
					var open = null;
					var high = null;
					var low = null;
					var close = null;
					if (list[k].length >= 5 && list[k][1] != null && list[k][1] > 0) open = list[k][1] * adj;
					if (list[k].length >= 5 && list[k][2] != null && list[k][2] > 0) high = list[k][2] * adj;
					if (list[k].length >= 5 && list[k][3] != null && list[k][3] > 0) low = list[k][3] * adj;
					if (list[k].length >= 5 && list[k][4] != null && list[k][4] > 0) close = list[k][4] * adj;
					data.push({date: new Date(list[k][0]).toISOString(), open: open, high: high, low: low, close: close,
						volume: volume, volume1: volume1, volume2: volume2});
				}
//			} else if (list[k] && list[k] instanceof Array && list[k].length == 5) {
//				var date = new Date(list[k][0]).toISOString();
//				var item = {date: date, open: Number(list[k][1]), high: Number(list[k][2]), low: Number(list[k][3]), close: Number(list[k][4])}
//				data.push(item);
			} else {
				var adj = 1;
				if (list[k].adjustment != null && list[k].adjustment != 1) adj = list[k].adjustment;
                if (list[k].adjustment_factor != null && list[k].adjustment_factor != 1) adj = list[k].adjustment_factor;
				var volume = null;
				var volume1 = null;
				var volume2 = null;
				if (list[k].volume != null) volume = list[k].volume / adj;
				if (list[k].volume1 != null) volume1 = list[k].volume1 / adj;
				if (list[k].volume2 != null) volume2 = list[k].volume2 / adj;
				var open = null;
				var high = null;
				var low = null;
				var close = null;
				if (list[k].open != null) open = list[k].open * adj;
				if (list[k].high != null) high = list[k].high * adj;
				if (list[k].low != null) low = list[k].low * adj;
				if (list[k].close != null) close = list[k].close * adj;
				data.push({date: new Date(list[k].date).toISOString(), open: open, high: high, low: low, close: close,
					volume: volume, volume1: volume1, volume2: volume2});
			}
		}
		technical.data = data;
	}
	if (technical.data.length > 1 && technical.data[0].date > technical.data[technical.data.length - 1].date) {
		technical.data.reverse();
	}
	// console.log(technical.data);
	
	if (technical.data.length > 0 && technical.name.indexOf("news") < 0) {
		
		// if (technical.view.chart.period == "daily") {
		//
		// 	var date = new Date(technical.data[0].date);
		//
		// 	while (technical.data.length < 120) {
		//
		// 		date.setDate(date.getDate() - 1);
		// 		while (date.getDay() == 0 || date.getDay() == 6) {
		// 			date.setDate(date.getDate() - 1);
		// 		}
		// 		var d = {date: date.toISOString(), open: null, high: null, low: null, close: null, volume: null};
		// 		technical.data.splice(0, 0, d);
		// 	}
		//
		// } else if (technical.view.chart.period == "1h") {
		//
		// 		var date = new Date(technical.data[0].date);
		//
		// 		while (technical.data.length < 120) {
		//
		// 			date.setHours(date.getHours() - 1);
		// 			while (date.getDay() == 0 || date.getDay() == 6 || date.getHours() > 6) {
		// 				date.setHours(date.getHours() - 1);
		// 			}
		// 			var d = {date: date.toISOString(), open: null, high: null, low: null, close: null, volume: null};
		// 			technical.data.splice(0, 0, d);
		// 		}
		//
		// } else if (technical.view.chart.period == "weekly") {
		//
		// 	var date = new Date(technical.data[0].date);
		//
		// 	while (technical.data.length < 120) {
		//
		// 		date.setDate(date.getDate() - 7);
		// 		var d = {date: date.toISOString(), open: null, high: null, low: null, close: null, volume: null};
		// 		technical.data.splice(0, 0, d);
		// 	}
		//
		// } else if (technical.view.chart.period == "monthly") {
		//
		// 	var date = new Date(technical.data[0].date);
		//
		// 	while (technical.data.length < 120) {
		//
		// 		date.setMonth(date.getMonth() - 1);
		// 		var d = {date: date.toISOString(), open: null, high: null, low: null, close: null, volume: null};
		// 		technical.data.splice(0, 0, d);
		// 	}
		//
		// }
		
	}

	// for (var i=0; i < technical.data.length; i++) {
	  // technical.data[i].volume = Math.ceil(Math.random() * 1000);
	// };

    if (technical.data.length == 0 && technical == technical.view.chart.mainTechnical) {
    	technical.dataStatus = SvgChartTechnical.DATA_STATUS_NO_DATA;
    	technical.view.onTechnicalDataStatusChange(technical);
    	return;
    } else {
	    technical.dataStatus = SvgChartTechnical.DATA_STATUS_OK;
    }
    
    technical.blankLength = 0;

	// if (technical.name == "mountain" && (technical.view.chart.period == "1m" || technical.view.chart.period == "5m")) {
		// var d = technical.data;
		// var startIndex = 0;
		// if (technical.view.chart.intradayTimeDate == null) {
			// var date = technical.data[technical.data.length - 1].date.substring(0, 10) + "T00:00:00";
			// startIndex = SvgChartUtil.searchIndex(date, technical.data, 0, technical.data.length - 1, "next");
//
			// // console.log(technical.data[startIndex]);
			// // console.log(technical.data[technical.data.length - 1]);
			// technical.data = technical.data.slice(startIndex);
		// } else {
			// var date = technical.view.chart.intradayTimeDate + "T24:00:00";
			// var endIndex = SvgChartUtil.searchIndex(date, technical.data, 0, technical.data.length - 1, "next");
//
			// // console.log(technical.data[startIndex]);
			// // console.log(technical.data[technical.data.length - 1]);
			// technical.data = technical.data.slice(0, endIndex);
		// }
//
		// var lastCloseData = [];
		// lastCloseData.push({close : technical.data[0]["close"] - technical.data[0]["net_change"]});
//
		// for (var i = 0; i < SvgChartGlobal.INTRADAY_LASTDAY_REFERENCE && i <= startIndex; i++) {
			// lastCloseData.push({close : d[startIndex - i]["close"]});
		// }
//
		// technical.view.chart.lastCloseTechnical.data = lastCloseData;
	// }

    technical.lastDataIndex = technical.data.length - 1;
    
    
    if (technical == technical.view.chart.mainTechnical && technical.view.chart.timeFrame == '1D') {
    	
//    	for (var i = 0; i < 20; i++) {
//        	technical.data.pop();
//    	}

		technical.data = technical.analysisAllDayBase(technical.data.length, 10);
		
		var origin_len = technical.data.length;

		var now = new Date();
		var exchangeCode = technical.view.chart.exchange;
		var next_day = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2);
		var full_data = technical.analysisIntradayBase(technical.data, SvgChartGlobal.exchange[exchangeCode], technical.view.chart.period, next_day);

		// technical.lastDataIndex = technical.data.length - 1;
		technical.fillBlank(full_data);
		
		technical.blankLength = technical.data.length - origin_len;
    }
    
    
    
//  if(technical.view.mapping.benchmark && technical.view.chart.period == "1m"){
//  	technical.data = technical.analysisAllDayBase(technical.data.length, 5);
//  	var now = new Date();
//		var exchangeCode = technical.view.chart.exchange;
//		var next_day = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2);
//		var full_data = technical.analysisIntradayBase(technical.data, SvgChartGlobal.exchange[exchangeCode], technical.view.chart.period, next_day);
//
//		technical.fillBlank(full_data);
//  	technical.abbreviate();
//
//  	var startIndex = SvgChartUtil.getStartIndex(technical.data, technical.data.length);
//		technical.lastCloseStartIndex = startIndex - 1;
//  }
//	else if (technical == technical.view.chart.mainTechnical && technical.view.chart.drawLastClose()) {
//
//		technical.data = technical.analysisAllDayBase(technical.data.length, 10);
//		
//		var origin_len = technical.data.length;
//
//		var now = new Date();
//		var exchangeCode = technical.view.chart.exchange;
//		var next_day = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2);
//		var full_data = technical.analysisIntradayBase(technical.data, SvgChartGlobal.exchange[exchangeCode], technical.view.chart.period, next_day);
//
//		// technical.lastDataIndex = technical.data.length - 1;
//		technical.fillBlank(full_data);
//		
//		technical.blankLength = technical.data.length - origin_len;
//
////		technical.abbreviate();
//		for (var i = technical.data.length - 1; i >= 0; i--) {
//			if (technical.data[i].close != null) {
//				technical.lastDataIndex = i;
//				break;
//			}
//		}
//
//		var startIndex = SvgChartUtil.getStartIndex(technical.data, technical.data.length);
//
//		technical.lastCloseStartIndex = startIndex - 1;
//		
//		if (technical.lastCloseStartIndex < 0) technical.dataStatus = SvgChartTechnical.DATA_STATUS_NO_DATA;
//
//		var startIndex0 = SvgChartUtil.getStartIndex(technical.data, startIndex - 1);
//		technical.twoDaysStartIndex = startIndex0;
//
//		if (technical.view.chart.period == "1m" || technical.view.chart.period == "5m") {
//			var lastCloseIndex = startIndex - 1 >= 0 ? startIndex - 1 : 0;
//			technical.lastCloseValue = technical.data[lastCloseIndex]["close"];
//			var lastlastCloseIndex = startIndex0 - 1 >= 0 ? startIndex0 - 1 : 0;
//			technical.lastlastCloseStartIndex = lastlastCloseIndex;
//			technical.lastlastCloseValue = technical.data[lastlastCloseIndex]["close"];
//		} else if (technical.lastCloseStartIndex >= 0) {
//			technical.lastCloseValue = technical.data[technical.lastCloseStartIndex]["close"];
//		}
//		
////		technical.data = technical.data.slice(startIndex0, technical.data.length);
////		technical.lastCloseStartIndex -= startIndex0;
////		technical.lastlastCloseStartIndex -= startIndex0;
////		technical.twoDaysStartIndex = 0
//	}
//
	if (technical == technical.view.chart.mainTechnical) {
		if (technical.view.chart.drawLastClose()) {
			technical.view.chart.minPageLength = technical.data.length - technical.lastCloseStartIndex;
		} else {
			technical.view.chart.minPageLength = Math.min(SvgChartGlobal.LAYOUT_SCROLL_MIN_PAGE_LENGTH, technical.data.length);
		}
	}

    if (technical != technical.view.chart.mainTechnical) {
        technical.data = technical.analysisBenchmark(technical.data, technical.view.chart.mainTechnical.data);
    }


	technical.view.onTechnicalDataStatusChange(technical);
};

SvgChartTechnical.prototype.analysisAllDayBase = function(endIndex, days) {
	if (days == 0) return [];
	if (endIndex == 0) return [];
	var startIndex = SvgChartUtil.getStartIndex(this.data, endIndex);
	// if (startIndex == 0) return [];

	var oneDayData = this.data.slice(startIndex, endIndex);
	var data = oneDayData;

//	var exchangeCode = this.view.chart.exchange;
//	var base_data = this.analysisIntradayBase(oneDayData, SvgChartGlobal.exchange[exchangeCode], this.view.chart.period);
//	var	data = this.analysisBenchmark(oneDayData, base_data, 16);
//	var data = this.analysisIntraday(data, base_data);

	var otherData = this.analysisAllDayBase(startIndex, days - 1);
	return otherData.concat(data);
};

SvgChartTechnical.prototype.loadData = function()
{
	if (this.dataStatus == SvgChartTechnical.DATA_STATUS_OK) {
		this.view.onTechnicalDataStatusChange(this);
		return;
	}
	if (this.dataLoadType == SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN) {
		this.dataStatus = SvgChartTechnical.DATA_STATUS_OK;
		this.view.onTechnicalDataStatusChange(this);
		return;
	} else if (this.dataLoadType == SvgChartTechnical.DATA_ANALYSIS) {
		if (this.view.chart.mainTechnical.data != null) {
			this.analysis.analysis(this, this.view.chart.mainTechnical.data);
		}
		this.dataStatus = SvgChartTechnical.DATA_STATUS_OK;
		this.view.onTechnicalDataStatusChange(this);
		return;
	}

	var data = SvgChartCache.load(this.dataLoadUrl);
	if (data != null) {
		SvgChartTechnical.loadSuccess(this, data);
		return;
	}

	// console.log("=======================================SvgChartGlobal.requestCount : " + SvgChartGlobal.requestCount);
	// if (SvgChartGlobal.requestCount > 0 ||
		// (this.dataLoadType == SvgChartTechnical.DATA_LOAD_TYPE_URL_DELAY && this.view.chart.actionView.mapping.status == SvgChartTechnical.DATA_STATUS_LOADING)) {
		// // this.count++;
		// // console.log("========loadData setTimeout===========");
		// // console.log(this.name);
		// // console.log(this.count);
		// (function (t) {
        	// setTimeout(function() {
            	// t.loadData();
        	// }, 500);
		// })(this);
		// return;
	// }
	// SvgChartGlobal.requestCount++;
	// technical = this;
	// console.log("========loadData start===========");

	// console.log(this.dataLoadUrl);

	var period = this.view.chart.period;
	var call_back = "chart_callback";
	if (this.callback) {
		call_back = this.callback;
	}
	(function(technical) {
		SvgChartUtil.jsonp({
			url : technical.dataLoadUrl,
			jsonpCallback : call_back,
			success : function(data) {
				// SvgChartGlobal.requestCount--;
				// console.log("========loadData success===========");

				if (technical.view.chart.period != period) {
					// console.log("==========period changed!!=========");
					return;
				}

				SvgChartCache.save(technical.dataLoadUrl, technical.view.chart.period, data);

				SvgChartTechnical.loadSuccess(technical, data);

			},
			fail : function() {
				SvgChartGlobal.requestCount--;
				// console.log("========loadData fail===========");

				if (technical.view.chart.period != period) {
					// console.log("==========period changed!!=========");
					return;
				}
				
				technical.dataRetryCount += 1;

				if (technical.dataRetryCount < SvgChartTechnical.DATA_MAX_RETRY_COUNT) {
					technical.loadData();
				} else {
					technical.dataStatus = SvgChartTechnical.DATA_STATUS_FAILURE;

					technical.data = [];

					// console.log("========DATA_MAX_RETRY_COUNT==========");
					technical.view.onTechnicalDataStatusChange(technical);
				}
			}
		});
	})(this);

	this.view.onTechnicalDataStatusChange(this);
};

SvgChartTechnical.prototype.analysisNews = function(mainData, newsSpan) {
//	if (this.newsSpan == newsSpan && this.newsPeriod == this.view.chart.period) {
//		return this.groupedNews;
//	}
	
	if (this.data == null || mainData == null || this.data.length == 0 || mainData.length == 0) return [];

//	for (var j = 0; j < this.data.length; j++){
//		console.log(this.data[j].type + "   " + this.data[j].date);
//	}
//	console.log(this.view.chart.newsType);
	
	var result = [];
	var data = this.data;

//	var start_date = new Date(mainData[start].date);
//	var end_date = new Date(mainData[end - 1].date);
//	var time = Math.round((end_date.getTime() - start_date.getTime()) / (24*3600*1000));
////	alert(time);
//	
//	var d = 1;
//	if (time > 7) {
//		d = 2;
//	}
//	if (time > 32) {
//		d = 7;
//	}
//	if (time > 190) {
//		d = 30;
//	}
//	if (time > 366 * 3) {
//		d = 180;
//	}
	
	
	var arr = [];
	var date;
	for (var i = 0; i < mainData.length; i++) {
		if (mainData[i].close == null) continue;
		if (arr.length == 0) {
			arr.push(i);
			date = (new Date(mainData[i].date)).getTime() + 24*3600*1000 * newsSpan;
		} else {
			var t = (new Date(mainData[i].date)).getTime();
			if (t >= date) {
				arr.push(i);
				date = t + 24*3600*1000 * newsSpan;
			}
		}		
	}
	
	var index = 0;
	for (var i = 0; i < arr.length; i++) {
		
		var mainDate = mainData[arr[i]].date;
		var nextDate = null;
		if (i < arr.length - 1) {
			nextDate = mainData[arr[i+1]].date;
//		} else if (end < mainData.length) {
//			nextDate = mainData[end].date;
		}

		if (i == 0) {
			while (index < data.length && data[index].date < mainDate) {
				index++;
			}
		}
		
		var news = [];

		for (var j = index; j < data.length; j++){
			var newsData = data[j];
			if (!this.view.chart.newsType[newsData["type"]]) continue;
			
			var newsDate = newsData["date"];
			if (nextDate == null || newsDate < nextDate){
				news.push(newsData);
				index = j + 1;
			} else {
				break;
			}
		}
		
		if (news.length > 0 && i >= 0) {
			result[arr[i]] = news;
		}
	}

//	var news = [];
//	for (var j = index; j < data.length; j++){
//		var newsData = data[j];
//		var newsDate = newsData["date"];
//		news.push(newsData);
//	}
//	
//	if (news.length > 0) {
//		result[mainData.length - 1] = news;
//	}
	this.newsSpan = newsSpan;
	this.newsPeriod = this.view.chart.period;
	this.groupedNews = result;
	return result;
};

SvgChartTechnical.prototype.analysisIntraday = function(data, base_data) {
	if (data == null || base_data == null) return;

	var result = [];
	// for (var i = 0; i < this.data.length; i += SvgChartGlobal.INTRADAY_1M_STEP) {
		// result.push({date : base_data[i].date, open : this.data[i].open, high : this.data[i].high, low : this.data[i].low, close : this.data[i].close, net_change : this.data[i].net_change});
	// }
	for (var i = 0; i < data.length; i++) {
		// var m = base_data[i].date.substring(14, 16);
		// if (parseInt(m) % 5 == 0) {
			result.push({date : base_data[i].date, open : data[i].open, high : data[i].high, low : data[i].low, close : data[i].close, net_change : data[i].net_change, volume : data[i].volume, current_time: data[i].current_time});
		// }
	}
	return result;
};

SvgChartTechnical.prototype.abbreviate = function() {
	var data = this.data;
	if (data == null || data.length == 0) return;
	var result = [];
	for (var i = 0; i < data.length; i++) {
		var m = data[i].date.substring(14, 16);
		if (parseInt(m) % 2 == 0) {
			result.push(data[i]);
		}
	}
	this.data = result;
};

SvgChartTechnical.prototype.analysisIntradayBase = function(data, exchangeTimes, period, current_time) {
	if (data == null || data.length == 0) return;

	var result = [];
	var span;

	switch (period) {
	case "1h":
		span = 60;
		break;
	case "5m":
		span = 5;
		break;
	case "1m":
		span = 1;
		break;
	}
	span = span * 60 * 1000;
	var date = data[data.length - 1].date.substring(0, 10);
	var year = parseInt(date.substring(0, 4), 10);
	var month = parseInt(date.substring(5, 7), 10) - 1;
	var day = parseInt(date.substring(8, 10), 10);
	var data_timestamp;
	var gmtOffset = (new Date()).getTimezoneOffset() * 60 * 1000;
	// var current_time = data[0].date;
	if (current_time == null) {
		current_time = data[data.length - 1].current_time;	
		current_time = current_time.substring(0, 10) + " " + current_time.substring(11, 19) + " +00";
		current_time = new Date(current_time);
	}
	// console.warn(data[data.length - 1].current_time);

	for (var i = 0; i < exchangeTimes.length; i++) {

		var label = exchangeTimes[i];
		var times = label.split(/:|\//);

		if (times.length != 2 && times.length != 4) {
			continue;
		}
		for (var t = 0; t < times.length; t++) {
			times[t] = parseInt(times[t]);
			if (isNaN(times[t])) {
				return data;
			}
		}

		var timestamp = Date.UTC(year, month, day, times[0], times[1]);
		if (i == 0) {
			data_timestamp = timestamp;
			result.push({date : (new Date(data_timestamp + gmtOffset)).format("yyyy/MM/dd hh:mm:ss +00")});
			data_timestamp += span;
		} else {
			while (data_timestamp <= timestamp && data_timestamp < current_time) {
				result.push({date : (new Date(data_timestamp + gmtOffset)).format("yyyy/MM/dd hh:mm:ss +00")});
				data_timestamp += span;
			};
		}

		if (times.length == 4) {
			timestamp = Date.UTC(year, month, day, times[2], times[3]);

			while (data_timestamp + span <= timestamp) {
				data_timestamp += span;
			};

			if (timestamp < current_time) {
				// data_timestamp = timestamp;
				result.push({date : (new Date(data_timestamp + gmtOffset)).format("yyyy/MM/dd hh:mm:ss +00")});
				data_timestamp += span;
			} else {
				break;
			}
		}
	}
	if (period != "1m") {
		result = result.slice(0, result.length - 1);
	}
	return result;
};

SvgChartTechnical.prototype.fillBlank = function(fullData) {
	var index = SvgChartUtil.searchIndex(this.data[this.data.length - 1].date, fullData, 0, fullData.length - 1, "next");
	this.data = this.data.concat(fullData.slice(index + 1));
};

SvgChartTechnical.prototype.analysisLastClose = function(mainData) {
	if (this.data == null || mainData == null || this.data.length == 0 || mainData.length == 0) return;

	// var result = [];
	// var data = this.data;
//
	// for (var i = data.length - 1; i >= 0; i--) {
		// if (data[i].date.substring(0, 10) < mainData[0].date.substring(0, 10)) {
			// var lastData = data[i];
			// this.data = [];
			// this.data[0] = lastData;
			// break;
		// }
	// }

	if (mainData.length >= SvgChartGlobal.LASTDAY_REFERENCE_LIMIT) {
		var lastData = this.data[0];
		this.data = [];
		this.data[0] = lastData;
	}
};

//begin guhai 20141102
SvgChartTechnical.prototype.analysisPick2 = function(mainData) {
	if (this.analysed == true) return;
	var startDate=new Date();
	// console.warn('Starting analysisPick2....');
	if (this.data == null || mainData == null) return;

	var result = [];
	var data = this.data;
	var mainIndex = 0;
	for (var i = 0; i < mainData.length; i++){
		var mainDate=mainData[i].date;
		//console.warn("mainDate:"+mainDate);
		var existFlg=false;
		var pickData=null;
		for (var j = 0; j < data.length; j++){
			pickData=data[j];
			var pickDate=pickData["start_time"];//.substr(0,10);
			//mainDate is sorted asc,pickDate is sorted desc
			if(mainDate>pickDate){
				break;
			}

			//console.warn("pickDate:"+pickDate);
			if (mainDate.substr(0,10)==pickDate.substr(0,10)){
				existFlg=true;
			    break;
			}
		}
		 //result[i]=existFlg ? mainData[i].date : null;
		 result[i]=existFlg ? pickData : null;
	}

	this.analysed = true;
	this.data = result;
	var endDate=new Date();
	var usedTime=endDate.getTime() - startDate.getTime();
	// console.warn('Ended analysisPick2,used '+(usedTime)+" ms");

	//just for test guhai  20141101
	//console.warn("Comparing begin------------");
	// for (var i = 0; i < data.length; i++){
		// console.warn(data[i]["start_time"]);
	// }
//
	// for (var i = 0; i < mainData.length; i++){
		// console.warn(mainData[i].date);
	// }
	//console.warn("Comparing end--------");
/**
	for (var i = 0; i < data.length; i++) {
		var start_time = data[i]["start_time"];

		if (mainData[mainIndex]&&mainData[mainIndex].date > start_time) {
			continue;
		}

		//otherwise index out of bounds exception
		while (mainIndex < mainData.length-1&&mainData[mainIndex].date < start_time) {
		//while (mainData[mainIndex].date < start_time && mainIndex < mainData.length) {
			result[mainIndex] = null;
			mainIndex++;
		}
		result[mainIndex] = data[i];
		mainIndex++;
	}
	result.shift();

	while (result.length < mainData.length) {
		result.push(null);
	}

	// console.warn(result);
	this.data = result;
	**/
};
//end guhai 20141102

SvgChartTechnical.prototype.analysisPick = function(mainData) {
	if (this.data == null || mainData == null || this.data.length == 0 || mainData.length == 0) return;

	var result = [];
	var data = this.data;
	var mainIndex = 0;
	for (var i = 0; i < data.length; i++) {
		var start_time = data[i]["start_time"];

		if (mainData[mainIndex].date > start_time) {
			continue;
		}

		while (mainData[mainIndex].date < start_time && mainIndex < mainData.length) {
			result[mainIndex] = null;
			mainIndex++;
		}
		result[mainIndex] = data[i];
		mainIndex++;
	}
	result.shift();

	while (result.length < mainData.length) {
		result.push(null);
	}

	// console.warn(result);
	this.data = result;
};

SvgChartTechnical.prototype.analysisBenchmark = function(data, mainData, date_len) {
	if (data == null || mainData == null) return;
	date_len = date_len || 10;

	var result = [];
	var index = 0;
	// console.warn(mainData.length + " <=========> " + data.length);
	if (data.length > 0) {
		var minDate = data[0].date.substring(0, date_len);
	
		for (var i = 0; i < mainData.length; i++) {

//			if (mainData[0].close != null && mainData[i].close == null) {
			if (mainData[i].close == null) {
				result[i] = mainData[i];
				continue;
			}

			// console.warn(i + " <=> " + index);
			var mainDate = mainData[i].date.substring(0, date_len);

			while (index < data.length && mainDate > data[index].date.substring(0, date_len)) {
				index++;
			}

            if (index < data.length) {
                if (mainDate < minDate){
                    result[i] = {date: mainData[i].date, open: null, high: null, low: null, close: null};
                } else {
                    result[i] = data[index];
                }
            } else {
                result[i] = {date: mainData[i].date, open: null, high: null, low: null, close: null};
            }
		}
	}
	return result;
};

SvgChartTechnical.id = 0;

SvgChartTechnical.prototype.setName = function(chart, name) {
	var globalViewConfig = chart.chartConfig.globalViewConfig;
	var viewConfig = chart.chartConfig.viewConfig;
	var technicalConfig = chart.chartConfig.technicalConfig[name];
	
	var config;
	if (technicalConfig.type == "sub") {
		config = MST.deepMerge(globalViewConfig, viewConfig.sub);
	} else {
		config = MST.deepMerge(globalViewConfig, viewConfig.main);
	}
	config = MST.deepMerge(config, technicalConfig);
	
	this.techId = SvgChartTechnical.id++;
	this.name = name;
//	this.label = name;

	this.colorIsConstant = technicalConfig.colorIsConstant;
	if (this.colorIsConstant) {
		this.dataColor = technicalConfig.dataColor;
		this.lineWidth = [SvgChartGlobal.LINE_WIDTH[0]];
	} else {
		this.dataColor = [];
		this.lineWidth = [];
		for (var i = 0; i < technicalConfig.dataColor.length; i++) {
			this.dataColor.push(SvgChartGlobal.COLOR_PANEL[technicalConfig.dataColor[i]]);
			this.lineWidth.push(SvgChartGlobal.LINE_WIDTH[0])
		}
	}
	if(technicalConfig.dataFillcolor){
		this.dataFillcolor = technicalConfig.dataFillcolor;
	}
	if(technicalConfig.cancellable != undefined){
		this.cancellable = technicalConfig.cancellable;
	}
	if(technicalConfig.maxCount != undefined){
		this.maxCount = technicalConfig.maxCount;
	}
	
	this.plural = technicalConfig.plural;
	this.labelShowParam = technicalConfig.labelShowParam;
	this.type = technicalConfig.type;
	this.dataColorSelect = technicalConfig.dataColorSelect;
	
	if (technicalConfig.dataColorLabel == null || technicalConfig.dataColorLabel.length == 0) {
		this.dataColorLabel = technicalConfig.labels;
	} else {
		this.dataColorLabel = technicalConfig.dataColorLabel;
	}
	this.dataLoadType = technicalConfig.loadType;
	this.labels = technicalConfig.labels;
	this.analysis = technicalConfig.analysis;
	if (technicalConfig.params != null) {
		this.params = technicalConfig.params.concat();
	}
	if(technicalConfig.paramLabels != null) {
		this.paramLabels = technicalConfig.paramLabels;
	}
	if(technicalConfig.paramMaxLengths != null){
		this.paramMaxLengths = technicalConfig.paramMaxLengths;
	}
	this.projectorType = technicalConfig.projectorType;
	this.dataLoadName = technicalConfig.dataName;

	this.layout = config.layout;
	this.vAxis = config.vAxis;
	this.vAxis.vAxis = this.vAxis.vAxis || 0;
	this.hAxis = config.hAxis;
	this.panel = config.panel;
};

MST.SvgChartTechnical = SvgChartTechnical;
}());