import { initializeCharts } from '../packs/mini_chart'; // 确保路径正确
$(document).ready(function () {
    // 绑定点击事件处理程序到 .click_choice_event 元素上
    $('.click_choice_event').on('click', function (e) {
        e.preventDefault();
        $('.click_choice_event').removeClass('button_time_active');
        $(this).addClass('button_time_active');
        var filter = $('.button_time_active').data('filter');
        hideDivByFilter(filter);
    });
});

function hideDivByFilter(filter) {
    var divMap = {
        'urgent_rise': $('#urgent_rise'),
        'latest': $('#latest'),
        'popular': $('#popular')
    };

    function hideAllDivs() {
        for (let key in divMap) {
            divMap[key].addClass('hidden');
            divMap[key].find('.mini-chart').addClass('hidden_chart');
        }
    }

    hideAllDivs();

    if (divMap[filter]) {
        divMap[filter].removeClass('hidden');
        divMap[filter].find('.mini-chart').removeClass('hidden_chart');
    }

    $('.mini-chart').empty();
    initializeCharts('.mini-chart');  // 手动初始化 .mini-chart
}
