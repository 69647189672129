const LZString = require('lz-string');
// import {ConLog} from './con_log';
// const conlog = new ConLog({debug: false});

export let com = {};
// Exp: 
//  com.getCookie(cookieKey);
com.base64Encode = function(str) { 
    // const encode = window.btoa(str);
    const encode = LZString.compress(str);
    return encode;
}
