import './chart/main.js';
import './chart/conf.js';
import './chart/lang/zh-cn.js';
import './chart/src/svgChartDrawUtil.js';
import './chart/src/svgChartUtil.js';
import './chart/src/svgChartCache.js';
import './chart/src/svgChartMapping.js';
import './chart/src/svgChartProjector.js';
import './chart/src/svgChartTechnical.js';
import './chart/src/svgChartSketch.js';
import './chart/src/svgChartConfig.js';
import './chart/src/svgChartView.js';
import './chart/src/svgChartActionView.js';
import './chart/src/svgChartScrollView.js';
// import './chart/src/svgChartControllerView.js';
import './chart/src/svgChart.js';