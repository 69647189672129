import {com as catchException} from  './com.exception';
import {com as base64} from  './com.base64';
import {com as manageCookies} from  './com.cookie';
import {com as http} from  './com.http';
let com = {
    ...catchException,
    ...base64,
    ...manageCookies,
    ...http
}

export { com };