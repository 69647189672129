MST.deepMerge = function (target, src) {
    var dst = {};

    if (target && typeof target === 'object') {
        Object.keys(target).forEach(function (key) {
            dst[key] = target[key];
        })
    }
    Object.keys(src).forEach(function (key) {
        if (src[key] instanceof Array || typeof src[key] !== 'object' || !src[key]) {
            dst[key] = src[key];
        }
        else {
            if (!target[key]) {
                dst[key] = src[key];
            } else {
                dst[key] = MST.deepMerge(target[key], src[key]);
            }
        }
    });

    return dst;
};

MST.globalViewConfig = {
	axis: true,
	leftAxis: false,
	rightAxis: true,
	showLabel: true,
	labelActive: false,
	vAxis: {width: 40, decimals: 4, textClassName: 'yAxis'},
	hAxis: {height: 17, textClassName: 'xAxis'},
	panel: {gridColor: '#DCDCDC'}
}
MST.globalViewConfigSimple = {
	axis: false,
	leftAxis: false,
	rightAxis: false,
	showLabel: false,
	labelActive: false,
	layout: {paddingLeft: 1, paddingRight: 1, paddingTop: 1, paddingBottom: 1},
	vAxis: {width: 0, decimals: 4, textClassName: 'yAxis'},
	hAxis: {height: 0, textClassName: 'xAxis'},
	panel: {gridColor: '#DCDCDC'}
}
MST.viewConfig = {
	main : {
		layout: {height: 280},
		vAxis: {parts: 4}
	},
	sub : {
		layout: {height: 60},
		vAxis: {parts: 3}
	}
}
MST.scrollConfig = {
	showScroll: false
}
MST.technicalConfig = {
	candle : {
		dataColor : [0],
		dataColorLabel : ["name"],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_URL,
		dataName : "close",
		labels : [{label : "open", data : "open"}, {label : "high", data : "high"}, {label : "low", data : "low"}, {label : "close", data : "close"}]
	},
	mountain : {
		projectorType : "line",
		dataColor : [0],
		dataFillcolor : ["#4572A7"],
		dataColorLabel : [],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_URL,
		dataName : "close",
		labels : [{label : "close", data : "close"}]
	},
	line : {
		projectorType : "line",
		dataColor : [0],
		dataColorLabel : [],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_URL,
		dataName : "close",
		labels : [{label : "close", data : "close"}]
	},
    sub_line : {
        type : "sub",
        projectorType : "line",
        dataColor : [0],
        dataColorLabel : [],
        loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_URL,
        dataName : "close",
        labels : [{label : "close", data : "close"}]
    },
	intraday : {
		projectorType : "line",
		colorIsConstant: true,
		dataColor : ["#0070bf", "#FF0000", "#008000"],
		dataColorLabel : [],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_URL,
		dataName : "close",
		labels : [{label : "close", data : "close"}]
	},
	twoDays : {
		layout: {height: 280},
		projectorType : "line",
		dataColor : [0],
		dataFillcolor : ["#4572A7"],
		dataColorLabel : [],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_URL,
		dataName : "close",
		labels : [{label : "close", data : "close"}]
	},
	volume : {
		layout: {height: 100},
		type : "sub",
		colorIsConstant: true,
		dataColor : ["#4572A7"],
		dataColorSelect : ["#4572A7"],
		dataColorLabel : [],
		// cancellable: false,
		// dataColor : [0],
		// dataColorLabel : [],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN,
		dataName : "volume",
		labels : [{label : "volume", data : "volume"}]
	},
	maxMin : {
		type : "dummy",
		colorIsConstant: true,
		dataColor : ["#FF2B11", "#1379F9"],
		// dataColorLabel : [],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_WITH_MAIN,
		dataName : "",
		labels : []
	},
	news : {
		type : "dummy",
		colorIsConstant: true,
		dataColor : ["#FF6600", "#00FF00"],
		// dataColorLabel : [],
		loadType : MST.SvgChartTechnical.DATA_LOAD_TYPE_URL,
		dataName : "",
		labels : []
	}
};

MST.SvgChartConfig = function () {
	var controllerConfig = {
		timeFrame : '6M',
		showController : false,
		showIndicator : false,
		showBenchmark : false,
		showChartRange : false,
		showUpdateBrowser : false,
		showType : false,
		showReset : false,
		layout : {
			height : 30
		},
		ranges : ["2D", "1W", "1M", "3M", "6M", "YTD", "1Y", "5Y", "10Y"],
//		indicators : ["MA", "MACD", "Ichimoku", "BollingerBand", "SRV", "RCI", "RSI", "DMI", "PSY", "ABRatio", "LinearTrend"]
		indicators : ["MA", "MACD", "Ichimoku", "BollingerBand", "SRV", "Parabolic", "RSI", "PSY"]
	};
	var actionViewConfig = {active: true, panel: {crossColor: '#96c5e3'}};

	var config = {
		type : 'PC',
		resizeHeight: false,
		technicalList : [['line'], ['volume']],
		controller: controllerConfig,
		scroll : MST.deepMerge(MST.globalViewConfig, MST.scrollConfig),
		actionView : actionViewConfig,
		globalViewConfig : MST.globalViewConfig,
		viewConfig: MST.viewConfig,
		technicalConfig: MST.technicalConfig
	};
	
	return config;
}

MST.MiniChartConfig = function () {
	var config = {
		resizeHeight: false,
		technicalList : [['mountain']],
		controller: {},
		scroll : {},
		actionView : {active: false, panel: {crossColor: '#96c5e3'}},
		globalViewConfig : MST.globalViewConfigSimple,
		viewConfig: MST.viewConfig,
		technicalConfig: MST.technicalConfig
	};

	return config;
}