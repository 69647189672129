(function() {

var SvgChartSketch = function () {
	this.pointIndex = 0;
	this.dataColor = '#ff7712';
	this.lineWidth = 2;
	this.dash = null;
};

SvgChartSketch.prototype.dashArray = ['5,5', '10,10', '20,10,5,5,5,10'];

SvgChartSketch.prototype.isNew = function () {
	return this.points.length == 0;
}

SvgChartSketch.prototype.isFinish = function () {
	return this.points.length == this.count;
}

SvgChartSketch.prototype.reset = function () {
	this.pointIndex = 0;
	this.points = [];
	this.period = null;
}

SvgChartSketch.prototype.addPoint = function(index, value, temp) {
	this.points[this.pointIndex] = {index: index, value: value};
	if (this.pointLimit != null && this.pointIndex > 0) {
		this.pointLimit();
	}
	if (!temp) {
		this.pointIndex++;
	}
}

SvgChartSketch.prototype.projectPoint = function(context, mapping) {
	for (var i = 0; i < this.points.length; i++) {
		var x = mapping.transformIndex(this.points[i].index);
		var v = this.points[i].value;
		if (mapping.benchmark == true && mapping.startValue != null) {
			v = (v / mapping.startValue - 1) * 100;
		}
		var y = mapping.transformValue(v);
		
		if (SvgChartSketch.inMappingDrawArea(mapping, x, y)) {
			MST.SvgChartDrawUtil.drawPoint(context, x, y, '#FFFFFF', 2, this.dataColor);
		}
	}	
}

SvgChartSketch.prototype.line = function(context, ret) {
	var line = MST.SvgChartDrawUtil.line(context, ret[0], ret[1], ret[2], ret[3], this.dataColor);
	line.style.strokeWidth = this.lineWidth;
	line.style.strokeLinecap = "round";
	if (this.dash != null) {
		line.style.strokeDasharray = this.dashArray[this.dash];
	}
}

SvgChartSketch.prototype.verticalLine = function(context, mapping, x) {
	var x0 = mapping.paddingLeft;
	var x1 = mapping.drawAreaWidth - mapping.paddingRight;
	var y0 = mapping.paddingTop;
	var y1 = mapping.drawAreaHeight - mapping.paddingBottom;
	if (x >= x0 && x <= x1) {
		var ret = [x, y0, x, y1];
		this.line(context, ret)
	}
}

SvgChartSketch.prototype.pointSelected = function(mapping, x, y) {
	for (var i = 0; i < this.points.length; i++) {
		var p = mapping.transform(this.points[i]);
		
		if (SvgChartSketch.pointInRect(x, y, p.x, p.y, p.x, p.y, SvgChartSketch.selectMargin)) {
			return i;
		}
	}
	return -1;
}

SvgChartSketch.prototype.move = function(index, value) {
	for (var i = 0; i < this.points.length; i++) {
		if (this.selectedPoint != null) {
			if (i != this.selectedPoint) continue;
		}
		this.points[i].index += index - this.moveStatus.index;
		this.points[i].value += value - this.moveStatus.value;
	}
	
	if (this.selectedPoint != null) {
		if (this.pointLimit != null) {
			this.pointLimit();
		}
	}

	this.moveStatus.index = index;
	this.moveStatus.value = value;
}

SvgChartSketch.inMappingDrawArea = function (mapping, x, y) {
	var span = 2;
	var x0 = mapping.paddingLeft - span;
	var x1 = mapping.drawAreaWidth - mapping.paddingRight + span;
	var y0 = mapping.paddingTop - span;
	var y1 = mapping.drawAreaHeight - mapping.paddingBottom + span;

	if(x >= x0 && x <= x1 && y >= y0 && y <= y1) {
		return true;
	}
	return false;
}


SvgChartSketch.clipTest = function (p, q) {
	if (p == 0) {
		return null;		
	} else {
		return q / p;
	}
}
SvgChartSketch.clipLine = function (clipType, wxl, wxr, wyt, wyb, start, stop) {
	arr = [];
	
	var dx = stop.x - start.x;
	var dy = stop.y - start.y;
	
	if (dx == 0 && dy == 0) return null;
	
	arr[0] = SvgChartSketch.clipTest(-dx, start.x - wxl);
	arr[1] = SvgChartSketch.clipTest(dx, wxr - start.x);
	arr[2] = SvgChartSketch.clipTest(-dy, start.y - wyt);
	arr[3] = SvgChartSketch.clipTest(dy, wyb - start.y);
	
//	console.log(arr);

	var min, max;
	if (dx == 0) {
		if (start.x < wxl || start.x > wxr) return null;
		
		min = Math.min(arr[2], arr[3]);
		max = Math.max(arr[2], arr[3]);
	} else if (dy == 0) {
		if (start.y < wyt || start.y > wyb) return null;
		
		min = Math.min(arr[0], arr[1]);
		max = Math.max(arr[0], arr[1]);
	} else {
		min = Math.max(arr[0], arr[2]);
		max = Math.min(arr[1], arr[3]);

		min = Math.max(Math.min(arr[0], arr[1]), Math.min(arr[2], arr[3]));
		max = Math.min(Math.max(arr[0], arr[1]), Math.max(arr[2], arr[3]));
	}
	
	if (clipType == "ray") {
		min = Math.max(min, 0);
	} else if (clipType == "lineSegment") {
		min = Math.max(min, 0);
		max = Math.min(max, 1);
	}
	
	if (min < max) {
		var arrCoords = [];
		arrCoords[0] = parseInt(start.x) + parseInt(min * dx);
		arrCoords[1] = parseInt(start.y) + parseInt(min * dy);
		arrCoords[2] = parseInt(start.x) + parseInt(max * dx);
		arrCoords[3] = parseInt(start.y) + parseInt(max * dy);
		return arrCoords;
	}
}

SvgChartSketch.clipLineMapping = function (clipType, mapping, p0, p1) {
	return SvgChartSketch.clipLine(clipType, mapping.paddingLeft, mapping.drawAreaWidth - mapping.paddingRight,
			mapping.paddingTop, mapping.drawAreaHeight - mapping.paddingBottom, p0, p1);
}

//X'=x*cos(n)+y*sin(n)
//Y'=-x*sin(n)+y*cos(n)
SvgChartSketch.rotate = function (x0, y0, x1, y1, sin, cos) {	
	var x_r = (x1 - x0) * cos + (y1 - y0) * sin + x0;
	var y_r = (y1 - y0) * cos - (x1 - x0) * sin + y0;
	return {x: x_r, y: y_r};
}

SvgChartSketch.selectMargin = 3;
SvgChartSketch.pointInLine = function (x, y, x0, y0, x1, y1) {
	var a = y1 - y0;
	var b = x1 - x0;
	var c = Math.sqrt(a * a + b * b);
	if (c == 0) {
		return SvgChartSketch.pointInRect(x, y, x0, y0, x0, y0, SvgChartSketch.selectMargin);
	}
	
	var sin = a / c;
	var cos = b / c;

	var point1_r = SvgChartSketch.rotate(x0, y0, x1, y1, sin, cos);
	var point_r = SvgChartSketch.rotate(x0, y0, x, y, sin, cos);
	
	return SvgChartSketch.pointInRect(point_r.x, point_r.y, x0, y0, point1_r.x, point1_r.y, SvgChartSketch.selectMargin);
}

SvgChartSketch.pointInRect = function (x, y, x0, y0, x1, y1, m) {
	if (x >= x0 - m && x <= x1 + m && y >= y0 - m && y <= y1 + m) {
		return true;
	} else {
		return false;
	}
}

SvgChartSketch.polygonSelected = function(mapping, points, x, y) {
	for (var i = 0; i < points.length; i++) {
		var p0 = mapping.transform(points[i]);
		if (i == points.length - 1) {
			var p1 = mapping.transform(points[0]);
		} else {
			var p1 = mapping.transform(points[i + 1]);
		}
		
		var ret = SvgChartSketch.clipLineMapping("lineSegment", mapping, p0, p1);
		if (ret != null) {
			if (SvgChartSketch.pointInLine(x, y, ret[0], ret[1], ret[2], ret[3])) return true;
		}
	}
	return false;
}

SvgChartSketch.distance = function(x0, y0, x1, y1) {
	return Math.sqrt((x0 - x1) * (x0 - x1) + (y0 - y1) * (y0 - y1));
}

MST.SvgChartSketch = SvgChartSketch;
}());